import React from "react";

import { CustomModalButtons } from "@constants/global.constants";

import IconButton from "@components/IconButton";
import CloseIcon from "@components/SVGs/CloseIcon";
import WPButton from "@components/WPButton";

import CircularLoader from "@common/styles/components/circularLoader";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";

import StyledCustomModal from "./StyledCustomModal";

const CustomModal = ({
  header,
  headerHelper,
  confirmBtn,
  confirmBtnDisabled,
  cancelBtn,
  children,
  onModalClose,
  customclass,
  width,
  cancelBtnDisabled,
  isLoading,
  loadingText,
  cancelBtnType,
  confirmBtnType
}) => {
  const handleBtnClick = id => {
    if (onModalClose) {
      onModalClose({ target: { id } });
    }
  };
  return (
    <StyledCustomModal show centered className={`${customclass}`} width={width}>
      {isLoading && (
        <div className='loading-overlay'>
          <div className='spinner'>
            <CircularLoader />
            {loadingText && <div className='loading-text'>{loadingText}</div>}
          </div>
        </div>
      )}
      <StyledCustomModal.Header>
        <div className='pump-heading'>
          <CustomHeading
            label={header}
            color={colors.PrimaryDarkAquaMarine}
            fontFamily='DiodrumSemiBold'
            fontSize='16px'
          />
          <CustomHeading
            label={headerHelper}
            color={colors.blackTransparency045}
            fontSize='12px'
            className='sub-heading'
          />
        </div>
        <IconButton id='modal-close-icon' tooltip='Close' onClick={() => handleBtnClick(CustomModalButtons.CLOSE)}>
          <CloseIcon />
        </IconButton>
      </StyledCustomModal.Header>
      <StyledCustomModal.Body>
        <div className="modal-body-container">{children}</div></StyledCustomModal.Body>
      <StyledCustomModal.Footer>
        {cancelBtn && (
          <WPButton
            type={cancelBtnType}
            variant='outlined'
            id={CustomModalButtons.cancelBtn}
            onClick={() => handleBtnClick(CustomModalButtons.CANCEL)}
            disabled={cancelBtnDisabled}
          >
            {cancelBtn}
          </WPButton>
        )}
        {confirmBtn && (
          <WPButton
            type={confirmBtnType}
            disabled={confirmBtnDisabled}
            id={CustomModalButtons.CONFIRM}
            onClick={() => handleBtnClick(CustomModalButtons.CONFIRM)}
          >
            {confirmBtn}
          </WPButton>
        )}
      </StyledCustomModal.Footer>
    </StyledCustomModal>
  );
};

export default CustomModal;
