import { useDispatch } from "react-redux";

import { setUfReportTableData } from "@common/ReportSlice";

import { useReportUtils } from "../useReportUtils";

import { useWPUfDesignWarningsTableData } from "./WPUfDesignWarningsTable/";
import { useWPUfConfigurationOptionsTableData } from "./WPUfConfiguringationOptionsTable";
import { useWPUfFlowDetailsTableData } from "./WPUfFlowDetailsTable";
import { useWPUfSystemOverViewTableData } from "./WPUfSystemOverviewTable";

export const useUfReport = () => {
  const dispatch = useDispatch();

  const { ufReportRespData } = useReportUtils();

  const { convertToSystemOverviewTableData } = useWPUfSystemOverViewTableData();
  const { convertToUfConfigurationOptionsTableData } = useWPUfConfigurationOptionsTableData();
  const { convertToUfDesignWarningsTableData } = useWPUfDesignWarningsTableData();
  const { convertToUfFlowDetailsTableData } = useWPUfFlowDetailsTableData();

  const convertUfCalcReportToTableData = () => {
    if (!ufReportRespData) return;
    dispatch(
      setUfReportTableData({
        uf_system_overview: convertToSystemOverviewTableData(),
        uf_configuration_options: convertToUfConfigurationOptionsTableData(),
        uf_design_quality: convertToUfDesignWarningsTableData(),
        flow_details: convertToUfFlowDetailsTableData(),
      }),
    );
  };

  return { convertUfCalcReportToTableData };
};
