import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import API_URLS from "@constants/api.urls";
import { DEFAULT_RECOVERY_VALUES, STATUS_CODE, TECHNOLOGIES } from "@constants/global.constants";
import { UNITS } from "@constants/units.constant";

import useUFDataHandler from "@hooks/useUFDataHandler";
import useUnitConversion from "@hooks/useUnitConversion";

import DupontLogger from "@utils/DupontLogger";

import Header from "@common/header/Header";
import {
  updateCaseConfig,
  updateChemicalConfig,
  updateProjectCurrency,
  updateProjectData,
  updateProjectInfo,
  updateProjectTitle,
  updatePumpList,
  updateUnitConfig,
} from "@common/ProjectInfoSlice";
import { updateIXDJson, updateIXDLoadder } from "@common/ReportIXDSlice";
import { updateIsReportAvailable, UpdateUFReport, updateUfReportLoading } from "@common/ReportUFSlice";
import { MyError } from "@common/utils/ErrorCreator";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import { updateGlobalUnits } from "@common/utils/GlobalUnitConversionSlice";
import { parseSelectedUnits } from "@common/utils/unitParser";

import { useCreateDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import ActivityMonitor from "./activitymonitor/ActivityMonitor";
import { UpdatedStream, updateFeedSetupStoreData } from "./feedsetup/FeedsetupSlice";
import { setIXDUpdate, updateIXStore, updateShowFinalParameterTab } from "./ix/IXDSlice";
import { getDefaultOnVessel1Selected } from "./ix/IXDUtilityFunction";
import SecondaryMenu from "./secondarymenu/SecondaryMenu";
import { setIsDataUpdated, setLoading, setNodeAndEdge, updateRecovery } from "./systemdesign/processDiagramSlice";
import { setUFChemicalAdjustment } from "./uf/UFSlice";
import FeedWaterHomeStyled from "./FeedWaterHomeStyled";

import "reactflow/dist/style.css";

const FeedWaterHome = () => {
  const Logger = DupontLogger("FeedWaterHome");
  const dispatch = useDispatch();
  const { fetchFeedSetupData } = useUFDataHandler();
  const caseFlag = useSelector(state => state.IXStore?.caseFlag);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const ixStore = useSelector(state => state.IXStore.data);
  const { isDataFetched } = useSelector(state => state.Feedsetupdetailsdatapanel);
  const userId = UserInfoStore ? UserInfoStore.UserId : 1;
  const projectid = ProjectInfoStore ? ProjectInfoStore.projectID : 0;
  const location = useLocation();
  const [getData, response] = useLazyGetAllDataQuery();
  const [getUnitlist, unitDataResponse] = useLazyGetAllDataQuery();
  const [getChemical, chemicalResponse] = useLazyGetAllDataQuery();
  const [getPumps, pumpsResponse] = useLazyGetAllDataQuery();
  const [getCases, CasesResponse] = useLazyGetAllDataQuery();
  const [getProjectinfo, ProjectinfoResponse] = useLazyGetAllDataQuery();
  const [getCurrencylist, CurrencylistResponse] = useLazyGetAllDataQuery();
  const [getUFReportJSON, UFReportJSONResponse] = useLazyGetAllDataQuery();
  const [getIXReportJSON, IXReportJSONResponse] = useLazyGetAllDataQuery();
  const { state } = useLocation();
  const [currentPanel, setCurrentPanel] = useState(-2);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const ixStoreObj = useSelector(state => state.IXStore);
  const ixResinID1 = ixStore?.selectedResinList[0]?.ixResinID1;
  const ixResinID2 = ixStore?.selectedResinList[0]?.ixResinID2;
  const ixResinID3 = ixStore?.selectedResinList[1]?.ixResinID1;
  const ixResinID4 = ixStore?.selectedResinList[1]?.ixResinID2;
  const resinVal = useSelector(state => state.IXStore.data?.listRegenConds);
  const ixStoreAdvance = useSelector(state => state.IXStore?.data?.listAdvRegen);
  const ixRegenreteDose = useSelector(state => state.IXStore?.data?.listProductQualityandregeneration);
  const [IXData_PostData] = useCreateDataMutation();
  const [resinItem1, resinItem2] = ixStore.selectedResinList;
  const { unitConversionByName } = useUnitConversion();

  useEffect(() => {
    if (UFReportJSONResponse.isSuccess && UFReportJSONResponse.status === "fulfilled") {
      Logger.log("UFReportJSONResponse.data", UFReportJSONResponse);
      if (UFReportJSONResponse?.data?.responseCode === STATUS_CODE.NO_CONTENT) {
        dispatch(updateUfReportLoading(false));
        dispatch(updateIsReportAvailable(false));
      } else {
        const data = UFReportJSONResponse.data;
        const adjustment = data?.adjustment;
        if (adjustment) {
          dispatch(setUFChemicalAdjustment({ adjustment }));
        }
        dispatch(UpdateUFReport(UFReportJSONResponse.data));
        dispatch(updateRecovery({ name: "UF", value: data?.ufRecovery || DEFAULT_RECOVERY_VALUES.UF }));
        dispatch(updateIsReportAvailable(true));
      }
    }
    if (UFReportJSONResponse.isError) {
      dispatch(updateUfReportLoading(false));
    }
  }, [UFReportJSONResponse]);

  useEffect(() => {
    if (projectid !== 0) {
      dispatch(setLoading());
      getUnitlist(`${"masterdata/api/v1/UnitOfMeassure"}?userID=${userId}&projectID=${projectid}`);
      getProjectinfo(`masterdata/api/v1/ProjectInfo?userID=${userId}&projectID=${projectid}`);
      getPumps(`masterdata/api/v1/Pumps?userID=${userId}&projectID=${projectid}`);
      getChemical(`masterdata/api/v${1}/OperatingCostChemical?userid=${userId}&projectid=${projectid}`);
      getCases(`masterdata/api/v1/CaseType?projectID=${projectid}`);
      getCurrencylist(`${"masterdata/api/v1/DefaultCurrency"}?userID=${userId}&projectid=${projectid}`);
    }
  }, [state]);

  const formatFeedData = source => {
    const { waterTypeId, feedStreamId, ph, chargeBalance, ...rest } = source;
    const feedData = {
      waterTypeID: waterTypeId,
      feedStreamID: feedStreamId,
      pH: ph,
      chargeBalance: Number.parseFloat(chargeBalance).toFixed(6),
      balacedInd: true,
      blendedStreamInd: true,
      ...rest,
    };
    return feedData;
  };

  const fetchFeedData = async caseID => {
    if (!isDataFetched) {
      const feedWaterData = await fetchFeedSetupData(caseID);
      const streamData = { caseID, ...formatFeedData(feedWaterData[0]) };
      const formattedStreamData = {
        Method: "masterdata/api/v1/FeedWaterData",
        userID: userId,
        projectID: projectid,
        lstrequestsavefeedwater: [{ streams: [streamData] }],
      };
      dispatch(UpdatedStream(formattedStreamData));
      dispatch(updateFeedSetupStoreData({ title: feedWaterData[0].streamName }));
    }
  };

  useEffect(() => {
    if (response.isLoading) {
      dispatch(setLoading());
    }
    if (response.isError) {
      throw new MyError("SystemDesig Api Error", response.error.status, "ApiError");
    }
    if (response.isSuccess && response.status === "fulfilled") {
      const caseID = state.caseID || response?.data?.caseID;
      const listTreatmentID = response.data.systemDesignCaseTreatmentVM;
      const projetStateURL = `${API_URLS.projectState}?projectID=${projectid}&caseID=${caseID}`;
      if (listTreatmentID.find(item => item.treatmentName == TECHNOLOGIES.UF)) {
        getUFReportJSON(projetStateURL);
      } else {
        dispatch(updateUfReportLoading(false));
      }
      if (
        listTreatmentID.find(item => item.treatmentName == TECHNOLOGIES.IXD) ||
        listTreatmentID.find(item => item.treatmentName == TECHNOLOGIES.IXMB)
      ) {
        getIXReportJSON(projetStateURL);
      }
      fetchFeedData(caseID);

      let newFlowValue = Number(
        GlobalUnitConversion(GlobalUnitConversionStore, response.data.flow, unit.selectedUnits[1], "m³/h").toFixed(2),
      );
      if (response.data.flow === 0 || response.data.flow === 1) {
        newFlowValue = Number(
          GlobalUnitConversion(GlobalUnitConversionStore, 100, unit.selectedUnits[1], "m³/h").toFixed(2),
        );
      }
      dispatch(setNodeAndEdge({ ...response.data, ["flow"]: newFlowValue }));
      dispatch(setIsDataUpdated());
      const obj = { ...ProjectInfoStore };
      obj.caseId = response.data?.caseID;
      const title = location.state?.title || "";
      const caseName = response?.data.projectCaseName;
      const projectName = title ? `${title.length > 30 ? `${title.substring(0, 30)}...` : title} - ${caseName}` : "";
      dispatch(updateProjectTitle(projectName));

      if (caseFlag === true) {
        autoSaveIXDData(listTreatmentID);
      }

      dispatch(updateProjectInfo(obj));
    }
  }, [response]);

  const getEquipmentDetails = () => ({
    pdExtPiping: unitConversionByName(ixStore.pdExtPiping, UNITS.bar, unit.selectedUnits[3]),
    pdIntDistributor: unitConversionByName(ixStore.pdIntDistributor, UNITS.bar, unit.selectedUnits[3]),
    effluentPressure: unitConversionByName(ixStore.effluentPressure, UNITS.bar, unit.selectedUnits[3]),
    tankTemperature: unitConversionByName(ixStore.tankTemperature, UNITS.celsius, unit.selectedUnits[2]),
    backwashTowerDiameter: unitConversionByName(
      ixStore.backwashTowerDiameter || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      UNITS.millimeter,
      unit.selectedUnits[8],
    ),
    sacRegenVesselDia: unitConversionByName(
      ixStore.sacRegenVesselDia || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      UNITS.millimeter,
      unit.selectedUnits[8],
    ),
    sbaRegenVesselDia: unitConversionByName(
      ixStore.sbaRegenVesselDia || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      UNITS.millimeter,
      unit.selectedUnits[8],
    ),
  });

  const autoSaveIXDData = listTreatmentID => {
    const existingData = {
      ...ixStore,
      existingPlantDescription: ixStoreObj.existingPlantDescription,
    };
    dispatch(updateIXStore(existingData));
    let dummyListFinal = [];
    if (ixStoreObj.viewReport === "true" && ixStore.evaluteExisting_ind == true) {
      dummyListFinal = ixStoreObj?.existingPlantDescription;
    } else {
      dummyListFinal = ixStoreObj?.listFinalParamAdj;
    }
    if (dummyListFinal.length <= 1) {
      let vesselCount = 0;
      if (ixStoreObj.resinName4 !== null) {
        vesselCount = 4;
      } else if (ixStoreObj.resinName3 !== null) {
        vesselCount = 3;
      } else if (ixStoreObj.resinName2 !== null) {
        vesselCount = 2;
      } else {
        vesselCount = 1;
      }
      const dummyArray = Array.from({ length: vesselCount }, (_, index) => ({
        resinType: ixStoreObj.resinData[ixStoreObj[`resinName${index + 1}`]],
        resinName: ixStoreObj[`resinName${index + 1}`],
        resinId:
          ixStoreObj[`resinName${index + 1}`] == "WAC"
            ? ixResinID1
            : ixStoreObj[`resinName${index + 1}`] == "SAC"
              ? ixResinID2
              : ixStoreObj[`resinName${index + 1}`] == "WBA"
                ? ixResinID3
                : ixResinID4,
        vesselNo: index + 1,
        resinVolumeAsDelivered: 0,
        vesselDiameter: 0,
        resinBedHeightAsDelivered: 0,
        resinBedStandardHeight: 0,
        resinBedHeightAsExhausted: 0,
        resinBedHeightAsRegenerated: 0,
        inertResinVolume: 0,
        inertBedHeight: 0,
        freeBoard: 0,
        vesselCylindricalHeight: 0,
        vesselWallThickness: 0,
        pressureDropwithRecomQty: 0,
        resinPackagingSize: 0,
        ixfpaRadioButtonID: 0,
      }));
      if (vesselCount > 1) {
        dummyListFinal = dummyArray;
      }
    }
    const list = [...dummyListFinal];
    dummyListFinal = list.map(item => {
      const resinVolumeAsDelivered = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.resinVolumeAsDelivered,
        "m³",
        unit.selectedUnits[12],
      );
      const inertResinVolume = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.inertResinVolume,
        "m³",
        unit.selectedUnits[12],
      );
      const vesselDiameter = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.vesselDiameter,
        "mm",
        unit.selectedUnits[8],
      );
      const resinBedHeightAsDelivered = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.resinBedHeightAsDelivered,
        "mm",
        unit.selectedUnits[8],
      );
      const resinBedStandardHeight = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.resinBedStandardHeight,
        "mm",
        unit.selectedUnits[8],
      );
      const resinBedHeightAsRegenerated = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.resinBedHeightAsRegenerated,
        "mm",
        unit.selectedUnits[8],
      );
      const resinBedHeightAsExhausted = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.resinBedHeightAsExhausted,
        "mm",
        unit.selectedUnits[8],
      );
      const inertBedHeight = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.inertBedHeight,
        "mm",
        unit.selectedUnits[8],
      );
      const vesselCylindricalHeight = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.vesselCylindricalHeight,
        "mm",
        unit.selectedUnits[8],
      );
      const vesselWallThickness = GlobalUnitConversion(
        GlobalUnitConversionStore,
        item.vesselWallThickness,
        "mm",
        unit.selectedUnits[8],
      );
      return {
        ...item,
        ["resinVolumeAsDelivered"]: Number.parseFloat(resinVolumeAsDelivered).toFixed(2),
        ["inertResinVolume"]: Number.parseFloat(inertResinVolume).toFixed(2),
        ["vesselDiameter"]: Number.parseFloat(vesselDiameter).toFixed(2),
        ["resinBedHeightAsDelivered"]: Number.parseFloat(resinBedHeightAsDelivered).toFixed(2),
        ["resinBedStandardHeight"]: Number.parseFloat(resinBedStandardHeight).toFixed(2),
        ["resinBedHeightAsRegenerated"]: Number.parseFloat(resinBedHeightAsRegenerated).toFixed(2),
        ["resinBedHeightAsExhausted"]: Number.parseFloat(resinBedHeightAsExhausted).toFixed(2),
        ["inertBedHeight"]: Number.parseFloat(inertBedHeight).toFixed(2),
        ["vesselCylindricalHeight"]: Number.parseFloat(vesselCylindricalHeight).toFixed(2),
        ["vesselWallThickness"]: Number.parseFloat(vesselWallThickness).toFixed(2),
      };
    });

    /*----Unit conversion for regenenConditionPage start-----*/
    const [a, b] = resinVal;
    let cationTemp = resinVal[0]?.temperature;
    let anionTemp = resinVal[1]?.temperature;
    if (a) {
      cationTemp = Number(
        GlobalUnitConversion(GlobalUnitConversionStore, resinVal[0]?.temperature, "°C", unit.selectedUnits[2]).toFixed(
          2,
        ),
      );
    }
    if (b) {
      anionTemp = Number(
        GlobalUnitConversion(GlobalUnitConversionStore, resinVal[1]?.temperature, "°C", unit.selectedUnits[2]).toFixed(
          2,
        ),
      );
    }
    const [Ra, Rd] = ixRegenreteDose;
    // averageConductivityVal
    let cationRegenreteDoseVel = ixRegenreteDose[0]?.regenerantDoseVal4;
    let anionRegenreteDoseVel = ixRegenreteDose[1]?.regenerantDoseVal4;
    let cationAverageConduc = ixRegenreteDose[0]?.averageConductivityVal;
    let anionAverageConduc = ixRegenreteDose[1]?.averageConductivityVal;
    let cationendpointConduc = ixRegenreteDose[0]?.endpointConductivityVal;
    let anionendpointConduc = ixRegenreteDose[1]?.endpointConductivityVal;
    if (Ra) {
      cationRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      cationAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
      cationendpointConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.endpointConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }
    if (Rd) {
      anionRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      anionAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
      anionendpointConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.endpointConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }
    /*----Unit conversion for regenenConditionPage end-----*/
    /*----Unit conversion for Advance Regeneration start-----*/
    const [c, d] = ixStoreAdvance;
    let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
    let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
    let cationDisVol = ixStoreAdvance[0]?.displacementVolume;
    let anionDisVol = ixStoreAdvance[1]?.displacementVolume;
    let cationFasVol = ixStoreAdvance[0]?.fatRinseVolume;
    let anionFasVol = ixStoreAdvance[1]?.fatRinseVolume;
    if (c) {
      cationregenVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
      cationDisVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.displacementVolume,
        "BV",
        unit.selectedUnits[13],
      );
      cationFasVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.fatRinseVolume,
        "BV",
        unit.selectedUnits[13],
      );
    }
    if (d) {
      anionregeneVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
      anionDisVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.displacementVolume,
        "BV",
        unit.selectedUnits[13],
      );
      anionFasVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.fatRinseVolume,
        "BV",
        unit.selectedUnits[13],
      );
    }

    const MethodName = { Method: "ix/api/v1/AutoSaveIXData" };
    const IXData_Method_Body = {
      ...MethodName,
      ...ixStore,
      ["treatmentObjID"]: listTreatmentID && listTreatmentID[listTreatmentID.length - 1]?.caseTreatmentID,
      ["caseTreatmentID"]: listTreatmentID && listTreatmentID[listTreatmentID.length - 1]?.caseTreatmentID,
      ["caseID"]: state.caseID === 0 ? ixStore.caseID : state.caseID,
      ["space_velocity_txt"]: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ixStore.space_velocity_txt,
          "BV/h",
          unit.selectedUnits[10],
        ).toFixed(2),
      ),
      ...getEquipmentDetails(),
      selectedResinList: [
        { ...resinItem1, ["inert"]: resinItem1?.inert || 0 },
        { ...resinItem2, ["inert"]: resinItem2?.inert || 0 },
      ],
      listRegenConds: [
        { ...ixStore.listRegenConds[0], ["temperature"]: cationTemp },
        { ...ixStore.listRegenConds[1], ["temperature"]: anionTemp },
      ],
      listAdvRegen: [
        {
          ...ixStore.listAdvRegen[0],
          ["regenerationVelocity"]: Number(cationregenVel?.toFixed(2)),
          ["displacementVolume"]: Number(cationDisVol?.toFixed(2)),
          ["fatRinseVolume"]: Number(cationFasVol?.toFixed(2)),
        },
        {
          ...ixStore.listAdvRegen[1],
          ["regenerationVelocity"]: Number(anionregeneVel?.toFixed(2)),
          ["displacementVolume"]: Number(anionDisVol?.toFixed(2)),
          ["fatRinseVolume"]: Number(anionFasVol?.toFixed(2)),
        },
      ],
      listProductQualityandregeneration: [
        {
          ...ixStore.listProductQualityandregeneration[0],
          ["regenerantDoseVal4"]: cationRegenreteDoseVel,
          ["averageConductivityVal"]: cationAverageConduc,
          ["endpointConductivityVal"]: cationendpointConduc,
        },
        {
          ...ixStore.listProductQualityandregeneration[1],
          ["regenerantDoseVal4"]: anionRegenreteDoseVel,
          ["averageConductivityVal"]: anionAverageConduc,
          ["endpointConductivityVal"]: anionendpointConduc,
        },
      ],
      listFinalParamAdj: dummyListFinal,
    };
    IXData_PostData(IXData_Method_Body);
    dispatch(setIXDUpdate(false));
  };
  useEffect(() => {
    if (unitDataResponse.isError) {
      throw new MyError("SystemDesig Api Error", unitDataResponse.error.status, "ApiError");
    }

    if (unitDataResponse.isSuccess) {
      const parsedUnits = parseSelectedUnits(unitDataResponse.data);
      dispatch(updateGlobalUnits(parsedUnits));
      dispatch(updateUnitConfig(unitDataResponse.data));
      if (projectid !== 0) {
        const url =
          state.caseID === 0
            ? `masterdata/api/v${1}/SystemDesign?userID=${userId}&projectID=${state.projectID}`
            : `masterdata/api/v${1}/SystemDesign?userID=${userId}&projectID=${state.projectID}&caseID=${state.caseID}`;
        getData(url);
      }
    }
  }, [unitDataResponse]);

  useEffect(() => {
    if (chemicalResponse.isSuccess) {
      dispatch(updateChemicalConfig(chemicalResponse.data));
    }
  }, [chemicalResponse]);

  useEffect(() => {
    if (pumpsResponse.isSuccess) {
      dispatch(updatePumpList(pumpsResponse.data));
    }
  }, [pumpsResponse]);

  useEffect(() => {
    if (ProjectinfoResponse.isSuccess) {
      dispatch(updateProjectData(ProjectinfoResponse.data));
    }
  }, [ProjectinfoResponse]);

  useEffect(() => {
    if (CurrencylistResponse.isSuccess) {
      dispatch(updateProjectCurrency(CurrencylistResponse.data));
    }
  }, [CurrencylistResponse]);

  useEffect(() => {
    const { data, isSuccess } = IXReportJSONResponse || {};
    if (isSuccess && data && data.responseCode !== STATUS_CODE.NO_CONTENT) {
      dispatch(updateIXDJson(data || {}));
      dispatch(updateShowFinalParameterTab(data.showFinalParameterTab));
      dispatch(updateIsReportAvailable(true));
      return;
    } else if (data?.responseCode === STATUS_CODE.NO_CONTENT) {
      dispatch(updateIsReportAvailable(false));
    }
    dispatch(updateIXDLoadder(false));
  }, [IXReportJSONResponse]);

  useEffect(() => {
    if (CasesResponse.isSuccess) {
      const reorderData = CasesResponse.data;
      dispatch(updateCaseConfig(reorderData));
    }
  }, [CasesResponse]);

  localStorage.setItem("firstLoginChecked", true);

  return (
    <>
      <FeedWaterHomeStyled fluid className='p-0'>
        <Header />
        <SecondaryMenu currentPanel={currentPanel} />
        <ActivityMonitor setCurrentPanel={setCurrentPanel} />
        {/* <QuickNav /> Not in December scope */}
      </FeedWaterHomeStyled>
    </>
  );
};

export default FeedWaterHome;
