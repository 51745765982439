import { UNIT_TYPES } from "@constants/units.constant";

export const WPUfSystemOverviewTableLabels = {
  moduleType: "Module Type",
  numUnits: "# Units",
  modules: "# Modules",
  systemFlowRate: "System Flow Rate",
  trainFlowRate: "Train Flow Rate",
  ufSystemRecovery: "UF System Recovery",
  tmp: "TMP",
  utilityWater: "Utility Water",
};

export const WPUfSystemOverviewValueLabels = {
  online: "Online",
  standBy: "Standby",
  redundant: "Redundant",
  perUnit: "Per Unit",
  total: "Total",
  grossFeed: "Gross Feed",
  netProduct: "Net Product",
  forwardFlush: "Forward Flush",
  backwash: "Backwash",
  ceb: "CEB",
  cip: "CIP",
};

export const WPUfSystemOverviewRows = [
  ["moduleType"],
  ["numUnits"],
  [],
  ["modules"],
  ["systemFlowRate", UNIT_TYPES.FLOW],
  ["trainFlowRate", UNIT_TYPES.FLOW],
  ["ufSystemRecovery", "%"],
  ["tmp", UNIT_TYPES.PRESSURE],
  ["utilityWater"],
  [""],
];

export const WPUfSystemOverviewTableColumns = [
  {
    field: "col1",
    rowHeader: true,
  },
  {
    field: "col2",
    rowHeader: true,
  },
  {
    field: "col3",
  },
  {
    field: "col4",
  },
];
