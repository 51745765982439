/**
 * Sorts an array of objects based on a specified order of a property.
 *
 * @param {Array} Ions - The array of objects to be sorted.
 * @param {Array} order - The array specifying the order of the property values.
 * @returns {Array} - The sorted array.
 */
export const sortByOrder = (Ions, order) => {
  const ReplicatedIons = [...Ions];
  const sortedIons = ReplicatedIons.sort((item1, item2) => order[item1.name] - order[item2.name]);
  // Create a new array with formatted mgL values
  const formattedIons = sortedIons.map(ion => ({
    ...ion,
    mgL: parseAndFormatFloat(ion.mgL, 3),
  }));
  return formattedIons;
};

/**
 * Converts to a floating-point number and formats it to a specified number of decimal places.
 *
 * @param {string} value - The string to be converted to a floating-point number.
 * @param {number} num - The number of decimal places to format the number to.
 * @returns {string} - The formatted number as a string.
 */
export function parseAndFormatFloat(value, num) {
  return parseFloat(value).toFixed(num);
}
