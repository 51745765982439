import { useState } from "react";
import { downloadExportedProject } from "src/api/importApi/importApi";

import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import { useToastNotification } from "@components/WPToastNotification";

export const useExportProject = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState(null);
  const { showSuccessNotification } = useToastNotification();

  const exportProject = async (projectId, projectName) => {
    setExportError(null);
    if (projectId === "") {
      return false;
    }

    const params = new URLSearchParams();
    params.append("projectId", projectId);

    try {
      setIsExporting(true);
      const response = await downloadExportedProject(params, {
        responseType: "file",
        headers: { accept: "application/json, text/plain, */*" },
      });
      if (response.status === 200) {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = projectName + ".dwpx";
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          a.remove();
          window.URL.revokeObjectURL(url);
          showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_EXPORTED_SUCCESS);
        }, 100); // Adjust the delay as needed
      } else {
        setExportError("Error exporting project");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setExportError(error.message);
    } finally {
      setIsExporting(false);
    }
  };

  return [exportProject, isExporting, exportError, setExportError];
};
