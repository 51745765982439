/* eslint-disable max-len */
import { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useUFTechnologyStrings from "@hooks/useUFTechnologyStrings";

import DupontLogger from "@utils/DupontLogger";

import CustomCard from "@components/CustomCard";

import InfoIcon from "@common/icons/InfoIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import CalcEngineInputBox from "@common/styles/components/inputs/CalcEngineInputBox";
import { handleTabAndEnter } from "@common/styles/components/inputs/InputFocusUtililty";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { colors } from "@common/styles/Theme";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import { handleSameAsBackwash, setUfDataUpdate, updateUFStoreData } from "../uf/UFSlice";

import { CHEMICAL_TYPES, UF_CHEMICAL_SCREENS } from "./constants/UFConstants";
import BackwashStyled from "./BackwashStyled";
import UFChemicalFields from "./UFChemicalFields";
import UFSystemDesignDiagram from "./UFSystemDesignDiagram";
import { isIngeSelected } from "./ufUtils";

const Backwash = () => {
  const Logger = DupontLogger("Backwash");

  const dispatch = useDispatch();

  const UFStore = useSelector(state => state.UFStore);
  const selectedUFModule = useSelector(state => state.UFStore.activeUFModule);
  const FeedStream = useSelector(state => state.Feedsetupdetailsdatapanel);
  const unitConfig = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);

  const [message, setMessage] = useState("");
  const [isFocused, setIsFocused] = useState(null);
  const [isFieldValid, setIsFieldValid] = useState(false);
  const [isUXASelected, setIsUXASelected] = useState(false);
  const [DISABLED_INPUTS, setDISABLED_INPUTS] = useState([]);

  const ufTechnologyStrings = useUFTechnologyStrings();

  const { bwWaterSource, forwardFlushWaterSource, bwProtocol, data: UFData, defaultInputRangeConfig } = UFStore;
  const fieldFFBackWash = defaultInputRangeConfig["forwardFlush_backWash"];
  const fieldBackwashTop = defaultInputRangeConfig["backWash1_backWash"];
  const fieldBackwashBottom = defaultInputRangeConfig["backWash2_backWash"];
  const fieldBackwashAirScour = defaultInputRangeConfig["backwash_AirScour"];
  const fieldBackwashDrain = defaultInputRangeConfig["drain_backWash"];
  const tempDesign = (FeedStream && FeedStream?.streamData?.lstrequestsavefeedwater[0]?.streams[0]?.tempDesign) || 25;

  const chemicalRanges = { oxidantValue_BW: defaultInputRangeConfig.oxidantValue_BW };

  const {
    lF,
    uFBWProtocolID,
    drain_backWash,
    t_BWBtnAirScour,
    backwash_AirScour,
    backWash1_backWash,
    backWash2_backWash,
    backwash_Filtration,
    forwardFlush_backWash,
  } = UFData;

  const isIngeFlow = useMemo(() => isIngeSelected(UFData.pUFTechnologyID), [UFData.pUFTechnologyID]);
  const updateStoreData = newData => dispatch(updateUFStoreData(newData));

  useEffect(() => {
    Logger.log("Technology changed - isIngeFlow: ", isIngeFlow);

    const disabledInputsSet = new Set(
      isIngeFlow
        ? [
            ufTechnologyStrings.filtratetoLevel,
            ufTechnologyStrings.airScour,
            ufTechnologyStrings.drain,
            ufTechnologyStrings.lumenFill,
            ufTechnologyStrings.backwashesbetweenAirScour,
          ]
        : [ufTechnologyStrings.filtratetoLevel, ufTechnologyStrings.lumenFill],
    );

    if (!isIngeFlow) {
      if (isUXASelected) {
        disabledInputsSet.add(ufTechnologyStrings.drain);
        disabledInputsSet.add(ufTechnologyStrings.airScour);
        dispatch(updateStoreData({ data: { backwash_AirScour: backWash1_backWash } }));
      } else {
        disabledInputsSet.delete(ufTechnologyStrings.drain);
        disabledInputsSet.delete(ufTechnologyStrings.airScour);
      }
    }

    setDISABLED_INPUTS(Array.from(disabledInputsSet));
  }, [isIngeFlow, isUXASelected]);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    Logger.log("uFBWProtocolID changes to: ", uFBWProtocolID, bwProtocol);
    if (bwProtocol.length > 1) {
      const selectedFilter = bwProtocol.filter(item => item.ufbwProtocolID === parseInt(uFBWProtocolID));
      const isUXASelected = selectedFilter[0]?.protocolName.includes("UXA");
      setIsUXASelected(isUXASelected);
    }
  }, [uFBWProtocolID]);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsFieldValid(false);
      handleTabAndEnter(event);
    }
  };

  const handleFocus = id => setIsFocused(id);
  const handleBlur = e => {
    const precision = e.target.name === "t_BWBtnAirScour" ? 0 : 2;
    setIsFocused(null);
    let valueIsSafe = false;

    if (e.target.name === "forwardFlush_backWash") {
      if (
        e.target.value < fieldFFBackWash?.minValue ||
        e.target.value > fieldFFBackWash?.maxValue ||
        isNaN(e.target.value)
      ) {
        //Show Error PopUp
        setIsFieldValid(true);
        const msg = `The Forward Flush value entered is outside the allowed range (${fieldFFBackWash?.minValue} to ${fieldFFBackWash?.maxValue}). Please revise your input.`;
        setMessage(msg);
        setIsFocused("forwardFlush_backWash");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (e.target.name === "backWash1_backWash") {
      if (
        e.target.value < fieldBackwashTop?.minValue ||
        e.target.value > fieldBackwashTop?.maxValue ||
        isNaN(e.target.value)
      ) {
        //Show Error PopUp
        setIsFieldValid(true);
        const msg = `The Top Backwash value entered is outside the allowed range (${fieldBackwashTop?.minValue} to ${fieldBackwashTop?.maxValue}). Please revise your input.`;
        setMessage(msg);
        setIsFocused("backWash1_backWash");
        setTimeout(() => e.target.focus(), 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (e.target.name === "backWash2_backWash") {
      if (
        e.target.value < fieldBackwashBottom?.minValue ||
        e.target.value > fieldBackwashBottom?.maxValue ||
        isNaN(e.target.value)
      ) {
        //Show Error PopUp
        setIsFieldValid(true);
        const msg = `The Bottom Backwash value entered is outside the allowed range (${fieldBackwashBottom?.minValue} to ${fieldBackwashBottom?.maxValue}). Please revise your input.`;
        setMessage(msg);
        setIsFocused("backWash2_backWash");
        setTimeout(() => e.target.focus(), 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (e.target.name === "backwash_AirScour") {
      if (
        e.target.value < fieldBackwashAirScour?.minValue ||
        e.target.value > fieldBackwashAirScour?.maxValue ||
        isNaN(e.target.value)
      ) {
        //Show Error PopUp
        setIsFieldValid(true);
        const msg = `The Air Scour value entered is outside the allowed range (${fieldBackwashAirScour?.minValue} to ${fieldBackwashAirScour?.maxValue}). Please revise your input.`;
        setMessage(msg);
        setIsFocused("backwash_AirScour");
        setTimeout(() => e.target.focus(), 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (e.target.name === "drain_backWash") {
      if (
        e.target.value < fieldBackwashDrain?.minValue ||
        e.target.value > fieldBackwashDrain?.maxValue ||
        isNaN(e.target.value)
      ) {
        //Show Error PopUp
        setIsFieldValid(true);
        const msg = `The Drain value entered is outside the allowed range (${fieldBackwashDrain?.minValue} to ${fieldBackwashDrain?.maxValue}). Please revise your input.`;
        setMessage(msg);
        setIsFocused("drain_backWash");
        setTimeout(() => e.target.focus(), 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (e.target.name === "t_BWBtnAirScour") {
      if (e.target.value == "" || e.target.value < 1 || isNaN(e.target.value) || e.target.value > 100) {
        setIsFieldValid(true);
        setMessage("The Backwashes between Air Scour is outside the allowed range 1 to 100. Please revise your input.");
        setIsFocused("t_BWBtnAirScour");
        setTimeout(() => e.target.focus(), 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    }
    if (valueIsSafe) {
      updateStoreData({
        data: { [e.target.name]: Number(e.target.value).toFixed(precision) },
        calcEngineDataRefreshCount: 1,
      });
    }
  };

  const getPrecisionValue = name => (name === "t_BWBtnAirScour" ? 0 : 2);

  const getRestrictedEvents = name => {
    const events = ["e", "E", "+", "-", "ArrowUp", "ArrowDown"];
    if (name === "t_BWBtnAirScour") {
      events.push(".");
    }
    return events;
  };

  const durationData = [
    {
      id: 1,
      label: ufTechnologyStrings.filtratetoLevel,
      name: "backwash_Filtration",
      defaultValue: isIngeFlow ? "0" : backwash_Filtration || "0",
      value: isIngeFlow ? "0" : backwash_Filtration,
      inputText: "Sec",
      placeholder: "0.00",
      disabled: true,
      calcInput: true,
      className: "big-text",
      isError: false,
      validator: { minValue: 0, maxValue: 0 },
      hideInputref: isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.filtratetoLevel),
    },
    {
      id: 2,
      label: ufTechnologyStrings.airScour,
      name: "backwash_AirScour",
      defaultValue: isIngeFlow ? "0" : "10", //backwash_AirScour
      value: isIngeFlow ? "0" : backwash_AirScour,
      inputText: "Sec",
      placeholder: "0.00",
      disabled: DISABLED_INPUTS.includes(ufTechnologyStrings.airScour),
      calcInput: false,
      className: "big-text",
      isError:
        backwash_AirScour < fieldBackwashAirScour?.minValue || backwash_AirScour > fieldBackwashAirScour?.maxValue,
      validator: fieldBackwashAirScour,
      hideInputref: isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.airScour),
    },
    {
      id: 3,
      label: ufTechnologyStrings.drain,
      name: "drain_backWash",
      defaultValue: isIngeFlow || isUXASelected ? "0" : drain_backWash,
      value: isIngeFlow ? "0" : drain_backWash,
      inputText: "Sec",
      placeholder: "0.00",
      disabled: DISABLED_INPUTS.includes(ufTechnologyStrings.drain),
      calcInput: false,
      className: "big-text",
      isError:
        drain_backWash < fieldBackwashDrain?.minValue || drain_backWash > fieldBackwashDrain?.maxValue ? true : false,
      validator: fieldBackwashDrain,
      hideInputref: isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.drain),
    },
    {
      id: 4,
      label: ufTechnologyStrings.topbackwash,
      name: "backWash1_backWash",
      defaultValue: backWash1_backWash,
      value: backWash1_backWash,
      inputText: "Sec",
      placeholder: "0.00",
      disabled: DISABLED_INPUTS.includes(ufTechnologyStrings.topbackwash),
      calcInput: false,
      className: "big-text",
      validator: fieldBackwashBottom,
      hideInputref: isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.topbackwash),
      isError: backWash1_backWash < fieldBackwashTop?.minValue || backWash1_backWash > fieldBackwashTop?.maxValue,
    },
    {
      id: 5,
      label: ufTechnologyStrings.bottomBackwash,
      name: "backWash2_backWash",
      defaultValue: backWash2_backWash,
      value: isUXASelected ? "0" : backWash2_backWash,
      inputText: "Sec",
      placeholder: "0.00",
      disabled: isUXASelected,
      calcInput: false,
      className: "big-text",
      validator: fieldBackwashTop,
      hideInputref: (isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.bottomBackwash)) || isUXASelected,
      isError: backWash2_backWash < fieldBackwashBottom?.minValue || backWash2_backWash > fieldBackwashBottom?.maxValue,
    },
    {
      id: 6,
      label: ufTechnologyStrings.forwardFlush,
      name: "forwardFlush_backWash",
      defaultValue: forwardFlush_backWash,
      value: forwardFlush_backWash,
      inputText: "Sec",
      placeholder: "0.00",
      disabled: DISABLED_INPUTS.includes(ufTechnologyStrings.forwardFlush),
      calcInput: false,
      className: "big-text",
      validator: fieldFFBackWash,
      hideInputref: isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.forwardFlush),
      isError: forwardFlush_backWash < fieldFFBackWash?.minValue || forwardFlush_backWash > fieldFFBackWash?.maxValue,
    },
    {
      id: 7,
      label: ufTechnologyStrings.lumenFill,
      name: "lF",
      defaultValue: lF,
      value: isIngeFlow ? "0" : lF,
      inputText: "Sec",
      placeholder: "0.00",
      disabled: DISABLED_INPUTS.includes(ufTechnologyStrings.lumenFill),
      calcInput: true,
      className: "big-text",
      hideInputref: isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.lumenFill),
      validator: { minValue: 0, maxValue: 0 },
    },
    {
      id: 8,
      label: ufTechnologyStrings.backwashesbetweenAirScour,
      name: "t_BWBtnAirScour",
      defaultValue: t_BWBtnAirScour,
      value: isIngeFlow ? "0" : t_BWBtnAirScour,
      disabled: DISABLED_INPUTS.includes(ufTechnologyStrings.backwashesbetweenAirScour),
      placeholder: "0",
      calcInput: false,
      className: "big-text",
      isAutoPopulated: true,
      validator: { minValue: 1, maxValue: 100 },
      hideInputref: isIngeFlow && DISABLED_INPUTS.includes(ufTechnologyStrings.backwashesbetweenAirScour),
      isError: t_BWBtnAirScour < 1 || t_BWBtnAirScour > 100,
    },
  ];

  const handleDropdownChange = e => {
    const { name, value } = e.target;
    updateStoreData({ data: { [name]: value }, calcEngineDataRefreshCount: 1 });
    dispatch(setUfDataUpdate(true));
    if (bwProtocol.length > 1) {
      const selectedFilter = bwProtocol.filter(item => item.ufbwProtocolID === parseInt(value));
      const isUXASelected = selectedFilter[0]?.protocolName.includes("UXA");
      setIsUXASelected(isUXASelected);
      if (name === "uFBWProtocolID") {
        updateStoreData({
          data: { drain_backWash: isUXASelected ? 0 : fieldBackwashDrain.defaultValue, drain: isUXASelected ? 0 : 30 },
        });
      }
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    if (!isNaN(value)) {
      const updatedData = { [name]: value };
      if (name === "backWash1_backWash" && isUXASelected) {
        updatedData["backwash_AirScour"] = value;
      }
      updateStoreData({ data: updatedData });
      dispatch(setUfDataUpdate(true));
    }
  };

  useEffect(() => {
    if (UFData.sameAsBackwash_Ind) {
      dispatch(handleSameAsBackwash(false));
    }
  }, [drain_backWash, backwash_AirScour, backWash1_backWash, backWash2_backWash, forwardFlush_backWash]);

  return (
    <>
      <BackwashStyled className='g-0'>
        <UFSystemDesignDiagram />
        <div className='backwash-wrapper'>
          <StyledCard className='backwash-temperature'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label={ufTechnologyStrings.backwashTemprature}
              />
              <IconWithTooltip
                label='Informational, it is assumed BW will use water at the design temperature.'
                icon={<InfoIcon />}
              />
            </Card.Header>
            <div className='temp-water'>
              <CustomLabel disabled={true} label='Use design temperature from feed water' />
              <InputWithText
                className='temp-water-input'
                type='text'
                disabled={true}
                isError={false}
                inputText={unitConfig.selectedUnits[2]}
                placeholder='0.00'
                value={
                  unitConfig.selectedUnits[2] !== "°C"
                    ? GlobalUnitConversion(GlobalUnitConversionStore, tempDesign, unitConfig.selectedUnits[2], "°C")
                    : tempDesign
                }
                isFocused={isFocused === 1}
                onBlur={handleBlur}
                onFocus={() => handleFocus(1)}
                tabIndex={1}
              />
            </div>
          </StyledCard>
          <StyledCard className='water-source'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label={ufTechnologyStrings.waterSource}
              />
              <IconWithTooltip
                label='Select source of water for cleaning protocols, RO permeate available if RO in design.'
                icon={<InfoIcon />}
              />
            </Card.Header>
            <div>
              <CustomLabel label={ufTechnologyStrings.backwashWaterSource} disabled={false} />
              <CustomSelect
                type=''
                id='uFBWWaterTypeID'
                name='uFBWWaterTypeID'
                value={UFData.uFBWWaterTypeID}
                placeholder={ufTechnologyStrings.backwashWaterSource}
                onChange={handleDropdownChange}
                isError={false}
                disabled={false}
              >
                {bwWaterSource?.map((w, index) => (
                  <option value={w.ufbwWaterTypeID} key={`water-source-${index}`}>
                    {w.waterTypeName}
                  </option>
                ))}
              </CustomSelect>
            </div>
            <div className='forward-flush-water'>
              <CustomLabel label={ufTechnologyStrings.forwardFlushWaterSource} />
              <CustomSelect
                type=''
                id='uFBWFlushWaterTypeID'
                name='uFBWFlushWaterTypeID'
                value={UFData.uFBWFlushWaterTypeID}
                placeholder={ufTechnologyStrings.forwardFlushWaterSource}
                onChange={handleDropdownChange}
                isError={false}
                disabled={false}
              >
                {forwardFlushWaterSource?.map((flushItem, index) => (
                  <option value={flushItem.ufbwFlushWaterTypeID} key={`flush-backwash-${index}`}>
                    {flushItem.fwFlushWaterTypeName}
                  </option>
                ))}
              </CustomSelect>
            </div>
          </StyledCard>
          <StyledCard className='backwash-protocol'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label={ufTechnologyStrings.backwashProtocol}
              />
              <IconWithTooltip
                label='Select BW protocol if specified module is suitable for more than one.'
                icon={<InfoIcon />}
              />
            </Card.Header>
            <div>
              <CustomLabel label={ufTechnologyStrings.backwashProtocol} />
              <CustomSelect
                type=''
                id='uFBWProtocolID'
                name='uFBWProtocolID'
                value={UFData.uFBWProtocolID}
                placeholder={ufTechnologyStrings.backwashProtocol}
                onChange={handleDropdownChange}
                isError={false}
                disabled={false}
              >
                {selectedUFModule?.newModuleLongName?.indexOf("UXA") >= 0 ? (
                  bwProtocol?.map((bwP, index) => (
                    <option value={bwP.ufbwProtocolID} key={`bw-protocol-${index}`}>
                      {bwP.protocolName}
                    </option>
                  ))
                ) : (
                  <option value={bwProtocol[1].ufbwProtocolID} key={`bw-protocol-${2}`}>
                    {bwProtocol[1].protocolName}
                  </option>
                )}
              </CustomSelect>
            </div>
          </StyledCard>
        </div>
        <div className='oxidant'>
          <CustomCard
            className='oxidant-card'
            header='Backwash Oxidant'
            tooltipLabel='If recommended, select chemical and dose of oxidant added to BW water.'
          >
            <UFChemicalFields
              type={CHEMICAL_TYPES.oxidant}
              chemicalRanges={chemicalRanges}
              screen={UF_CHEMICAL_SCREENS.BW}
              disabled={isIngeFlow}
            />
          </CustomCard>
        </div>
        <div className='duration'>
          <StyledCard className='duration-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                color={colors.PrimaryDarkAquaMarine}
                label={ufTechnologyStrings.durations}
              />
              <IconWithTooltip label='Provide duration of each step in the BW protocol.' icon={<InfoIcon />} />
            </Card.Header>
            <div className='duration-input-wrapper'>
              {durationData.map((item, index) => (
                <div key={index} className={`${item.className}  duration-container`}>
                  {item.calcInput ? (
                    <div>
                      <CustomLabel disabled={item.disabled} label={item.label} />
                      <CalcEngineInputBox
                        disabled={item.disabled}
                        inputText={item.inputText}
                        placeholder={item.placeholder}
                        defaultValue={item.defaultValue}
                        isFocused={isFocused === item.id}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(item.id)}
                        isError={false}
                        isAutoPopulated={item.isAutoPopulated}
                        tabIndex={index + 1}
                      />
                    </div>
                  ) : (
                    <div>
                      <CustomLabel disabled={item.disabled} label={item.label} />
                      <InputWithText
                        type='number'
                        id={item.id}
                        name={item.name}
                        disabled={item.disabled}
                        inputText={item.inputText}
                        placeholder={item.placeholder}
                        isError={item.isError}
                        value={item.value}
                        onChange={handleInputChange}
                        onKeyDown={evt => getRestrictedEvents(item.name).includes(evt.key) && evt.preventDefault()}
                        isFocused={isFocused === item.label}
                        onWheel={e => e.target.blur()}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(item.label)}
                        tabIndex={index + 1}
                      />
                      {!item.hideInputref && (
                        <InputReferenceText
                          refText={`Ranges ${item.validator?.minValue?.toFixed(getPrecisionValue(item.name))} - ${item.validator?.maxValue?.toFixed(
                            getPrecisionValue(item.name),
                          )}`}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </StyledCard>
        </div>
      </BackwashStyled>
      {isFieldValid && (
        <ProjectErrorPopup
          show={isFieldValid}
          close={() => {
            setIsFieldValid(false);
            setIsFocused(1);
          }}
          message={message}
        />
      )}
    </>
  );
};

export default Backwash;
