export const reportStrings = {
  ufFlowDetails: "UF Flow Details",
  feed: "Feed",
  product: "Product",
  source: "Source",
  tempDesign: "Temperature : Design",
  turbidity: "Turbidity",
  organics_TOC: "Organics (TOC)",
  tss: "TSS",
  tds: "TDS",
  duration: "Duration",
  flux: "Flux",
  flow: "Flow",
  interval: "Interval",
  filtration: "Filtration",
  instantaneous: "Instantaneous",
  trains: "trains",
  average: "Average",
  net: "Net",
  backwash: "Backwash",
  acidCEB: "Acid CEB",
  alkaliCEB: "Alkali CEB",
  disinfectionCEB: "Disinfection CEB",
  miniCip: "mini-CIP",
  cip: "CIP",
  membraneIntegrationTesting: "Membrane Integration Testing",
  bw: "BW",
  cebDisinf: "CEB (disinf.)",
  cipSls: "CIP SLS",
  miniCipSls: "mini-CIP SLS",
  peakFlow: "Peak Flow",
  averageFlow: "Average Flow",
  FeedGross: "Feed (Gross)",
  FeedUserFor: "Feed water used for",
  pretreatment: "Pretreatment",
  forwardFlushProcessStreams: "Forward Flush & Process streams",
  feedNet: "Feed (Net)",
  filtrateGross: "Filtrate (Gross)",
  filtrateUsedForCleaning: "Filtrate used for cleaning",
  filtrateNet: "Filtrate (Net)",
  air: "Air",
  air2: "Air 2",
  backwashBW: "Backwash (BW)",
  forwardFlush: "Forward Flush",
  ceb1Acid: "CEB 1 (Acid)",
  ceb2AlkaliOxidant: "CEB 2 (Alkali and/or Oxidant)",
  ceb3Disinfection: "CEB 3 (Disinfection)",
  miniCipRecycle: "mini-CIP Recycle",
  cipRecycle: "CIP Recycle",
  footNotes: "Foot Notes",
  remarks: "Remarks",
  maxPossibleFlowRate: "Maximum possible flow rate",
  ceb: "CEB",
};
