import React from "react";

import { WPTable } from "@components/WPTable/WPTable";
import { WPTypogrpahy } from "@components/WPTypography";

import DownloadIcon from "@common/icons/Download.svg";
import PrinterIcon from "@common/icons/Printer.svg";
import { colors } from "@common/styles/Theme";

import { WPUfConfiguringationOptionsTable } from "@features/report-new/uf/WPUfConfiguringationOptionsTable";
import { WPUfDesignWarningsTable } from "@features/report-new/uf/WPUfDesignWarningsTable";
import { WPUfFlowDetailsTable } from "@features/report-new/uf/WPUfFlowDetailsTable";
import { WPUfSystemOverViewTable } from "@features/report-new/uf/WPUfSystemOverviewTable";

import { WPReportDesignDiagram } from "../WPReportDesignDiagram";
import { WPReportProjectInfoTable } from "../WPReportProjectInfoTable";
import { WPReportSectionHeader } from "../WPReportSectionHeader";
import { WPReportSystemDiagram } from "../WPReportSystemDiagram";

// dummy test data to be removed
const columns = [
  {
    headerName: "RowHead",
    field: "key",
    rowHeader: true,
    width: "100px",
  },
  {
    headerName: "Name",
    field: "name",
    rowHeader: true,
    width: "200px",
    onCell: (_, index) => ({
      colSpan: index === 1 ? 1 : 1,
    }),
  },
  {
    headerName: "Age",
    field: "age",
    width: "20%",
  },
  {
    headerName: "Home phone",
    colSpan: 2,
    field: "tel",
    onCell: (_, index) => {
      if (index === 3) {
        return {
          rowSpan: 2,
        };
      }
      if (index === 4) {
        return {
          rowSpan: 0,
        };
      }
      return {};
    },
  },
  {
    headerName: "Phone",
    colSpan: 0,
    field: "phone",
  },
  {
    headerName: "Address",
    field: "address",
  },
];
const rows = [
  {
    key: "0",
    name: "John Brown",
    age: 32,
    tel: "0571-22098909",
    phone: 18889898989,
    address: "New York No. 1 Lake Park",
  },
  {
    key: "1",
    name: "Jim Green",
    tel: "0571-22098333",
    phone: 18889898888,
    age: 42,
    address: "London No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Joe Black",
    age: 32,
    tel: "0575-22098909",
    phone: 18900010002,
    address: "Sydney No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Jim Red",
    age: 18,
    tel: "0575-22098909",
    phone: 18900010002,
    address: "London No. 2 Lake Park",
  },
  {
    key: "4",
    name: "Jake White",
    age: 18,
    tel: "0575-22098909",
    phone: 18900010002,
    address: "Dublin No. 2 Lake Park",
  },
];

export const WPReportContentHeader = () => (
  <div className='report-content-header'>
    <WPTypogrpahy asElement='h2' color={colors.PrimaryDarkAquaMarine}>
      Ultrafiltration Summary report
    </WPTypogrpahy>
    <div>
      <img alt='Download Report' src={DownloadIcon} className='mx-3' height={24} width={24} />
      <img alt='Print Report' src={PrinterIcon} height={24} width={24} />
    </div>
  </div>
);

export const WPReportContent = () => (
  <>
    <WPReportContentHeader />

    <div className='report-content-main '>
      <WPReportProjectInfoTable />
      <WPReportSystemDiagram />
      <WPReportDesignDiagram />
      <WPUfSystemOverViewTable />
      <WPUfConfiguringationOptionsTable />
      <WPUfDesignWarningsTable />
      <WPUfFlowDetailsTable />
      <WPReportSectionHeader text='Sample table' />
      <br />
      <WPTable columns={columns} rows={rows} />
    </div>
  </>
);
