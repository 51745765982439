import React, { useEffect, useMemo, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import API_URLS from "@constants/api.urls";
import { CustomModalButtons, PROJECT_VIEWS } from "@constants/global.constants";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";
import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import { useExportProject } from "@hooks/useExportProject";
import { useResetProjectData } from "@hooks/useResetReduxData";
import useUnitConversion from "@hooks/useUnitConversion";

import { ImportProjectStrings } from "@utils/StringConstants";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import LoaderPopUp from "@components/LoaderPopUp";
import { useToastNotification } from "@components/WPToastNotification";

import DropRightIcon from "@common/icons/DropRightIcon";
import SaveIcon from "@common/icons/SaveIcon";
import AlertPopUp from "@common/notifications/AlertPopUp";
import { updateProjectInfo } from "@common/ProjectInfoSlice";
import { updateTabAvailable } from "@common/ReportIXDSlice";
import { updateTabAvailableForUF } from "@common/ReportUFSlice";
import { MyError } from "@common/utils/ErrorCreator";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";

import { useCreateDataMutation, useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import { updateLeftpanel } from "../../menu/SideMenuSlice";
import CreateNewProjectModal from "../../modals/CreateNewProjectModal";
import { detectDataChange } from "../feedsetup/FeedsetupSlice";
import { parseAndFormatNumber } from "../ix/IXConfiguration/IXhelper";
import { setIXDUpdate, updateFlagIsSaveIXDReportJson } from "../ix/IXDSlice";
import { getTechnologyId, isIXTechnology } from "../ix/IXDUtilityFunction";
import CurrencyAndUnitPopUp from "../modals/CurrencyAndUnitPopUp";
import ManageCase from "../modals/ManageCase";
import OpenProject from "../modals/OpenProject";
import ProjectCostAndChemicalLibrary from "../modals/ProjectCostAndChemicalLibrary";
import ProjectInformationModal from "../modals/ProjectInformationModal";
import Pumps from "../modals/Pumps";
import { setNodeAndEdge } from "../systemdesign/processDiagramSlice";
import ProjectSaveWarning from "../systemdesign/ProjectSaveWarning";
import { setUfDataUpdate, updateUFStoreData } from "../uf/UFSlice";

import ProjectSavePopup from "./ProjectSavePopup";
import SecondaryMenuStyled from "./SecondaryMenuStyled";
const FeedWaterHeader = ({ currentPanel }) => {
  const dispatch = useDispatch();
  const { showSuccessNotification } = useToastNotification();
  const { convertUnitNumber } = useUnitConversion();
  const { resetProjectData } = useResetProjectData();

  const UserInfoStore = useSelector(state => state.userInfo.data);
  const scrollDirection = useSelector(state => state.scrollData.direction);
  const ixStore = useSelector(state => state.IXStore.data);
  const ixStoreObj = useSelector(state => state.IXStore);
  const { isForDrinkingWater } = useSelector(state => state.UFStore);
  const UFData = useSelector(state => state.UFStore.data);
  const userId = UserInfoStore ? UserInfoStore.UserId : 0;
  const { showFinalParameterTab } = useSelector(state => state.IXStore);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const { projectActiveView, projectConfig } = useSelector(state => state.projectInfo);
  const unit = projectConfig?.unitConfig;
  const caseTreatmentID = useSelector(
    state =>
      state.processDiagramSlice.lstTechnologyLists.find(
        item => item?.technologyID == getTechnologyId(ixStore.treatmentName) && !item?.isDeleted,
      )?.caseTreatmentID,
  );
  const [openProject, setOpenProject] = useState(false);
  const [manageCase, setManageCase] = useState(false);
  const [currency, setCurrency] = useState(false);
  const [projectInfo, setProjectInfo] = useState(false);
  const [openPump, setOpenPump] = useState(false);
  const [openNewProject, setOpenNewProject] = useState(false);
  const [openNewProjectPopup, setOpenNewProjectPopup] = useState(false);
  const [openChemicalLibrary, setOpenChemicalLibrary] = useState(false);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const [saveWarning, setSaveWarning] = useState(false);
  const { isDataUpdated, feedWaterData, nodes, edges, lstTechnologyLists } = useSelector(
    state => state.processDiagramSlice,
  );
  const dataisChanged = useSelector(state => state.Feedsetupdetailsdatapanel.dataisChanged);

  const tab = useSelector(state => state.tabData.tab);
  const { isUfDataUpdated } = useSelector(state => state.UFStore);
  const ixResinID1 = ixStore?.selectedResinList[0]?.ixResinID1;
  const ixResinID2 = ixStore?.selectedResinList[0]?.ixResinID2;
  const ixResinID3 = ixStore?.selectedResinList[1]?.ixResinID1;
  const ixResinID4 = ixStore?.selectedResinList[1]?.ixResinID2;
  const resinVal = useSelector(state => state.IXStore.data?.listRegenConds);

  const ixStoreAdvance = useSelector(state => state.IXStore?.data?.listAdvRegen);
  const ixRegenreteDose = useSelector(state => state.IXStore?.data?.listProductQualityandregeneration);
  const [updateData, response] = useUpdateDataMutation();
  const [showAlert, setAlertVisibility] = useState(false);
  const [alertData, setAlert] = useState({ type: "", message: "" });
  const projectID = ProjectInfoStore ? ProjectInfoStore.projectID : 1;
  const caseID = ProjectInfoStore ? ProjectInfoStore.caseId : 1;
  const loggedInUserID = UserInfoStore.UserId;
  const treatmentObjID = "1";
  const handleShowAlert = (type, message) => {
    setAlert({ type, message });
    setAlertVisibility(true);
  };
  const handleHideAlert = () => {
    setAlert({ type: "", message: "" });
    setAlertVisibility(false);
  };

  const isSaveEligible = useMemo(() => projectActiveView !== PROJECT_VIEWS.REPORT, [projectActiveView]);

  const navigate = useNavigate();

  const handleOpenCurrencyUnits = () => setCurrency(true);
  const handleShowOpenProject = () => setOpenProject(true);
  const handleOpenManageCase = () => setManageCase(true);
  const handleOpenProjectInformation = () => setProjectInfo(true);
  const handleOpenPump = () => setOpenPump(true);
  const handleOpenNewProject = () => setOpenNewProject(true);
  const handleOpenChemicalLibrary = () => setOpenChemicalLibrary(true);
  const [isToExport, setIsToExport] = useState(false);

  const navigateHome = () => {
    if (saveWarning) {
      setSaveWarning(false);
      navigate("/home");
    } else {
      if (!isToExport) {
        showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_SAVED);
      } else {
        exportProject(ProjectInfoStore.projectID, ProjectInfoStore.projectName);
      }
    }
    resetDataChangeFlag();
  };

  const updateCreateprojc = e => {
    e.preventDefault();
    setOpenNewProject(false);
  };

  const StoreDataFeed = useSelector(state => state.Feedsetupdetailsdatapanel.streamData);
  const [resinItem1, resinItem2] = ixStore.selectedResinList;

  const TechnologyStoreData = useSelector(state => state.processDiagramSlice.addedTechnology);
  const Technologynames = TechnologyStoreData.filter(item => item.id > 0);
  let Technology = [];
  const lengthcount = Technologynames.length;

  if (lengthcount == 1) {
    Technology = Technologynames[0].heading;
  } else if (lengthcount > 1) {
    Technology = "Multiple";
  } else {
    Technology = "null";
  }

  const [IXData_PostData] = useCreateDataMutation();
  const [updateFeedsetupData] = useCreateDataMutation();
  const [POSTUFAutoSaveData] = useCreateDataMutation();

  const resetDataChangeFlag = () => {
    dispatch(detectDataChange(false));
    dispatch(setUfDataUpdate(false));
  };

  const saveData = async selectedTab => {
    switch (selectedTab) {
      case "Feed Setup":
        {
          const savewatertypeid = StoreDataFeed.lstrequestsavefeedwater[0].streams[0].waterTypeID;
          const savewatersubtypeid = StoreDataFeed.lstrequestsavefeedwater[0].streams[0].waterSubTypeID;

          if (Technology == "UF") {
            if (savewatertypeid > 0 || savewatersubtypeid > 0) {
              dispatch(updateUFStoreData({ isSaveReportJson: true }));
              const response = await updateFeedsetupData(StoreDataFeed);
              if (response.data.responseMessage == "Success") {
                navigateHome();
              }
            } else {
              const warningMessage =
                "Water type and sub type is required for feed setup data save,Please select water type and water subtype !! ";
              handleShowAlert("warning", warningMessage);
            }
          } else {
            const response = await updateFeedsetupData(StoreDataFeed);
            if (response.data.responseMessage == "Success") {
              navigateHome();
            }
          }
        }
        break;
      case "System Design":
        {
          const response = await updateData({
            Method: "masterdata/api/v1/SystemDesign",
            flowValue: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                feedWaterData.flowValue === undefined ? 100 : feedWaterData.flowValue,
                "m³/h",
                unit.selectedUnits[1],
              ).toFixed(2),
            ),
            feedFlow: feedWaterData?.feedFlow,
            caseID: feedWaterData.caseID,
            projectID: feedWaterData.projectID,
            waterTypeID: feedWaterData.waterTypeID,
            userID: userId,
            processMap: { nodes: nodes, edges: edges },
            lstTechnologyLists,
          });
          if (response.data.responseMessage == "Success") {
            navigateHome();
          }
        }

        break;
      case "UF":
        {
          dispatch(updateUFStoreData({ isSaveReportJson: true }));
          const data = {
            userID: UFData.userID,
            projectID: UFData.projectID,
            caseID: UFData.caseID,
            treatmentName: "UF",
            treatmentObjID: UFData.treatmentObjID,
            fromTreatmentObjID: UFData.fromTreatmentObjID,
            treatmentSeqNo: UFData.treatmentSeqNo,
            recoveryTypeID: parseInt(UFData.recoveryTypeID),
            recovery: Number(UFData.recovery),
            feed: Number(UFData.feed),
            automatic: UFData.automatic,
            recoveryRo: Number(UFData.recoveryRo),
            compactionTemperature: Number(UFData.compactionTemperature),
            isCompactionFlux: UFData.isCompactionFlux,
            uFDesignFluxID: parseInt(UFData.uFDesignFluxID),
            caseTreatmentID: parseInt(UFData.caseTreatmentID),
            filtrateFlux: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.filtrateFlux,
                "LMH",
                unit.selectedUnits[4],
              ).toFixed(2),
            ),
            backwashFlux: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.backwashFlux,
                "LMH",
                unit.selectedUnits[4],
              ).toFixed(2),
            ),
            cEBFlux: Number(
              GlobalUnitConversion(GlobalUnitConversionStore, UFData.cEBFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
            ),
            forwardFlushFlow: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.forwardFlushFlow,
                "m³/h",
                unit.selectedUnits[1],
              ).toFixed(2),
            ),
            airFlow: Number(
              GlobalUnitConversion(GlobalUnitConversionStore, UFData.airFlow, "Nm³/h", unit.selectedUnits[18]).toFixed(
                2,
              ),
            ),
            aerationAirFlow: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.aerationAirFlow,
                "Nm³/h",
                unit.selectedUnits[18],
              ).toFixed(2),
            ),
            recycleFlowRate: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.recycleFlowRate,
                "m³/h",
                unit.selectedUnits[1],
              ).toFixed(2),
            ),
            recycleFlowRate_MiniCIP: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.recycleFlowRate_MiniCIP,
                "m³/h",
                unit.selectedUnits[1],
              ).toFixed(2),
            ),
            uFModuleID: parseInt(UFData.uFModuleID),
            flow_FF1: Number(UFData.flow_FF1),
            flow_FF2: Number(UFData.flow_FF2),
            flow_FF3: Number(UFData.flow_FF3),
            flow_FF4: Number(UFData.flow_FF4),
            aDBWDisplacement: Number(UFData.aDBWDisplacement),
            fTLDisplacement: Number(UFData.fTLDisplacement),
            typicalWaitDuration_Dupont: Number(UFData.typicalWaitDuration_Dupont),
            typicalPumpRamp_Dupont: Number(UFData.typicalPumpRamp_Dupont),
            typicalWaitDuration_Inge: Number(UFData.typicalWaitDuration_Inge),
            typicalPumpRamp_Inge: Number(UFData.typicalPumpRamp_Inge),
            typicalWaitDuration_Memcor: Number(UFData.typicalWaitDuration_Memcor),
            typicalPumpRamp_Memcor: Number(UFData.typicalPumpRamp_Memcor),
            uFDesignCycleIntervalsID: parseInt(UFData.uFDesignCycleIntervalsID),
            backwash_design: Number(UFData.backwash_design),
            airScour_design: Number(UFData.airScour_design),
            acidCEB: Number(UFData.acidCEB),
            alkaliOxidantCEB: Number(UFData.alkaliOxidantCEB),
            cIP: Number(UFData.cIP),
            miniCIP: Number(UFData.miniCIP),
            disinfectionCEB: Number(UFData.disinfectionCEB),
            t_CEB_Rinse12: Number(UFData.t_CEB_Rinse12),
            t_CEB_Rinse2: Number(UFData.t_CEB_Rinse2),
            t_BW1_CEBrinse: Number(UFData.bwRinseDrainTop),
            t_BW2_CEBrinse: Number(UFData.bwRinseDrainBottom),
            bwRinseDrainBottom: Number(UFData.bwRinseDrainBottom),
            bwRinseDrainTop: Number(UFData.bwRinseDrainTop),
            uFBWCEBStandbyOptionID: parseInt(UFData.uFBWCEBStandbyOptionID),
            bWPerCEBstandbyTrains: parseInt(UFData.bWPerCEBstandbyTrains),
            uFConfigurationID: parseInt(UFData.uFConfigurationID),
            uFCIPStandbyTrainOptionID: parseInt(UFData.uFCIPStandbyTrainOptionID),
            cIPstandbyTrains: parseInt(UFData.cIPstandbyTrains),
            integraPackDesign_Ind: UFData.integraPackDesign_Ind,
            drinkingWaterInd: UFData.drinkingWaterInd,
            membraneintergrityoption_Ind: UFData.membraneintergrityoption_Ind,
            modulesPerSkid: parseInt(UFData.modulesPerSkid),
            modulesPerTrain: parseInt(UFData.modulesPerTrain),
            offlinetimepertrain: parseInt(UFData.offlinetimepertrain),
            onlineTrains: parseInt(UFData.onlineTrains),
            redundantStandbyTrains: parseInt(UFData.redundantStandbyTrains),
            skids: parseInt(UFData.skids),
            skidsPerTrain: parseInt(UFData.skidsPerTrain),
            uFStorageTankOptionID: parseInt(UFData.uFStorageTankOptionID),
            totalModules: parseInt(UFData.totalModules),
            totalTrains: parseInt(UFData.totalTrains),
            redundantTrains: parseInt(UFData.redundantTrains),
            isBWCEBStandbyTrainsEnabled: UFData.isBWCEBStandbyTrainsEnabled,
            radTR1: UFData.radTR1,
            radTR2: UFData.radTR2,
            radTR3: UFData.radTR3,
            radMR1: UFData.radMR1,
            radMR2: UFData.radMR2,
            radMR3: UFData.radMR3,
            uFFiltrationID: Number(UFData.uFFiltrationID),
            backwash_Filtration: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.backwash_Filtration,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            acidCEB_Filtration: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.acidCEB_Filtration,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            alkaliCEB_Filtration: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.alkaliCEB_Filtration,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            cIP_Filtration: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.cIP_Filtration,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            miniCIP_Filtration: Number(UFData.miniCIP_Filtration),
            strainerRecovery: Number(UFData.strainerRecovery),
            strainerSize: Number(UFData.strainerSize),
            uFTanksID: Number(UFData.uFTanksID),
            chemicalStorageTime: Number(UFData.chemicalStorageTime),
            bWTankRefillRate: Number(UFData.bWTankRefillRate),
            filterateTank: Number(UFData.filterateTank),
            bWTank: Number(UFData.bWTank),
            cIPTank: Number(UFData.cIPTank),
            uFEquipmentPressureID: Number(UFData.uFEquipmentPressureID),
            maxAirScourPressure: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.maxAirScourPressure,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            maxAirProcPressure: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.maxAirProcPressure,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            filteratePressure: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.filteratePressure,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            nonIntegraPacTrainPresDrop: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.nonIntegraPacTrainPresDrop,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            integraPacFiltrationPreDrop: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.integraPacFiltrationPreDrop,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            backwashPipingPreDrop: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.backwashPipingPreDrop,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            cIPPipingPreDrop: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.cIPPipingPreDrop,
                "bar",
                unit.selectedUnits[3],
              ).toFixed(2),
            ),
            uFPowerID: Number(UFData.uFPowerID),
            pLCPowerReqPertrain: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.pLCPowerReqPertrain,
                "kW",
                unit.selectedUnits[9],
              ).toFixed(2),
            ),
            volvePowerReqPerTrain: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.volvePowerReqPerTrain,
                "kW",
                unit.selectedUnits[9],
              ).toFixed(2),
            ),
            uFValvesID: Number(UFData.uFValvesID),
            valvesPerTrain: Number(UFData.valvesPerTrain),
            valveOpenCloseDuration: Number(UFData.valveOpenCloseDuration),
            uFCEBID: Number(UFData.uFCEBID),
            uFCEBWaterTypeID: parseInt(UFData.uFCEBWaterTypeID),
            ceb_AirScour: Number(UFData.ceb_AirScour),
            backWash1_CEB: Number(UFData.backWash1_CEB),
            backWash2_CEB: Number(UFData.backWash2_CEB),
            cEBTemperature: parseInt(UFData.cEBTemperature),
            chemicalSoakingDuration_CEB: parseInt(UFData.chemicalSoakingDuration_CEB),
            drain: Number(UFData.drain),
            forwardFlush: Number(UFData.forwardFlush),
            designTemperature_Ind: UFData.designTemperature_Ind,
            ceb_LSI: Number(UFData.ceb_LSI),
            sameAsBackwash_Ind: UFData.sameAsBackwash_Ind,
            alkaliEnabled_Ind_CEB: UFData.alkaliEnabled_Ind_CEB,
            organicEnabled_Ind_CEB: UFData.organicEnabled_Ind_CEB,
            oxidantEnabled_Ind_CEB: UFData.oxidantEnabled_Ind_CEB,
            mineralEnabled_Ind_CEB: UFData.mineralEnabled_Ind_CEB,
            disOxidantEnabled_Ind_CEB: UFData.disOxidantEnabled_Ind_CEB,
            mineralValue_CEB: Number(UFData.mineralValue_CEB),
            organicValue_CEB: Number(UFData.organicValue_CEB),
            oxidantValue_CEB: Number(UFData.oxidantValue_CEB),
            alkaliValue_CEB: Number(UFData.alkaliValue_CEB),
            disOxidantValue_CEB: Number(UFData.disOxidantValue_CEB),
            alkaliChemId_CEB: UFData.alkaliChemId_CEB.toString(),
            mineralChemId_CEB: UFData.mineralChemId_CEB.toString(),
            organicChemId_CEB: UFData.organicChemId_CEB.toString(),
            oxidantChemId_CEB: UFData.oxidantChemId_CEB.toString(),
            disOxidantChemId_CEB: UFData.disOxidantChemId_CEB.toString(),
            alkaliValueInPh_Ind_CEB: UFData.alkaliValueInPh_Ind_CEB,
            mineralValueInPh_Ind_CEB: UFData.mineralValueInPh_Ind_CEB,
            uFCIPID: Number(UFData.uFCIPID),
            bWStepInCIP: parseInt(UFData.bWStepInCIP),
            chemicalSoakingDuration_CIP: Number(UFData.chemicalSoakingDuration_CIP),
            uFCIPWaterTypeID: parseInt(UFData.uFCIPWaterTypeID),
            heatingStepDuration: Number(UFData.heatingStepDuration),
            cip_LSI: Number(UFData.cip_LSI),
            recycleDuration: Number(UFData.recycleDuration),
            recycleTemperature: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                UFData.recycleTemperature,
                "°C",
                unit.selectedUnits[2],
              ).toFixed(2),
            ),
            rinseBWCycle: parseInt(UFData.rinseBWCycle),
            cIPRinseSoakCycle: Number(UFData.cIPRinseSoakCycle),
            alkaliEnabled_Ind_CIP: UFData.alkaliEnabled_Ind_CIP,
            organicEnabled_Ind_CIP: UFData.organicEnabled_Ind_CIP,
            oxidantEnabled_Ind_CIP: UFData.oxidantEnabled_Ind_CIP,
            mineralEnabled_Ind_CIP: UFData.mineralEnabled_Ind_CIP,
            oxidant2Enabled_Ind_CIP: UFData.oxidant2Enabled_Ind_CIP,
            mineralValue_CIP: Number(UFData.mineralValue_CIP),
            organicValue_CIP: Number(UFData.organicValue_CIP),
            oxidantValue_CIP: Number(UFData.oxidantValue_CIP),
            alkaliValue_CIP: Number(UFData.alkaliValue_CIP),
            oxidant2Value_CIP: Number(UFData.oxidant2Value_CIP),
            alkaliChemId_CIP: UFData.alkaliChemId_CIP.toString(),
            mineralChemId_CIP: UFData.mineralChemId_CIP.toString(),
            organicChemId_CIP: UFData.organicChemId_CIP.toString(),
            oxidantChemId_CIP: UFData.oxidantChemId_CIP.toString(),
            oxidant2ChemId_CIP: UFData.oxidant2ChemId_CIP?.toString(),
            alkaliValueInPh_Ind_CIP: UFData.alkaliValueInPh_Ind_CIP,
            mineralValueInPh_Ind_CIP: UFData.mineralValueInPh_Ind_CIP,
            uFBackWashID: parseInt(UFData.uFBackWashID),
            bWTemperature: Number(UFData.bWTemperature),
            oxidantEnabled_Ind_BW: UFData.oxidantEnabled_Ind_BW,
            uFBWWaterTypeID: parseInt(UFData.uFBWWaterTypeID),
            uFBWFlushWaterTypeID: parseInt(UFData.uFBWFlushWaterTypeID),
            uFBWProtocolID: parseInt(UFData.uFBWProtocolID),
            oxidantChemId_BW: UFData.oxidantChemId_BW.toString(),
            oxidantValue_BW: Number(UFData.oxidantValue_BW),
            backwash_AirScour: Number(UFData.backwash_AirScour),
            backWash1_backWash: Number(UFData.backWash1_backWash),
            backWash2_backWash: Number(UFData.backWash2_backWash),
            drain_backWash: Number(UFData.drain_backWash),
            forwardFlush_backWash: Number(UFData.forwardFlush_backWash),
            lF: Number(UFData.lF),
            t_FTL: Number(UFData.t_FTL),
            t_BWBtnAirScour: parseInt(UFData.t_BWBtnAirScour),
            uFMiniCIPID: Number(UFData.uFMiniCIPID),
            bWStepInMiniCIP: Number(UFData.bWStepInMiniCIP),
            rinseBWCycle_MiniCIP: parseInt(UFData.rinseBWCycle_MiniCIP),
            chemicalSoakingDuration_MiniCIP: Number(UFData.chemicalSoakingDuration_MiniCIP),
            uFMiniCIPWaterTypeID: parseInt(UFData.uFMiniCIPWaterTypeID),
            heatingStepDuration_MiniCIP: Number(UFData.heatingStepDuration_MiniCIP),
            lSI_MiniCIP: Number(UFData.lSI_MiniCIP),
            recycleDuration_MiniCIP: Number(UFData.recycleDuration_MiniCIP),
            recycleTemperature_MiniCIP: Number(UFData.recycleTemperature_MiniCIP),
            cIPRinseSoakCycle_MiniCIP: Number(UFData.cIPRinseSoakCycle_MiniCIP),
            alkaliEnabled_Ind_MiniCIP: UFData.alkaliEnabled_Ind_MiniCIP,
            organicEnabled_Ind_MiniCIP: UFData.organicEnabled_Ind_MiniCIP,
            oxidantEnabled_Ind_MiniCIP: UFData.oxidantEnabled_Ind_MiniCIP,
            mineralEnabled_Ind_MiniCIP: UFData.mineralEnabled_Ind_MiniCIP,
            oxidant2Enabled_Ind_MiniCIP: UFData.oxidant2Enabled_Ind_MiniCIP,
            mineralValue_MiniCIP: Number(UFData.mineralValue_MiniCIP),
            organicValue_MiniCIP: Number(UFData.organicValue_MiniCIP),
            oxidantValue_MiniCIP: Number(UFData.oxidantValue_MiniCIP),
            alkaliValue_MiniCIP: Number(UFData.alkaliValue_MiniCIP),
            oxidant2Value_MiniCIP: Number(UFData.oxidant2Value_MiniCIP),
            alkaliChemId_MiniCIP: UFData.alkaliChemId_MiniCIP.toString(),
            mineralChemId_MiniCIP: UFData.mineralChemId_MiniCIP.toString(),
            organicChemId_MiniCIP: UFData.organicChemId_MiniCIP.toString(),
            oxidantChemId_MiniCIP: UFData.oxidantChemId_MiniCIP.toString(),
            oxidant2ChemId_MiniCIP: UFData.oxidant2ChemId_MiniCIP.toString(),
            alkaliValueInPh_Ind_MiniCIP: UFData.alkaliValueInPh_Ind_MiniCIP,
            mineralValueInPh_Ind_MiniCIP: UFData.mineralValueInPh_Ind_MiniCIP,
            ufSpecialFeatureID: parseInt(UFData.ufSpecialFeatureID),
            pUFTechnologyID: parseInt(UFData.pUFTechnologyID),
          };
          const MethodName = { Method: API_URLS.ufDetails };
          const UFRequestDetails = {
            ...MethodName,
            ...data,
            ["drinkingWater_Ind"]: isForDrinkingWater,
            ["userID"]: loggedInUserID,
            ["projectID"]: projectID,
            ["caseID"]: caseID,
            ["treatmentName"]: "UF",
            ["treatmentObjID"]: treatmentObjID,
          };
          dispatch(setUfDataUpdate(false));
          navigateHome();
          const autoSaveUFResponse = await POSTUFAutoSaveData(UFRequestDetails);
          if (autoSaveUFResponse?.data?.responseMessage == "Success") {
            console.log("***********  UF AUTOSAVE ON UF SubMenu Change- success", autoSaveUFResponse?.data);
          } else {
            console.log("***********  UF AUTOSAVE ON UF SubMenu Change- failed");
            throw new MyError("UF Auto Save Functionality Failed", 403, "ApiError");
          }
        }
        break;
      case "IXOS":
        {
          console.log("save project case IXOS");
        }
        break;
      case "IXB":
        {
          console.log("save project case1 IXB");
        }

        break;
      case "IXN":
        {
          console.log("save project case1 IXN");
        }
        break;
      case "IXD":
      case "IXMB":
        {
          dispatch(updateFlagIsSaveIXDReportJson(true));
          let dummyListFinal = [];
          if (ixStoreObj.viewReport == "true" || showFinalParameterTab) {
            dummyListFinal = ixStoreObj?.listFinalParamAdj;
          } else {
            dummyListFinal = ixStoreObj?.existingPlantDescription;
          }
          if (dummyListFinal.length <= 1) {
            let vesselCount = 0;
            if (ixStoreObj.resinName4 !== null) {
              vesselCount = 4;
            } else if (ixStoreObj.resinName3 !== null) {
              vesselCount = 3;
            } else if (ixStoreObj.resinName2 !== null) {
              vesselCount = 2;
            } else {
              vesselCount = 1;
            }
            const dummyArray = Array.from({ length: vesselCount }, (_, index) => ({
              resinType: ixStoreObj.resinData[ixStoreObj[`resinName${index + 1}`]],
              resinName: ixStoreObj[`resinName${index + 1}`],
              resinId:
                ixStoreObj[`resinName${index + 1}`] == "WAC"
                  ? ixResinID1
                  : ixStoreObj[`resinName${index + 1}`] == "SAC"
                    ? ixResinID2
                    : ixStoreObj[`resinName${index + 1}`] == "WBA"
                      ? ixResinID3
                      : ixResinID4,
              vesselNo: index + 1,
              resinVolumeAsDelivered: 0,
              vesselDiameter: 0,
              resinBedHeightAsDelivered: 0,
              resinBedStandardHeight: 0,
              resinBedHeightAsExhausted: 0,
              resinBedHeightAsRegenerated: 0,
              inertResinVolume: 0,
              inertBedHeight: 0,
              freeBoard: 0,
              vesselCylindricalHeight: 0,
              vesselWallThickness: 0,
              pressureDropwithRecomQty: 0,
              resinPackagingSize: 0,
              ixfpaRadioButtonID: 0,
            }));
            if (vesselCount < 1) {
              dummyListFinal = dummyArray;
            }
          }
          const list = [...dummyListFinal];
          dummyListFinal = list.map((item, index) => {
            const resinVolumeAsDelivered = convertUnitNumber(
              item.resinVolumeAsDelivered,
              UNITS.CUBIC_METER,
              SELECTED_UNIT.VOLUME,
              0,
            );
            const inertResinVolume = convertUnitNumber(
              item.inertResinVolume,
              UNITS.CUBIC_METER,
              SELECTED_UNIT.VOLUME,
              0,
            );
            const vesselDiameter = convertUnitNumber(item.vesselDiameter, UNITS.millimeter, SELECTED_UNIT.LENGTH, 0);
            const resinBedHeightAsDelivered = convertUnitNumber(
              item.resinBedHeightAsDelivered,
              UNITS.millimeter,
              SELECTED_UNIT.LENGTH,
              0,
            );
            const resinBedStandardHeight = convertUnitNumber(
              item.resinBedStandardHeight,
              UNITS.millimeter,
              SELECTED_UNIT.LENGTH,
              0,
            );
            const resinBedHeightAsRegenerated = convertUnitNumber(
              item.resinBedHeightAsRegenerated,
              UNITS.millimeter,
              SELECTED_UNIT.LENGTH,
              0,
            );
            const resinBedHeightAsExhausted = convertUnitNumber(
              item.resinBedHeightAsExhausted,
              UNITS.millimeter,
              SELECTED_UNIT.LENGTH,
              0,
            );
            const inertBedHeight = convertUnitNumber(item.inertBedHeight, UNITS.millimeter, SELECTED_UNIT.LENGTH, 0);
            const vesselCylindricalHeight = convertUnitNumber(
              item.vesselCylindricalHeight,
              UNITS.millimeter,
              SELECTED_UNIT.LENGTH,
              0,
            );
            const vesselWallThickness = convertUnitNumber(
              item.vesselWallThickness,
              UNITS.millimeter,
              SELECTED_UNIT.LENGTH,
              0,
            );
            const freeBoard = convertUnitNumber(item.freeBoard, UNITS.millimeter, SELECTED_UNIT.LENGTH, 0);
            return {
              ...item,
              resinVolumeAsDelivered: parseAndFormatNumber(resinVolumeAsDelivered),
              inertResinVolume: parseAndFormatNumber(inertResinVolume),
              vesselDiameter: parseAndFormatNumber(vesselDiameter),
              resinBedHeightAsDelivered: parseAndFormatNumber(resinBedHeightAsDelivered),
              resinBedStandardHeight: parseAndFormatNumber(resinBedStandardHeight),
              resinBedHeightAsRegenerated: parseAndFormatNumber(resinBedHeightAsRegenerated),
              resinBedHeightAsExhausted: parseAndFormatNumber(resinBedHeightAsExhausted),
              inertBedHeight: parseAndFormatNumber(inertBedHeight),
              vesselCylindricalHeight: parseAndFormatNumber(vesselCylindricalHeight),
              vesselWallThickness: parseAndFormatNumber(vesselWallThickness),
              freeBoard: parseAndFormatNumber(freeBoard),
            };
          });
          /*----Unit conversion for regenenConditionPage start-----*/
          const [a, b] = resinVal;
          let cationTemp = resinVal[0]?.temperature;
          let anionTemp = resinVal[1]?.temperature;
          if (a) {
            cationTemp = Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                resinVal[0]?.temperature,
                "°C",
                unit.selectedUnits[2],
              ).toFixed(2),
            );
          }
          if (b) {
            anionTemp = Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                resinVal[1]?.temperature,
                "°C",
                unit.selectedUnits[2],
              ).toFixed(2),
            );
          }
          const [Ra, Rd] = ixRegenreteDose;
          // averageConductivityVal
          let cationRegenreteDoseVel = ixRegenreteDose[0]?.regenerantDoseVal4;
          let anionRegenreteDoseVel = ixRegenreteDose[1]?.regenerantDoseVal4;
          let cationAverageConduc = ixRegenreteDose[0]?.averageConductivityVal;
          let anionAverageConduc = ixRegenreteDose[1]?.averageConductivityVal;
          let cationendpointConduc = ixRegenreteDose[0]?.endpointConductivityVal;
          let anionendpointConduc = ixRegenreteDose[1]?.endpointConductivityVal;
          if (Ra) {
            cationRegenreteDoseVel = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixRegenreteDose[0]?.regenerantDoseVal4,
              "g/L",
              unit.selectedUnits[14],
            );
            cationAverageConduc = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixRegenreteDose[0]?.averageConductivityVal,
              "µS/cm",
              unit.selectedUnits[17],
            );
            cationendpointConduc = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixRegenreteDose[0]?.endpointConductivityVal,
              "µS/cm",
              unit.selectedUnits[17],
            );
          }
          if (Rd) {
            anionRegenreteDoseVel = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixRegenreteDose[1]?.regenerantDoseVal4,
              "g/L",
              unit.selectedUnits[14],
            );
            anionAverageConduc = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixRegenreteDose[1]?.averageConductivityVal,
              "µS/cm",
              unit.selectedUnits[17],
            );
            anionendpointConduc = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixRegenreteDose[1]?.endpointConductivityVal,
              "µS/cm",
              unit.selectedUnits[17],
            );
          }
          /*----Unit conversion for regenenConditionPage end-----*/
          /*----Unit conversion for Advance Regeneration start-----*/
          const [c, d] = ixStoreAdvance;
          let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
          let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
          let cationDisVol = ixStoreAdvance[0]?.displacementVolume;
          let anionDisVol = ixStoreAdvance[1]?.displacementVolume;
          let cationFasVol = ixStoreAdvance[0]?.fatRinseVolume;
          let anionFasVol = ixStoreAdvance[1]?.fatRinseVolume;
          if (c) {
            cationregenVel = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStoreAdvance[0]?.regenerationVelocity,
              "BV/h",
              unit.selectedUnits[10],
            );
            cationDisVol = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStoreAdvance[0]?.displacementVolume,
              "BV",
              unit.selectedUnits[13],
            );
            cationFasVol = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStoreAdvance[0]?.fatRinseVolume,
              "BV",
              unit.selectedUnits[13],
            );
          }
          if (d) {
            anionregeneVel = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStoreAdvance[1]?.regenerationVelocity,
              "BV/h",
              unit.selectedUnits[10],
            );
            anionDisVol = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStoreAdvance[1]?.displacementVolume,
              "BV",
              unit.selectedUnits[13],
            );
            anionFasVol = GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStoreAdvance[1]?.fatRinseVolume,
              "BV",
              unit.selectedUnits[13],
            );
          }
          /*----Unit conversion for Advance Regeneration end-----*/

          const MethodName = { Method: "ix/api/v1/IXData" };
          const IXData_Method_Body = {
            ...MethodName,
            ...ixStore,
            ["treatmentObjID"]: caseTreatmentID,
            ["caseTreatmentID"]: caseTreatmentID,
            ["space_velocity_txt"]: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                ixStore.space_velocity_txt,
                "BV/h",
                unit.selectedUnits[10],
              ).toFixed(2),
            ),
            selectedResinList: [
              { ...resinItem1, ["inert"]: resinItem1?.inert || 0 },
              { ...resinItem2, ["inert"]: resinItem2?.inert || 0 },
            ],
            listRegenConds: [
              { ...ixStore.listRegenConds[0], ["temperature"]: cationTemp },
              { ...ixStore.listRegenConds[1], ["temperature"]: anionTemp },
            ],
            listAdvRegen: [
              {
                ...ixStore.listAdvRegen[0],
                ["regenerationVelocity"]: Number(cationregenVel?.toFixed(2)),
                ["displacementVolume"]: Number(cationDisVol?.toFixed(2)),
                ["fatRinseVolume"]: Number(cationFasVol?.toFixed(2)),
              },
              {
                ...ixStore.listAdvRegen[1],
                ["regenerationVelocity"]: Number(anionregeneVel?.toFixed(2)),
                ["displacementVolume"]: Number(anionDisVol?.toFixed(2)),
                ["fatRinseVolume"]: Number(anionFasVol?.toFixed(2)),
              },
            ],
            listProductQualityandregeneration: [
              {
                ...ixStore.listProductQualityandregeneration[0],
                ["regenerantDoseVal4"]: Number(cationRegenreteDoseVel?.toFixed(2)),
                ["averageConductivityVal"]: Number(cationAverageConduc?.toFixed(2)),
                ["endpointConductivityVal"]: Number(cationendpointConduc?.toFixed(2)),
              },
              {
                ...ixStore.listProductQualityandregeneration[1],
                ["regenerantDoseVal4"]: Number(anionRegenreteDoseVel?.toFixed(2)),
                ["averageConductivityVal"]: Number(anionAverageConduc?.toFixed(2)),
                ["endpointConductivityVal"]: Number(anionendpointConduc?.toFixed(2)),
              },
            ],
            listFinalParamAdj: showFinalParameterTab ? dummyListFinal : [],
            treatmentName: ixStore.treatmentName,
            pdExtPiping: GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStore.pdExtPiping,
              "bar",
              unit.selectedUnits[3],
            ).toFixed(2),
            pdIntDistributor: GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStore.pdIntDistributor,
              "bar",
              unit.selectedUnits[3],
            ).toFixed(2),
            effluentPressure: GlobalUnitConversion(
              GlobalUnitConversionStore,
              ixStore.effluentPressure,
              "bar",
              unit.selectedUnits[3],
            ),
            tankTemperature: Number(
              GlobalUnitConversion(GlobalUnitConversionStore, ixStore.tankTemperature, "°C", unit.selectedUnits[2]),
            ).toFixed(2),
            backwashTowerDiameter: Number(
              GlobalUnitConversion(
                GlobalUnitConversionStore,
                ixStore.backwashTowerDiameter,
                "mm",
                unit.selectedUnits[8],
              ),
            ).toFixed(2),
            sacRegenVesselDia: Number(
              GlobalUnitConversion(GlobalUnitConversionStore, ixStore.sacRegenVesselDia, "mm", unit.selectedUnits[8]),
            ).toFixed(2),
            sbaRegenVesselDia: Number(
              GlobalUnitConversion(GlobalUnitConversionStore, ixStore.sbaRegenVesselDia, "mm", unit.selectedUnits[8]),
            ).toFixed(2),
          };
          await IXData_PostData(IXData_Method_Body);
          navigateHome();
          dispatch(setIXDUpdate(false));
        }

        break;
      case "IXCP":
        {
          console.log("save project case IXCP");
        }
        break;
      case "EDI":
        {
          console.log("save project case EDI");
        }
        break;
      case "IXSD":
        {
          console.log("save project case IXSD");
        }
        break;
      case "Report":
        {
          console.log("save project case REPORT");
        }
        break;
      default: {
        console.log("save project default===========>");
      }
    }
  };

  const [getAllData] = useLazyGetAllDataQuery();
  const [getSystemData, responseSystemData] = useLazyGetAllDataQuery();
  const [recentProject, setRecentProject] = useState([]);
  const [exportProject, isExporting, exportError, setExportError] = useExportProject();
  const [showExportConfirmation, setShowExportConfirmation] = useState(false);

  const userID = userId;

  useEffect(() => {
    if (response.isSuccess) {
      const url =
        feedWaterData.caseID === 0
          ? `masterdata/api/v${1}/SystemDesign?userID=${userId}&projectID=${feedWaterData.projectID}`
          : `masterdata/api/v${1}/SystemDesign?userID=${userId}&projectID=${feedWaterData.projectID}&caseID=${feedWaterData.caseID}`;
      getSystemData(url);
    }
  }, [response]);
  useEffect(() => {
    if (responseSystemData.isSuccess) {
      dispatch(
        setNodeAndEdge({
          ...responseSystemData.data,
          ["flow"]: Number(
            GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseSystemData.data.flow,
              unit.selectedUnits[1],
              "m³/h",
            ).toFixed(2),
          ),
        }),
      );
    }
  }, [responseSystemData]);
  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setOpenNewProjectPopup(false);
    }
  };

  const handleNavigate = () => {
    dispatch(updateLeftpanel("masterdata/api/v1/ProjectRecent"));
    if (location.pathname === "/FeedWaterHome") {
      if (tab == "System Design" && isDataUpdated) {
        setSaveWarning(true);
      } else if (tab == "Feed Setup" && dataisChanged) {
        setSaveWarning(true);
      } else if (tab == "UF" && isUfDataUpdated) {
        setSaveWarning(true);
      } else if (isIXTechnology(tab) && ixStoreObj.isIXDDataUpdated) {
        setSaveWarning(true);
      } else {
        navigate("/home");
        dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
        dispatch(updateTabAvailableForUF({ FeedSetup: false, UF: false }));
      }
    }
  };

  /**
   * Handles the click event for a recent project.
   * Resets project data, updates project info, and navigates to the FeedWaterHome page.
   *
   * @param {Object} event - The click event object.
   */
  const handleRecentProjectClick = ({ target: { id } }) => {
    resetProjectData();

    const project = recentProject[id];
    const updatedProjectInfo = createUpdatedProjectInfo(project);

    dispatch(updateProjectInfo(updatedProjectInfo));
    navigateToFeedWaterHome(project);
  };

  /**
   * Creates an updated project information object.
   *
   * @param {Object} project - The project object.
   * @returns {Object} - The updated project information.
   */
  const createUpdatedProjectInfo = project => {
    const { projectID, projectName, technologyName, isImported } = project;
    return {
      ...ProjectInfoStore,
      projectID,
      caseId: 0,
      projectName,
      Tchnology: technologyName,
      case: technologyName,
      isImported: isImported,
    };
  };

  /**
   * Navigates to the FeedWaterHome page with the given project information.
   *
   * @param {Object} project - The project object.
   */
  const navigateToFeedWaterHome = project => {
    const { projectID, projectName, technologyName } = project;
    navigate("/FeedWaterHome", {
      state: {
        title: projectName,
        projectID,
        technologyName,
        caseID: 0,
      },
    });
  };

  const handleDropDownToggle = async isOpened => {
    try {
      if (isOpened && !recentProject.length) {
        const { data } = await getAllData(`masterdata/api/v1/ProjectRecent?userID=${userID}`);
        setRecentProject(data);
      }
    } catch (error) {
      console.error("Error while fetching recent projects", error);
    }
  };

  const handleExportProject = () => {
    if (isDataUpdated || dataisChanged || ixStoreObj.isIXDDataUpdated) {
      setShowExportConfirmation(true);
      setIsToExport(true);
    } else {
      exportProject(ProjectInfoStore.projectID, ProjectInfoStore.projectName);
    }
  };

  const onExportConfirmationClose = id => {
    setShowExportConfirmation(false);
    if (id === CustomModalButtons.CONFIRM) {
      saveData(currentPanel);
    }
  };

  const handleErrorClose = () => {
    setExportError(null);
  };
  return (
    <>
      {openNewProject && (
        <CreateNewProjectModal CPmodal={updateCreateprojc} show={openNewProject} close={setOpenNewProject} />
      )}
      <ProjectSaveWarning
        show={saveWarning}
        close={a => {
          setSaveWarning(false);
          navigate("/home");
        }}
        yes={() => saveData(currentPanel)}
      />

      <SecondaryMenuStyled scrollDirection={scrollDirection}>
        <Row className='feed-water-header-row g-0'>
          <Col lg={8} md={8} sm={8} xs={8} className='d-flex select-menu-column'>
            <Dropdown onToggle={handleDropDownToggle}>
              <Dropdown.Toggle>File Access</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleOpenNewProject}>New Project</Dropdown.Item>
                <Dropdown.Item onClick={handleShowOpenProject}>
                  Open Project
                  <OpenProject show={openProject} close={setOpenProject} />
                </Dropdown.Item>
                <Dropdown.Item className='recent-project'>
                  Recent Projects
                  <DropRightIcon />
                  {recentProject.length > 0 && (
                    <ul className='recent-project-submenu list-unstyled'>
                      {recentProject.map((project, index) => (
                        <li key={project.projectID} id={index} onClick={handleRecentProjectClick}>
                          {project.projectName}
                        </li>
                      ))}
                    </ul>
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleExportProject}
                  disabled={isIXTechnology(ProjectInfoStore?.Tchnology)}
                  className={isIXTechnology(ProjectInfoStore?.Tchnology) ? "disabled-dropdown-item" : ""}
                >
                  Save as .dwpx
                </Dropdown.Item>
                <Dropdown.Item onClick={handleNavigate}>Back to Home</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle>Project Settings</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleOpenProjectInformation}>Project Info & Details</Dropdown.Item>
                <ProjectInformationModal show={projectInfo} close={setProjectInfo} />
                <Dropdown.Item onClick={handleOpenManageCase}>Manage Cases</Dropdown.Item>
                <ManageCase show={manageCase} close={setManageCase} />
                <Dropdown.Item onClick={handleOpenCurrencyUnits}>Currency & Units</Dropdown.Item>
                <Dropdown.Item onClick={handleOpenChemicalLibrary}>
                  Chemical Library & Operating Costs
                  <ProjectCostAndChemicalLibrary
                    show={openChemicalLibrary}
                    close={setOpenChemicalLibrary}
                    forUser={false}
                  />
                </Dropdown.Item>
                <Dropdown.Item onClick={handleOpenPump}>
                  Pumps
                  <Pumps show={openPump} close={setOpenPump} forUser={false} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <CurrencyAndUnitPopUp show={currency} close={setCurrency} forUser={false} />

          {isSaveEligible && (
            <Col lg={4} md={4} sm={4} xs={4} className='d-flex icons-column'>
              <div className='save icon' onClick={() => saveData(currentPanel)}>
                <SaveIcon />
              </div>
            </Col>
          )}
        </Row>
      </SecondaryMenuStyled>
      {showAlert ? <AlertPopUp type={alertData?.type} message={alertData?.message} close={handleHideAlert} /> : null}
      {openNewProjectPopup && (
        <ProjectSavePopup
          show={openNewProjectPopup}
          close={() => {
            setOpenNewProjectPopup(false);
            if (isToExport) {
              setIsToExport(false);
              exportProject(ProjectInfoStore.projectID, ProjectInfoStore.projectName);
            }
          }}
          message='Project saved successfully!!!!'
        />
      )}
      {showExportConfirmation && (
        <ConfirmationPopup
          header='Please save your file before exporting'
          type={DIALOG_TYPES.WARNING}
          onClose={onExportConfirmationClose}
          confirmBtn='Save & Export'
          cancelBtn='Cancel'
        />
      )}
      {isExporting && <LoaderPopUp loadingText={ImportProjectStrings.exporingProject} />}
      {exportError && (
        <ConfirmationPopup
          show={exportError}
          header={ImportProjectStrings.errorExportingFile}
          description={exportError}
          type={DIALOG_TYPES.ERROR}
          onClose={handleErrorClose}
          confirmBtn='Okay'
        />
      )}
    </>
  );
};

export default FeedWaterHeader;
