import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { WPToastNotification, WPToastNotificationProvider } from "@components/WPToastNotification";

import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./styles/fonts.css";
import "./index.css";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <WPToastNotificationProvider>
        <WPToastNotification />
        <App />
      </WPToastNotificationProvider>
    </Provider>
  </BrowserRouter>,
  // </React.StrictMode>
);
reportWebVitals();
