import { useDispatch } from "react-redux";
import { getUfCalcReport } from "src/api/reportApi/reportApi";

import { setUfCalcReportApiResp } from "@common/ReportSlice";

import useProjectDetails from "./useProjectDetails";
import useUFConfig from "./useUFConfig";

export const useReport = () => {
  const { getConfigPayload } = useUFConfig();
  const { projectDetails } = useProjectDetails();

  const { activeCaseId: caseID, projectID } = projectDetails;
  const dispatch = useDispatch();

  const getUfReportData = async () => {
    try {
      dispatch(setUfCalcReportApiResp({ isLoading: true }));
      const { requestConfig: ufReport } = getConfigPayload();
      const response = await getUfCalcReport({ ufReport, projectID, caseID });
      dispatch(setUfCalcReportApiResp({ data: response.data, error: null }));
    } catch (err) {
      dispatch(setUfCalcReportApiResp({ data: null, error: err }));
    }
    dispatch(setUfCalcReportApiResp({ isLoading: false }));
  };

  return { getUfReportData };
};
