import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "@common/styles/Theme";

import { TypographyMapping, typographyVariants } from "./WPTypography.constants";

// The Typography is based on the typography guidelines
/**
 * Typography component for rendering text with various styles and alignments.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.variant - The variant of the typography element (e.g., 'body', 'cardHeader').
 * @param {string} [props.text] - The text content to be displayed. If not provided, children will be used.
 * @param {React.ReactNode} [props.children] - The children elements to be rendered if text is not provided.
 * @param {string} [props.className] - Additional class names to apply to the element.
 * @param {Object} [props.style] - Inline styles to apply to the element.
 * @param {string} [props.align] - Text alignment class (e.g., 'left', 'center', 'right').
 *
 * @returns {JSX.Element} The rendered typography element.
 */

// default varaint body
const Typography = ({ variant, text, children, className, style, align, asElement }) => {
  const { element, className: defaultClassName } = TypographyMapping[variant] || {};
  if (!defaultClassName) {
    return null;
  }
  const Element = asElement || element;
  const alignClassName = "align-" + align;
  return (
    <Element className={`${defaultClassName} ${className} ${alignClassName}`} style={style}>
      {text || children}
    </Element>
  );
};

Typography.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  align: PropTypes.oneOf(["left", "right", "center"]),
  color: PropTypes.string,
};

Typography.defaultProps = {
  text: "",
  className: "",
  style: {},
  align: "left",
  variant: typographyVariants.body,
  fontWeight: 400,
};

// default font family is notosans
const WPTypogrpahy = styled(Typography)`
  margin: 0px;
  color: ${props => props.color || colors.Black};
  font-weight: ${props => props.fontWeight};
  &.body {
    font-size: 14px;
  }

  &.cardHeader {
    font-family: Diodrum;
    font-size: 16px;
    fontweight: 600;
  }

  &.xs-Text {
    font-family: Diodrum;
    font-size: 10px;
  }

  &.reportSectionHeader {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }

  &.tableData {
    font-family: Diodrum;
    font-size: 14px;
  }

  &.tableColumnData {
    font-family: Diodrum;
    font-size: 14px;
    font-weight: 600;
  }

  &.align-left {
    text-align: left;
  }

  &.align-right {
    text-align: right;
  }

  &.align-center {
    text-align: center;
  }

  sup {
    font-size: 0.75em;
    vertical-align: middle;
  }

  sub {
    font-size: 0.75em;
    vertical-align: sub;
  }
`;

export default WPTypogrpahy;
