//Note: key is mapped with the API response data key & the order of the rows is as per the below labels order
export const WPUfConfiguringationOptionsTableLabels = {
  standby_opton: "Standby Option",
  standby_tank_option: "Standby Tank Option",
  fF_water_source: "Forward Flush Water Source",
  bW_water_source: "Backwash Water Source",
  ceB_water_source: "CEB Water Source",
  mini_CIP_water_source: "Mini CIP Water Source",
  ciP_water_source: "CIP Water Source",
};

export const WPUfConfiguringationOptionsTableColumns = [
  {
    field: "col1",
    rowHeader: true,
  },
  {
    field: "col2",
  },
];
