import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putRequest } from "src/api/baseApi";

import API_URLS from "@constants/api.urls";
import { TECHNOLOGIES } from "@constants/global.constants";

import DupontLogger from "@utils/DupontLogger";

import { updateGlobalData } from "@common/GlobalDataSlice";

import { useCreateDataMutation } from "@services/apiConfig";

import { IONS_SOLUTE_IDS } from "@features/feedwater/feedsetup/constants/FeedWaterConstants";
import {
  Feedsetupdetailsdata,
  UpdatedStream,
  updateFeedSetupStoreData,
} from "@features/feedwater/feedsetup/FeedsetupSlice";

import useUFDataHandler from "./useUFDataHandler";
import { useUFModuleFilters } from "./useUFModuleFilters";

const useCalculateFeedWaterData = () => {
  const Logger = DupontLogger("useCalculateFeedWaterData");
  const dispatch = useDispatch();
  const [isCalculating, setIsCalculating] = useState(false);
  const [getCalFeedwaterDetails] = useCreateDataMutation();
  const { projectid, userID, caseID } = useUFDataHandler()?.projectDetails || {};
  const { streamData, data } = useSelector(state => state.Feedsetupdetailsdatapanel);
  const FeedData = streamData?.lstrequestsavefeedwater?.[0].streams?.[0] || {};
  const { getWaterSubTypeId, getWaterSourceName } = useUFModuleFilters();
  const { fetchWaterSubTypes } = useUFDataHandler();
  const { companyName } = useSelector(state => state.UFStore.activeUFModule);
  const { waterLibraryList } = useSelector(state => state.globalAppData || {});
  const { userLibrary } = waterLibraryList;
  const { projectTechnology } = useUFDataHandler()?.projectDetails || {};
  const isUF = projectTechnology === TECHNOLOGIES.UF;

  const calcEngineApiCall = useCallback(
    async (payload, waterLibraryData) => {
      const responseCalFeedwaterDetails = await getCalFeedwaterDetails(payload);
      if (responseCalFeedwaterDetails.data != null) {
        const calculatedData = responseCalFeedwaterDetails.data;
        dispatch(Feedsetupdetailsdata([calculatedData]));
        const waterSubTypeList = await fetchWaterSubTypes(waterLibraryData.waterTypeId, 0);
        const filteredWaterSubTypes = waterSubTypeList[companyName] || [];
        const WaterSubTypeID = getWaterSubTypeId(filteredWaterSubTypes, {
          NTU: waterLibraryData.turbidity,
          TSS: waterLibraryData.tss,
          TOC: waterLibraryData.toc,
        });
        const newStreamData = [
          {
            ...FeedData,
            waterTypeID: waterLibraryData.waterTypeId,
            waterSubTypeID: WaterSubTypeID.waterSubTypeId,
            tss: waterLibraryData.tss,
            toc: waterLibraryData.toc,
            sdi: waterLibraryData.sdi,
            tempMax: waterLibraryData.tempMax,
            tempMin: waterLibraryData.tempMin,
            turbidity: waterLibraryData.turbidity,
            streamName: waterLibraryData.waterLibraryName,
            feedTitle: `Feed Setup - ${waterLibraryData.waterLibraryName}`,
            pH: calculatedData.ph,
            ph: calculatedData.ph,
            ph25: calculatedData.ph25,
            anions: calculatedData.anions,
            cations: calculatedData.cations,
            neutrals: calculatedData.neutrals,
            tempDesign: calculatedData.designTemp,
            chargeBalance: calculatedData.chargeBalance,
            equilibrate_with: calculatedData.equilibrate_with,
            totalDissolvedSolids: calculatedData.totalDissolvedSolids,
            totalDissolvedSolutes: calculatedData.totalDissolvedSolutes,
            estimatedConductivity: calculatedData.estimatedConductivity,
          },
        ];
        const updatedStreamData = {
          ...streamData,
          lstrequestsavefeedwater: streamData.lstrequestsavefeedwater.map(feedwater => ({
            ...feedwater,
            streams: newStreamData,
          })),
        };
        dispatch(UpdatedStream(updatedStreamData));
        dispatch(
          updateFeedSetupStoreData({ isCopyToFeedWaterSuccess: true, title: waterLibraryData.waterLibraryName }),
        );
        setIsCalculating(false);
      }
    },
    [dispatch, streamData],
  );

  const calculateFeedWaterData = useCallback(
    waterLibraryData => {
      setIsCalculating(true);
      const CalculateFeedWaterDataJson = {
        Method: "masterdata/api/v1/CalculateFeedWaterData",
        userID,
        projectID: projectid,
        caseID,
        typeFlag: 0,
        feedStream: {
          designTemp: waterLibraryData.tempDesign,
          methodname: "normal",
          ph: waterLibraryData.ph,
          ph25: 0,
          TotalDissolvedSolutes: 0.0,
          TotalDissolvedSolids: 0.0,
          ChargeBalance: 0.0,
          EstimatedConductivity: 0.0,
          Degas: 0.0,
          percentage_of_initial_total_CO2_remaining: 100.0,
          Equilibrate_with: 0.0,
          Adjustment_Type: 0.0,
          Add_Reagent: 0.0,
          Total_CO2: 0.0,
          cations: waterLibraryData.cations,
          anions: waterLibraryData.anions,
          neutrals: waterLibraryData.neutrals,
        },
      };
      calcEngineApiCall(CalculateFeedWaterDataJson, waterLibraryData);
    },
    [calcEngineApiCall],
  );

  const filterEssentialFields = list => list.map(({ name, mgL }) => ({ name, mgL, soluteID: IONS_SOLUTE_IDS[name] }));

  const createWaterLibraryDataJson = (libData, libName, entityData, waterSource) => ({
    additionalFeedWaterInfo: libData.additionalFeedWaterInfo,
    anions: filterEssentialFields(libData.anions),
    cations: filterEssentialFields(libData.cations),
    chargeBalance: Number(libData.chargeBalance),
    neutrals: filterEssentialFields(libData.neutrals),
    ph: Number(libData.pH),
    sdi: Number(libData.sdi),
    tempDesign: Number(libData.tempDesign),
    tempMax: Number(libData.tempMax),
    tempMin: Number(libData.tempMin),
    toc: Number(libData.toc),
    totalDissolvedSolids: Number(libData.totalDissolvedSolids),
    totalDissolvedSolutes: Number(libData.totalDissolvedSolutes),
    tss: Number(libData.tss),
    turbidity: Number(libData.turbidity),
    waterLibraryId: entityData.waterLibraryId || 0,
    waterLibraryName: libName,
    waterSource: isUF ? waterSource || data[0].waterSource : "N/A",
    waterTypeId: libData.waterTypeID,
  });

  const saveWaterLibraryData = useCallback(
    async (waterLibraryData, editedName, entityData = {}) => {
      const waterSource = getWaterSourceName(waterLibraryData.waterTypeID)?.waterSource || "";
      const reqPayload = createWaterLibraryDataJson(waterLibraryData, editedName, entityData, waterSource);
      try {
        const { data } = await putRequest(API_URLS.waterLibrary, reqPayload);
        const newLibraryItem = {
          waterLibraryId: data.waterLibraryId,
          waterLibraryName: data.waterLibraryName,
          isSystem: false,
        };

        const updatedUserLibrary = userLibrary.some(item => item.waterLibraryId === newLibraryItem.waterLibraryId)
          ? userLibrary
          : [...userLibrary, newLibraryItem];

        const updatedWaterLibraryList = {
          ...waterLibraryList,
          userLibrary: updatedUserLibrary,
        };

        dispatch(updateGlobalData({ type: "waterLibraryList", value: updatedWaterLibraryList }));
        return "SUCCESS";
      } catch (error) {
        Logger.error("Failed to save water library data:", error);
        return "FAILURE";
      }
    },
    [userID],
  );

  return { calculateFeedWaterData, isCalculating, saveWaterLibraryData };
};

export default useCalculateFeedWaterData;
