import { useSelector } from "react-redux";

import { WPTable } from "@components/WPTable";

import { WPUfConfiguringationOptionsTableColumns as columns } from "./WPUfConfiguringationOptionsTable.constants";

export const WPUfConfiguringationOptionsTable = () => {
  const { uf_configuration_options: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable sectionHeader='UF Configuration Options' showHeader={false} columns={columns} rows={rows} />;
};
