import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  systemDiagramBase64: null,
  ufCalcReportApiResp: {
    isLoading: false,
    data: null,
    error: null,
  },
  ufReportTableData: {},
};

export const ReportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setSystemDiagramBase64: (state, action) => {
      state.systemDiagramBase64 = action.payload;
    },
    setUfCalcReportApiResp: (state, action) => {
      state.ufCalcReportApiResp = { ...state.ufCalcReportApiResp, ...action.payload };
    },
    setUfReportTableData: (state, action) => {
      state.ufReportTableData = { ...state.ufReportTableData, ...action.payload };
    },
  },
});

export const { setSystemDiagramBase64, setUfCalcReportApiResp, setUfReportTableData } = ReportSlice.actions;

export default ReportSlice.reducer;
