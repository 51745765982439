import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES } from "@constants/units.constant";

export const WPUfFlowDetailsTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: reportStrings.ufFlowDetails,
  },
  {
    field: "col2",
    rowHeader: true,
    headerName: "",
  },
  {
    field: "col3",
    headerName: (
      <>
        Peak Flow <sup>a</sup>
      </>
    ),
  },
  {
    field: "col4",
    headerName: reportStrings.averageFlow,
  },
];

export const WPUfFlowDetailsTableRowsData = {
  feed_gross: {
    label: reportStrings.FeedGross,
    unit: UNIT_TYPES.FLOW,
  },
  // This is a custom row, not present in the API response
  feed_water_used_for: {
    label: reportStrings.FeedUserFor,
  },
  lossed_pretreatment: {
    label: reportStrings.pretreatment,
    unit: UNIT_TYPES.FLOW,
    indentLevel: 1,
  },
  ff_process_stream: {
    label: reportStrings.forwardFlushProcessStreams,
    unit: UNIT_TYPES.FLOW,
    indentLevel: 1,
  },
  feed_net: {
    label: reportStrings.feedNet,
    unit: UNIT_TYPES.FLOW,
  },
  flterate_gross: {
    label: reportStrings.filtrateGross,
    unit: UNIT_TYPES.FLOW,
  },
  losses: {
    label: reportStrings.filtrateUsedForCleaning,
    unit: UNIT_TYPES.FLOW,
  },
  filterate_net: {
    label: reportStrings.filtrateNet,
    unit: UNIT_TYPES.FLOW,
  },
  air: {
    label: reportStrings.air,
    unit: UNIT_TYPES.GAS_FLOW,
  },
  air2: {
    label: reportStrings.air2,
    unit: UNIT_TYPES.GAS_FLOW,
  },
  backwash_fd: {
    label: reportStrings.backwashBW,
    unit: UNIT_TYPES.FLOW,
  },
  forward_flush: {
    label: reportStrings.forwardFlush,
    unit: UNIT_TYPES.FLOW,
  },
  ceB1_acid: {
    label: reportStrings.ceb1Acid,
    unit: UNIT_TYPES.FLOW,
  },
  ceB2_alkali_oxidant: {
    label: reportStrings.ceb2AlkaliOxidant,
    unit: UNIT_TYPES.FLOW,
  },
  ceB3_disinf: {
    label: reportStrings.ceb3Disinfection,
    unit: UNIT_TYPES.FLOW,
  },
  mini_cip_recycle: {
    label: reportStrings.miniCipRecycle,
    unit: UNIT_TYPES.FLOW,
  },
  cip_recycle: {
    label: reportStrings.cipRecycle,
    unit: UNIT_TYPES.FLOW,
  },
};

export const WPUfFlowProcessStepLabels = {
  feed: reportStrings.feed,
  bw: reportStrings.bw,
  ceb: reportStrings.ceb,
  cebDis: reportStrings.cebDisinf,
  miniCip: reportStrings.miniCip,
  cip: reportStrings.cip,
  cipSls: reportStrings.cipSls,
  miniCipSls: reportStrings.miniCipSls,
};

export const WPUfFlowChemicalKeys = {
  feed: ["feed_acid", "feed_coag", "feed_ox"],
  bw: ["bw_ox"],
  ceb: ["ceb_acid", "ceb_org_acid", "ceb_base", "ceb_ox"],
  cebDis: ["ceb_disinf"],
  miniCip: ["mini_cip_acid", "mini_cip_org_acid", "mini_cip_base", "mini_cip_ox"],
  miniCipSls: ["mini_cip_sls"],
  cip: ["cip_acid", "cip_org_acid", "cip_base", "cip_ox"],
  cipSls: ["cip_sls"],
};

export const meteringPumpLabel = "Metering Pump";

export const chemicalFlowUnit = "L/h";
