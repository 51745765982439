import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TabList, TabPanel, Tabs } from "react-tabs";

import { UNIT_TYPES } from "@constants/units.constant";

import { useChemicalAdjustment } from "@hooks/useChemicalAdjustment";
import useFeedSetupDataHandler from "@hooks/useFeedSetupDataHandler";
import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUFConfig from "@hooks/useUFConfig";
import { useUFInputRanges } from "@hooks/useUFInputRanges";
import useUnitConversion from "@hooks/useUnitConversion";

import { DesignStrings } from "@utils/StringConstants";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import TabletCloseMenuIcon from "@common/icons/TabletCloseMenuIcon";
import { updateTabAvailable } from "@common/ReportIXDSlice";
import { updateTabAvailableForUF } from "@common/ReportUFSlice";
import DynamicLoadder from "@common/utils/DynamicLoadder";
import { MyError } from "@common/utils/ErrorCreator";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import { updateGlobalUnits, updateUnitFlag } from "@common/utils/GlobalUnitConversionSlice";

import { useCreateDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import {
  handleCalcEngineResponse,
  handleCipDropdownData,
  setActiveTab,
  setCustomAvail,
  setCustomOfflineTimePerUnit,
  setUfDataUpdate,
  setUFProductFilter,
  updateMenuIconHeader,
  updateTabletMenuIcon,
  updateUFDefaultInputRangeConfig,
  updateUFStore,
  updateUFStoreData,
  updateWaterSubtypeFlag,
} from "../uf/UFSlice";

import { UF_SPECIAL_FEATURE } from "./constants/UFConstants";
import ufDefaultData from "./constants/UFDefaultValues.json";
import { UF_FIELDS_MAPPING } from "./constants/UFFieldsMaping";
import { getModules, handleSortingOperations, searchFilterLogic } from "./UFConfiguration/UFHelper";
import AdditionalSetting from "./AdditionalSetting";
import Backwash from "./Backwash";
import CEB from "./CEB";
import CIP from "./CIP";
import Configuration from "./Configuration";
import Design from "./Design";
import UFMiniCip from "./UFMiniCip";
import UFStyled from "./UFStyled";
import { isIngeSelected } from "./ufUtils";

const UF = () => {
  const dispatch = useDispatch();
  const { fetchUFConfig, updateActiveModule } = useUFConfig();
  const { convertUnit, convertFromMetric } = useUnitConversion();
  const { isValueInPh, setUFChemicalsDefaultValues } = useUFChemicalsHandler();
  const { fetchWaterTypes } = useFeedSetupDataHandler();
  const { getConfigForForwardFlushFlow, getConfigForFiltrateFlux } = useUFInputRanges();
  const { fetchUFChemicalAdjustmentFieldsData } = useChemicalAdjustment();

  const [getCebLSIValue, responseCebLSIValue] = useCreateDataMutation();
  const [getCipLSIValue, responseCipLSIValue] = useCreateDataMutation();
  const [getMiniCipLSIValue, responseMiniCipLSIValue] = useCreateDataMutation();
  const [getUFDetails, responseUFDetails] = useLazyGetAllDataQuery();
  const [POSTUFAutoSaveData] = useCreateDataMutation();
  const [getCEBData, responseCeb] = useLazyGetAllDataQuery();
  const [getCIPData, responseCip] = useLazyGetAllDataQuery();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const showInDropDown = useSelector(state => state.projectInfo.projectConfig.chemicalConfig.showInDropDownChem);
  const { isChemicalsListUpdated } = useSelector(state => state.projectInfo.projectConfig.chemicalConfig);

  const {
    activeTab,
    ufModules,
    data: UFData,
    calcEngineData,
    ufDoseGuidline,
    cebDropdownData,
    ufProductFilter,
    waterSubTypeFlag,
    isForDrinkingWater,
    isUFDetailsFetched,
    ufInputRangeConfig,
    calcEngineDataRefreshCount,
    ufInputRangeConfigByWaterType,
    activeUFModule: selectedUFModule,
  } = useSelector(state => state.UFStore);

  const { activeSortOrder, activeSearch, selectedModuleCategory } = ufProductFilter;
  const StreamStoreData = useSelector(
    state => state.Feedsetupdetailsdatapanel.streamData.lstrequestsavefeedwater[0]?.streams[0],
  );
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { unitTypeFlux } = useSelector(state => state.GUnitConversion);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);

  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const caseTreatmentID = useSelector(
    state =>
      state.processDiagramSlice.lstTechnologyLists.find(item => item.technologyID == 1 && !item.isDeleted)
        ?.caseTreatmentID,
  );

  const tempDesign = useSelector(
    state => state.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]?.tempDesign,
  );

  const { waterSubTypeID = "1", waterTypeID } = StreamStoreData || {};
  const waterSubTypeBasedInputConfigs = ufInputRangeConfigByWaterType.filter(w => w.waterSubType == waterSubTypeID);
  const projectID = ProjectInfoStore.projectID;
  const caseID = ProjectInfoStore.caseId;
  const loggedInUserID = UserInfoStore.UserId;
  const updateStoreData = data => dispatch(updateUFStoreData(data));

  useEffect(() => {
    const filteredModules = getModules(selectedModuleCategory, ufModules);
    const filterdSearchedData = searchFilterLogic(activeSearch, filteredModules);
    let sortedModules = handleSortingOperations(activeSortOrder, filterdSearchedData);
    const isModulePresent = sortedModules.some(module => module.ufmoduleId == UFData.uFModuleID);

    if (!isModulePresent) {
      const moduleToAdd = ufModules.find(module => module.ufmoduleId == UFData.uFModuleID);
      if (moduleToAdd) {
        sortedModules = [...sortedModules, moduleToAdd];
      }
    }
    dispatch(setUFProductFilter({ filteredModules: sortedModules, localFilterdModules: sortedModules }));
  }, [UFData.uFModuleID]);

  useEffect(() => {
    const flux_Filter_actual = convertUnit(calcEngineData.flux_Filter_actual, 4, unitTypeFlux);

    const newUnits = {
      unitTypeFlux: unit.selectedUnits[4],
      unitTypeFlow: unit.selectedUnits[1],
      unitTypeGasFlow: unit.selectedUnits[18],
      unitTypePressure: unit.selectedUnits[3],
      unitTypePower: unit.selectedUnits[9],
      unitTypeTemp: unit.selectedUnits[2],
    };
    dispatch(handleCalcEngineResponse({ ...calcEngineData, ["flux_Filter_actual"]: flux_Filter_actual }));
    dispatch(updateGlobalUnits(newUnits));
    dispatch(updateUnitFlag(false));
  }, [unit.selectedUnits]);

  useEffect(() => {
    if (calcEngineDataRefreshCount > -1 && isUFDetailsFetched) {
      fetchUFConfig();
    }
  }, [calcEngineDataRefreshCount, isUFDetailsFetched]);

  useEffect(() => {
    fetchWaterTypes(waterTypeID, waterSubTypeID);
    fetchUFChemicalAdjustmentFieldsData();
    callUFDetailsAPI();
  }, []);

  useEffect(() => {
    callCEBAPI();
    callCIPAPI();
  }, [isChemicalsListUpdated]);

  const getConfigForAirFlow = () => {
    const genericRange = ufInputRangeConfig.filter(config => config.label == "Air Flow");
    const airFlowRange = [
      {
        ...genericRange[0],
        minValue: selectedUFModule.aS_Flow_min,
        maxValue: selectedUFModule.aS_Flow_max,
        defaultValue: selectedUFModule.aS_Flow_std,
      },
    ];
    return airFlowRange;
  };

  const getConfigForUFFields = label => {
    let normalRange = [];
    let rangeOnWaterSubType = [];
    if (label == "recycleFlowRate") {
      normalRange = ufInputRangeConfig.filter(config => config.label === DesignStrings.cIPRecycleFlowRate);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(
        config => config.label === DesignStrings.cIPRecycleFlowRate,
      );
      normalRange = [
        {
          ...normalRange[0],
          minValue: selectedUFModule.cIP_Flow_min,
          maxValue: selectedUFModule.cIP_Flow_max,
          defaultValue: convertFromMetric(selectedUFModule.cIP_Flow_std, UNIT_TYPES.FLOW),
        },
      ];
    } else if (label == "recycleFlowRate_MiniCIP") {
      // ranges should be same as recycleFlowRate , value should be 0 for inge or isCebOnly
      normalRange = ufInputRangeConfig.filter(config => config.label === DesignStrings.cIPRecycleFlowRate);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(
        config => config.label === DesignStrings.cIPRecycleFlowRate,
      );
      let defaultValue = convertFromMetric(selectedUFModule.cIP_Flow_std, UNIT_TYPES.FLOW);
      const { isCebOnly } = UF_SPECIAL_FEATURE;
      if (isIngeSelected(UFData.pUFTechnologyID) || +UFData.ufSpecialFeatureID === +isCebOnly) {
        defaultValue = 0;
      }
      normalRange = [
        {
          ...normalRange[0],
          label: DesignStrings.miniCIPRecycleFlowRate,
          minValue: selectedUFModule.cIP_Flow_min,
          maxValue: selectedUFModule.cIP_Flow_max,
          defaultValue,
        },
      ];
    } else if (label == "miniCIP") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Mini-CIP");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "mCIP Interval");
    } else if (label == "valveOpenCloseDuration") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Valve Open/Close Action Duration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "t_valves");
    } else if (label == "oxidantValue_BW") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Backwash Oxidant Concentration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Backwash NaOCl Dose");
    } else if (label == "backwash_design") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Filtration Duration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Backwash Interval");
    } else if (label == "alkaliOxidantCEB") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Alkaline CEB");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Alkali CEB Interval");
    } else if (label == "acidCEB") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Acid CEB");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Acid CEB Interval");
    } else if (label == "cIP") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "CIP");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "CIP Interval");
    } else {
      normalRange = ufInputRangeConfig.filter(config => config.label == UF_FIELDS_MAPPING[label]);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == UF_FIELDS_MAPPING[label]);
    }
    const rangeConfig = rangeOnWaterSubType?.length > 0 ? rangeOnWaterSubType : normalRange;
    return rangeConfig;
  };

  const getFieldRangeConfig = (label, moduleID) => {
    if (UF_FIELDS_MAPPING[label] == "Filtrate Flux") {
      return getConfigForFiltrateFlux(selectedUFModule);
    } else if (UF_FIELDS_MAPPING[label] == "Forward Flush Flow") {
      return getConfigForForwardFlushFlow(selectedUFModule);
    } else if (UF_FIELDS_MAPPING[label] == "Air Flow") {
      return getConfigForAirFlow(moduleID);
    } else {
      return getConfigForUFFields(label);
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsError(false);
    }
  };

  useEffect(() => {
    if (responseUFDetails.isLoading) {
      console.log("Save Data - Loading UF Data..");
    } else {
      if (responseUFDetails.status === "fulfilled") {
        const obj = { ...responseUFDetails.data };
        const UFDefaultConfigs = { ...UFData };

        const UFInputKeys = Object.keys(obj);
        const moduleID = (selectedUFModule && selectedUFModule.ufmoduleId) || responseUFDetails.data.uFModuleID;
        if (obj["uFModuleID"] == 0 || caseTreatmentID == 0) {
          const obj = { ...ufDefaultData };
          const UFDefaultConfigs = { ...UFData };
          let storeObj = { ...UFData };
          dispatch(setCustomAvail(true));

          UFInputKeys?.map(x => {
            if (x == "uFModuleID") {
              storeObj["uFModuleID"] = moduleID;
              storeObj["uFBWProtocolID"] = UFData.uFBWProtocolID;
            }
            storeObj["disOxidantEnabled_Ind_CEB"] = false;
            const config = getFieldRangeConfig(x, moduleID);
            if (config?.length > 0) {
              UFDefaultConfigs[x] = config[0];
            } else {
              UFDefaultConfigs[x] = {};
            }

            if (obj[x] === null) {
              storeObj[x] = "0";
            }
            if (obj[x] == 0) {
              const fieldConfiguration = getFieldRangeConfig(x, moduleID);
              if (fieldConfiguration?.length > 0) {
                storeObj[x] =
                  fieldConfiguration[0]?.defaultValue == 0
                    ? fieldConfiguration[0]?.minValue
                    : fieldConfiguration[0]?.defaultValue;
                if (x === "filtrateFlux" || x === "backwashFlux" || x === "cEBFlux") {
                  if (unit.selectedUnits[4] !== "LMH") {
                    const defaultValue =
                      fieldConfiguration[0]?.defaultValue == 0
                        ? GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.minValue,
                            unit.selectedUnits[4],
                            "LMH",
                          ).toFixed(2)
                        : GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.defaultValue,
                            unit.selectedUnits[4],
                            "LMH",
                          ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (
                  x === "maxAirScourPressure" ||
                  x === "maxAirProcPressure" ||
                  x === "filteratePressure" ||
                  x === "nonIntegraPacTrainPresDrop" ||
                  x === "integraPacFiltrationPreDrop" ||
                  x === "backwashPipingPreDrop" ||
                  x === "cIPPipingPreDrop" ||
                  x === "backwash_Filtration" ||
                  x === "acidCEB_Filtration" ||
                  x === "alkaliCEB_Filtration" ||
                  x === "cIP_Filtration"
                ) {
                  if (unit.selectedUnits[3] !== "bar") {
                    const defaultValue =
                      fieldConfiguration[0]?.defaultValue == 0
                        ? GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.minValue,
                            unit.selectedUnits[3],
                            "bar",
                          ).toFixed(2)
                        : GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.defaultValue,
                            unit.selectedUnits[3],
                            "bar",
                          ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (x === "forwardFlushFlow" || x === "recycleFlowRate" || x === "recycleFlowRate_MiniCIP") {
                  if (unit.selectedUnits[1] !== "m³/h") {
                    const defaultValue =
                      fieldConfiguration[0]?.defaultValue == 0
                        ? GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.minValue,
                            unit.selectedUnits[1],
                            "m³/h",
                          ).toFixed(2)
                        : GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.defaultValue,
                            unit.selectedUnits[1],
                            "m³/h",
                          ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (x === "airFlow" || x === "aerationAirFlow") {
                  if (unit.selectedUnits[18] !== "Nm³/h") {
                    const defaultValue =
                      fieldConfiguration[0]?.defaultValue == 0
                        ? GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.minValue,
                            unit.selectedUnits[18],
                            "Nm³/h",
                          ).toFixed(2)
                        : GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.defaultValue,
                            unit.selectedUnits[18],
                            "Nm³/h",
                          ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (x === "pLCPowerReqPertrain" || x === "volvePowerReqPerTrain") {
                  if (unit.selectedUnits[9] !== "kW") {
                    const defaultValue =
                      fieldConfiguration[0]?.defaultValue == 0
                        ? GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.minValue,
                            unit.selectedUnits[9],
                            "kW",
                          ).toFixed(2)
                        : GlobalUnitConversion(
                            GlobalUnitConversionStore,
                            fieldConfiguration[0]?.defaultValue,
                            unit.selectedUnits[9],
                            "kW",
                          ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                }
              }
              //non dupont fields are set as zero
              if (x == "backwash_Filtration" || x == "lF" || x == "disinfectionCEB" || x == "aerationAirFlow") {
                storeObj[x] = 0;
              }

              if (x == "t_BWBtnAirScour") {
                storeObj[x] = 1;
              }
            }

            if (UFData.isWaterSubTypeChanged == true || UFData.isDesignTempChanged == true) {
              const fieldConfig = getFieldRangeConfig(x, moduleID);
              if (fieldConfig?.length > 0) {
                storeObj[x] = fieldConfig[0]?.defaultValue;
                if (x === "filtrateFlux" || x === "backwashFlux" || x === "cEBFlux") {
                  if (unit.selectedUnits[4] !== "LMH") {
                    const defaultValue = GlobalUnitConversion(
                      GlobalUnitConversionStore,
                      fieldConfig[0]?.defaultValue,
                      unit.selectedUnits[4],
                      "LMH",
                    ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (
                  x === "maxAirScourPressure" ||
                  x === "maxAirProcPressure" ||
                  x === "filteratePressure" ||
                  x === "nonIntegraPacTrainPresDrop" ||
                  x === "integraPacFiltrationPreDrop" ||
                  x === "backwashPipingPreDrop" ||
                  x === "cIPPipingPreDrop" ||
                  x === "backwash_Filtration" ||
                  x === "acidCEB_Filtration" ||
                  x === "alkaliCEB_Filtration" ||
                  x === "cIP_Filtration"
                ) {
                  if (unit.selectedUnits[3] !== "bar") {
                    const defaultValue = GlobalUnitConversion(
                      GlobalUnitConversionStore,
                      fieldConfig[0]?.defaultValue,
                      unit.selectedUnits[3],
                      "bar",
                    ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (x === "forwardFlushFlow" || x === "recycleFlowRate" || x === "recycleFlowRate_MiniCIP") {
                  if (unit.selectedUnits[1] !== "m³/h") {
                    const defaultValue = GlobalUnitConversion(
                      GlobalUnitConversionStore,
                      fieldConfig[0]?.defaultValue,
                      unit.selectedUnits[1],
                      "m³/h",
                    ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (x === "airFlow" || x === "aerationAirFlow") {
                  if (unit.selectedUnits[18] !== "Nm³/h") {
                    const defaultValue = GlobalUnitConversion(
                      GlobalUnitConversionStore,
                      fieldConfig[0]?.defaultValue,
                      unit.selectedUnits[18],
                      "Nm³/h",
                    ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                } else if (x === "pLCPowerReqPertrain" || x === "volvePowerReqPerTrain") {
                  if (unit.selectedUnits[9] !== "kW") {
                    const defaultValue = GlobalUnitConversion(
                      GlobalUnitConversionStore,
                      fieldConfig[0]?.defaultValue,
                      unit.selectedUnits[9],
                      "kW",
                    ).toFixed(2);
                    storeObj[x] = defaultValue;
                  }
                }
              }
            }
          });
          storeObj.userID = loggedInUserID;
          storeObj.projectID = projectID;
          storeObj.fromTreatmentObjID = obj.fromTreatmentObjID;
          storeObj.caseTreatmentID = responseUFDetails.data.caseTreatmentID;
          storeObj.treatmentObjID = responseUFDetails.data.treatmentObjID;
          storeObj.caseID = caseID;
          storeObj.bWStepInCIP = obj.bWStepInCIP || 2;
          storeObj.rinseBWCycle = obj.rinseBWCycle || 1;
          storeObj.bWStepInMiniCIP = obj.bWStepInMiniCIP || 1;
          storeObj.rinseBWCycle_MiniCIP = obj.rinseBWCycle_MiniCIP || 1;
          storeObj.cIPRinseSoakCycle_MiniCIP = obj.cIPRinseSoakCycle_MiniCIP || 1;

          if (unit.selectedUnits[2] !== "°C") {
            storeObj.recycleTemperature =
              obj.recycleTemperature == 0 ? (tempDesign > 95 ? tempDesign : 95) : obj.recycleTemperature;
          } else {
            storeObj.recycleTemperature =
              obj.recycleTemperature == 0 ? (tempDesign > 35 ? tempDesign : 35) : obj.recycleTemperature;
          }

          if (obj["uFBWCEBStandbyOptionID"] == 0) {
            storeObj.uFBWCEBStandbyOptionID = 1;
          }
          if (obj["uFBWFlushWaterTypeID"] == 0) {
            storeObj.uFBWFlushWaterTypeID = 1;
          }
          if (obj["uFBWWaterTypeID"] == 0) {
            storeObj.uFBWWaterTypeID = 2;
          }
          if (obj["uFBWProtocolID"] == 0) {
            storeObj.uFBWProtocolID = 2;
          }
          if (obj["uFCIPWaterTypeID"] == 0) {
            storeObj.uFCIPWaterTypeID = 1;
          }
          if (obj["uFCEBWaterTypeID"] == 0) {
            storeObj.uFCEBWaterTypeID = 1;
          }
          if (obj["uFMiniCIPWaterTypeID"] == 0) {
            storeObj.uFMiniCIPWaterTypeID = 1;
          }

          storeObj.offlinetimepertrain = 0;
          // Chemicals default data
          const chemicalsDefaultData = setUFChemicalsDefaultValues();
          storeObj = { ...storeObj, ...chemicalsDefaultData };

          dispatch(updateWaterSubtypeFlag(false));
          dispatch(updateUFStore(storeObj));
          dispatch(updateUFDefaultInputRangeConfig(UFDefaultConfigs));
        } else {
          dispatch(setCustomAvail(false));
          updateActiveModule(moduleID);
          const storeObj = JSON.parse(JSON.stringify(responseUFDetails.data));
          obj["isWaterSubTypeChanged"] = false;
          obj["isDesignTempChanged"] = false;
          if (obj["alkaliEnabled_Ind_CEB"]) {
            const chem = showInDropDown.find(item => item.chemicalCat == "Base");
            if (obj["alkaliValueInPh_Ind_CEB"] != isValueInPh) {
              storeObj.alkaliValueInPh_Ind_CEB = isValueInPh;
              if (isValueInPh) {
                storeObj.alkaliValue_CEB = 12;
              } else {
                const alkaliTemp = ufDoseGuidline.find(
                  item =>
                    item.waterSubTypeId == waterSubTypeID &&
                    item.symbol == chem.symbol &&
                    item.guidelineName.includes("CEB"),
                );
                storeObj.alkaliValue_CEB = alkaliTemp ? alkaliTemp.targetDose : 650;
              }
            }
          } else {
            storeObj.alkaliChemId_CEB = 0;
            storeObj.alkaliValue_CEB = 0;
          }
          if (obj["mineralEnabled_Ind_CEB"]) {
            if (obj["mineralChemId_CEB"] == 0) {
              const chem = showInDropDown.find(item => item.chemicalCat == "Acid");
              storeObj.mineralChemId_CEB = chem.iD.toString();
              storeObj.mineralValueInPh_Ind_CEB = isValueInPh;
              storeObj.mineralEnabled_Ind_CEB = obj["mineralEnabled_Ind_CEB"];
              if (isValueInPh) {
                storeObj.mineralValue_CEB = 2;
              } else {
                const mineralTemp = ufDoseGuidline.find(
                  item =>
                    item.waterSubTypeId == waterSubTypeID &&
                    item.symbol == chem.symbol &&
                    item.guidelineName.includes("CEB"),
                );
                storeObj.mineralValue_CEB = mineralTemp ? mineralTemp.targetDose : 650;
              }
            } else {
              const chem = showInDropDown.find(item => item.chemicalCat == "Acid");
              if (obj["mineralValueInPh_Ind_CEB"] != isValueInPh) {
                storeObj.mineralValueInPh_Ind_CEB = isValueInPh;
                if (isValueInPh) {
                  storeObj.mineralValue_CEB = 2;
                } else {
                  const mineralTemp = ufDoseGuidline.find(
                    item =>
                      item.waterSubTypeId == waterSubTypeID &&
                      item.symbol == chem.symbol &&
                      item.guidelineName.includes("CEB"),
                  );
                  storeObj.mineralValue_CEB = mineralTemp ? mineralTemp.targetDose : 650;
                }
              }
            }
          } else {
            storeObj.mineralChemId_CEB = 0;
            storeObj.mineralValue_CEB = 0;
          }
          if (obj["organicEnabled_Ind_CEB"]) {
            if (obj["organicChemId_CEB"] == 0) {
              storeObj.organicEnabled_Ind_CEB = false;
              storeObj.organicChemId_CEB = 0;
              storeObj.organicValue_CEB = 0;
            }
          } else {
            storeObj.organicChemId_CEB = 0;
            storeObj.organicValue_CEB = 0;
          }
          if (obj["oxidantEnabled_Ind_CEB"]) {
            if (obj["oxidantChemId_CEB"] == 0) {
              storeObj.oxidantEnabled_Ind_CEB = obj["oxidantEnabled_Ind_CEB"];
              const chem = showInDropDown.find(item => item.chemicalCat == "Oxidant");
              storeObj.oxidantChemId_CEB = chem.iD.toString();
              const oxidantChemId_Temp = ufDoseGuidline.find(
                item =>
                  item.waterSubTypeId == waterSubTypeID &&
                  item.symbol == chem.symbol &&
                  item.guidelineName.includes("CEB"),
              );
              storeObj.oxidantValue_CEB = oxidantChemId_Temp ? oxidantChemId_Temp.targetDose : 1000;
            }
          } else {
            storeObj.oxidantChemId_CEB = 0;
            storeObj.oxidantValue_CEB = 0;
          }

          if (obj["alkaliEnabled_Ind_CIP"]) {
            if (obj["alkaliChemId_CIP"] == 0 || obj["alkaliChemId_CIP"] == null) {
              const chem = showInDropDown.find(item => item.chemicalCat == "Base");
              storeObj.alkaliChemId_CIP = chem.iD.toString();
              storeObj.alkaliValueInPh_Ind_CIP = isValueInPh;
              storeObj.alkaliEnabled_Ind_CIP = obj["alkaliEnabled_Ind_CIP"];
              if (isValueInPh) {
                storeObj.alkaliValue_CIP = 12;
              } else {
                const alkaliValue_CIP_Temp = ufDoseGuidline.find(
                  item =>
                    item.waterSubTypeId == waterSubTypeID &&
                    item.symbol == chem.symbol &&
                    item.guidelineName.includes("CIP"),
                );
                storeObj.alkaliValue_CIP = alkaliValue_CIP_Temp ? alkaliValue_CIP_Temp.targetDose : 1000;
              }
            } else {
              const chem = showInDropDown.find(item => item.chemicalCat == "Base");
              if (obj["alkaliValueInPh_Ind_CIP"] != isValueInPh) {
                storeObj.alkaliValueInPh_Ind_CIP = isValueInPh;
                if (isValueInPh) {
                  storeObj.alkaliValue_CIP = 12;
                } else {
                  const alkaliValue_CIP_Temp = ufDoseGuidline.find(
                    item =>
                      item.waterSubTypeId == waterSubTypeID &&
                      item.symbol == chem.symbol &&
                      item.guidelineName.includes("CIP"),
                  );
                  storeObj.alkaliValue_CIP = alkaliValue_CIP_Temp ? alkaliValue_CIP_Temp.targetDose : 1000;
                }
              }
            }
          } else {
            storeObj.alkaliChemId_CIP = 0;
            storeObj.alkaliValue_CIP = 0;
          }
          if (obj["mineralEnabled_Ind_CIP"]) {
            if (obj["mineralChemId_CIP"] == 0 || obj["mineralChemId_CIP"] == null) {
              const chem = showInDropDown.find(item => item.chemicalCat == "Acid");
              storeObj.mineralChemId_CIP = chem.iD.toString();
              storeObj.mineralValueInPh_Ind_CIP = isValueInPh;
              storeObj.mineralEnabled_Ind_CIP = obj["mineralEnabled_Ind_CIP"];
              if (isValueInPh) {
                storeObj.mineralValue_CIP = 2;
              } else {
                const mineralValue_CIP_Temp = ufDoseGuidline.find(
                  item =>
                    item.waterSubTypeId == waterSubTypeID &&
                    item.symbol == chem.symbol &&
                    item.guidelineName.includes("CIP"),
                );
                storeObj.mineralValue_CIP = mineralValue_CIP_Temp ? mineralValue_CIP_Temp.targetDose : 2000;
              }
            } else {
              const chem = showInDropDown.find(item => item.chemicalCat == "Acid");
              if (obj["mineralValueInPh_Ind_CIP"] != isValueInPh) {
                storeObj.mineralValueInPh_Ind_CIP = isValueInPh;
                if (isValueInPh) {
                  storeObj.mineralValue_CIP = 2;
                } else {
                  const mineralValue_CIP_Temp = ufDoseGuidline.find(
                    item =>
                      item.waterSubTypeId == waterSubTypeID &&
                      item.symbol == chem.symbol &&
                      item.guidelineName.includes("CIP"),
                  );
                  storeObj.mineralValue_CIP = mineralValue_CIP_Temp ? mineralValue_CIP_Temp.targetDose : 2000;
                }
              }
            }
          } else {
            storeObj.mineralChemId_CIP = 0;
            storeObj.mineralValue_CIP = 0;
          }
          if (obj["organicEnabled_Ind_CIP"]) {
            if (obj["organicChemId_CIP"] == 0 || obj["organicChemId_CIP"] == null) {
              storeObj.organicEnabled_Ind_CIP = false;
              storeObj.organicChemId_CIP = 0;
              storeObj.organicValue_CIP = 0;
            }
          } else {
            storeObj.organicChemId_CIP = 0;
            storeObj.organicValue_CIP = 0;
          }
          if (obj["oxidantEnabled_Ind_CIP"]) {
            if (obj["oxidantChemId_CIP"] == 0 || obj["oxidantChemId_CIP"] == null) {
              storeObj.oxidantEnabled_Ind_CIP = obj["oxidantEnabled_Ind_CIP"];
              const chem = showInDropDown.find(item => item.chemicalCat == "Oxidant");
              storeObj.oxidantChemId_CIP = chem.iD.toString();
              const oxidantValue_CIP_Temp = ufDoseGuidline.find(
                item =>
                  item.waterSubTypeId == waterSubTypeID &&
                  item.symbol == chem.symbol &&
                  item.guidelineName.includes("CIP"),
              );
              storeObj.oxidantValue_CIP = oxidantValue_CIP_Temp ? oxidantValue_CIP_Temp.targetDose : 1000;
            }
          } else {
            storeObj.oxidantChemId_CIP = 0;
            storeObj.oxidantValue_CIP = 0;
          }
          if (obj["alkaliEnabled_Ind_MiniCIP"]) {
            if (obj["alkaliEnabled_Ind_MiniCIP"] == 0 || obj["alkaliEnabled_Ind_MiniCIP"] == null) {
              const chem = showInDropDown.find(item => item.chemicalCat == "Base");
              storeObj.alkaliChemId_MiniCIP = chem.iD.toString();
              storeObj.alkaliValueInPh_Ind_MiniCIP = isValueInPh;
              storeObj.alkaliEnabled_Ind_MiniCIP = obj["alkaliEnabled_Ind_MiniCIP"];
              if (isValueInPh) {
                storeObj.alkaliValue_MiniCIP = 12;
              } else {
                const alkaliValue_CIP_Temp = ufDoseGuidline.find(
                  item =>
                    item.waterSubTypeId == waterSubTypeID &&
                    item.symbol == chem.symbol &&
                    item.guidelineName.includes("CIP"),
                );
                storeObj.alkaliValue_MiniCIP = alkaliValue_CIP_Temp ? alkaliValue_CIP_Temp.targetDose : 1000;
              }
            } else {
              const chem = showInDropDown.find(item => item.chemicalCat == "Base");
              if (obj["alkaliValueInPh_Ind_MiniCIP"] != isValueInPh) {
                storeObj.alkaliValueInPh_Ind_MiniCIP = isValueInPh;
                if (isValueInPh) {
                  storeObj.alkaliValue_MiniCIP = 12;
                } else {
                  const alkaliValue_CIP_Temp = ufDoseGuidline.find(
                    item =>
                      item.waterSubTypeId == waterSubTypeID &&
                      item.symbol == chem.symbol &&
                      item.guidelineName.includes("CIP"),
                  );
                  storeObj.alkaliValue_MiniCIP = alkaliValue_CIP_Temp ? alkaliValue_CIP_Temp.targetDose : 1000;
                }
              }
            }
          } else {
            storeObj.alkaliChemId_MiniCIP = 0;
            storeObj.alkaliValue_MiniCIP = 0;
          }
          if (obj["mineralEnabled_Ind_MiniCIP"]) {
            if (obj["mineralChemId_CIP"] == 0 || obj["mineralChemId_CIP"] == null) {
              const chem = showInDropDown.find(item => item.chemicalCat == "Acid");
              storeObj.mineralChemId_MiniCIP = chem.iD.toString();
              storeObj.mineralValueInPh_Ind_MiniCIP = isValueInPh;
              storeObj.mineralEnabled_Ind_CIP = obj["mineralEnabled_Ind_CIP"];
              if (isValueInPh) {
                storeObj.mineralValue_MiniCIP = 2;
              } else {
                const mineralValue_CIP_Temp = ufDoseGuidline.find(
                  item =>
                    item.waterSubTypeId == waterSubTypeID &&
                    item.symbol == chem.symbol &&
                    item.guidelineName.includes("CIP"),
                );
                storeObj.mineralValue_MiniCIP = mineralValue_CIP_Temp ? mineralValue_CIP_Temp.targetDose : 2000;
              }
            } else {
              const chem = showInDropDown.find(item => item.chemicalCat == "Acid");
              if (obj["mineralValueInPh_Ind_MiniCIP"] != isValueInPh) {
                storeObj.mineralValueInPh_Ind_MiniCIP = isValueInPh;
                if (isValueInPh) {
                  storeObj.mineralValue_MiniCIP = 2;
                } else {
                  const mineralValue_CIP_Temp = ufDoseGuidline.find(
                    item =>
                      item.waterSubTypeId == waterSubTypeID &&
                      item.symbol == chem.symbol &&
                      item.guidelineName.includes("CIP"),
                  );
                  storeObj.mineralValue_MiniCIP = mineralValue_CIP_Temp ? mineralValue_CIP_Temp.targetDose : 2000;
                }
              }
            }
          } else {
            storeObj.mineralChemId_MiniCIP = 0;
            storeObj.mineralValue_MiniCIP = 0;
          }
          if (obj["organicEnabled_Ind_MiniCIP"]) {
            if (obj["organicChemId_MiniCIP"] == 0 || obj["organicChemId_MiniCIP"] == null) {
              storeObj.organicEnabled_Ind_MiniCIP = false;
              storeObj.organicChemId_MiniCIP = 0;
              storeObj.organicValue_MiniCIP = 0;
            }
          } else {
            storeObj.organicChemId_MiniCIP = 0;
            storeObj.organicValue_MiniCIP = 0;
          }
          if (obj["oxidantEnabled_Ind_MiniCIP"]) {
            if (obj["oxidantChemId_MiniCIP"] == 0 || obj["oxidantChemId_MiniCIP"] == null) {
              storeObj.oxidantEnabled_Ind_MiniCIP = obj["oxidantEnabled_Ind_MiniCIP"];
              const chem = showInDropDown.find(item => item.chemicalCat == "Oxidant");
              storeObj.oxidantChemId_MiniCIP = chem.iD.toString();
              const oxidantValue_CIP_Temp = ufDoseGuidline.find(
                item =>
                  item.waterSubTypeId == waterSubTypeID &&
                  item.symbol == chem.symbol &&
                  item.guidelineName.includes("CIP"),
              );
              storeObj.oxidantValue_MiniCIP = oxidantValue_CIP_Temp ? oxidantValue_CIP_Temp.targetDose : 1000;
            }
          } else {
            storeObj.oxidantChemId_MiniCIP = 0;
            storeObj.oxidantValue_MiniCIP = 0;
          }
          storeObj["uFModuleID"] = moduleID;
          storeObj["uFBWProtocolID"] = storeObj.uFBWProtocolID == 0 ? 2 : storeObj.uFBWProtocolID;

          UFInputKeys?.map(x => {
            if (x != "uFModuleID") {
              const config = getFieldRangeConfig(x, moduleID);
              if (config?.length > 0) {
                UFDefaultConfigs[x] = config[0];
              } else {
                UFDefaultConfigs[x] = {};
              }
            }
          });
          if (unit.selectedUnits[2] !== "°C") {
            storeObj.recycleTemperature = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.recycleTemperature,
              unit.selectedUnits[2],
              "°C",
            ).toFixed(2);
          }
          if (unit.selectedUnits[3] !== "bar") {
            storeObj.maxAirScourPressure = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.maxAirScourPressure,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.maxAirProcPressure = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.maxAirProcPressure,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.filteratePressure = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.filteratePressure,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.nonIntegraPacTrainPresDrop = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.nonIntegraPacTrainPresDrop,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.integraPacFiltrationPreDrop = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.integraPacFiltrationPreDrop,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.backwashPipingPreDrop = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.backwashPipingPreDrop,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.cIPPipingPreDrop = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.cIPPipingPreDrop,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.backwash_Filtration = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.backwash_Filtration,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.acidCEB_Filtration = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.acidCEB_Filtration,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.alkaliCEB_Filtration = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.alkaliCEB_Filtration,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
            storeObj.cIP_Filtration = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.cIP_Filtration,
              unit.selectedUnits[3],
              "bar",
            ).toFixed(2);
          }
          if (unit.selectedUnits[4] !== "LMH") {
            storeObj.filtrateFlux = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.filtrateFlux,
              unit.selectedUnits[4],
              "LMH",
            ).toFixed(2);
            storeObj.backwashFlux = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.backwashFlux,
              unit.selectedUnits[4],
              "LMH",
            ).toFixed(2);
            storeObj.cEBFlux = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.cEBFlux,
              unit.selectedUnits[4],
              "LMH",
            ).toFixed(2);
          }
          if (unit.selectedUnits[1] !== "m³/h") {
            storeObj.forwardFlushFlow = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.forwardFlushFlow,
              unit.selectedUnits[1],
              "m³/h",
            ).toFixed(2);
            storeObj.recycleFlowRate = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.recycleFlowRate,
              unit.selectedUnits[1],
              "m³/h",
            ).toFixed(2);
            storeObj.recycleFlowRate_MiniCIP = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.recycleFlowRate_MiniCIP,
              unit.selectedUnits[1],
              "m³/h",
            ).toFixed(2);
          }
          if (unit.selectedUnits[18] !== "Nm³/h") {
            storeObj.airFlow = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.airFlow,
              unit.selectedUnits[18],
              "Nm³/h",
            ).toFixed(2);
            storeObj.aerationAirFlow = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.aerationAirFlow,
              unit.selectedUnits[18],
              "Nm³/h",
            ).toFixed(2);
          }
          if (unit.selectedUnits[9] !== "kW") {
            storeObj.pLCPowerReqPertrain = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.pLCPowerReqPertrain,
              unit.selectedUnits[9],
              "kW",
            ).toFixed(2);
            storeObj.volvePowerReqPerTrain = GlobalUnitConversion(
              GlobalUnitConversionStore,
              responseUFDetails.data.volvePowerReqPerTrain,
              unit.selectedUnits[9],
              "kW",
            ).toFixed(2);
          }
          dispatch(updateUFStore(storeObj));
          dispatch(updateUFDefaultInputRangeConfig(UFDefaultConfigs));
        }

        if (obj["offlinetimepertrain"] != 12 && obj["offlinetimepertrain"] != 0) {
          dispatch(setCustomOfflineTimePerUnit(true));
        }

        dispatch(
          updateUFStoreData({
            isForDrinkingWater: obj["drinkingWater_Ind"],
            isUfDataUpdated: false,
            isUFDetailsFetched: true,
            calcEngineDataRefreshCount: 1,
          }),
        );
      }
    }
  }, [responseUFDetails]);

  useEffect(() => {
    if (responseCeb.isSuccess && !responseCeb.isLoading) {
      const mapping = {
        Acid: "mineral",
        Base: "alkali",
        "Organic Acid": "organic",
        Oxidant: "oxidant",
      };
      const { lstUFCEBWaterTypeVM: waterType, lstChemicalVM } = responseCeb.data;
      const chemicalType = lstChemicalVM.reduce((acc, item) => {
        const key = mapping[item.name];
        if (!acc[key]) acc[key] = [];
        acc[key].push(item);
        return acc;
      }, {});

      // disinfection oxidant CEB has same chemicals as oxidant chemicals
      chemicalType.disOxidant = chemicalType.oxidant;

      updateStoreData({ cebDropdownData: { isFetched: true, chemicalType, waterType } });
    }
    if (responseCeb.isError) {
      throw new MyError("UFDetails Api Error", responseCeb.error.status, "ApiError");
    }
  }, [responseCeb]);

  useEffect(() => {
    if (responseCip.isError) {
      throw new MyError(responseCip.error.message, responseCip.error.status, "ApiError");
    }
    if (responseCip.isSuccess) {
      dispatch(handleCipDropdownData({ data: responseCip.data, isFetched: true }));
    }
  }, [responseCip]);

  const updateUFSubMenus = name => {
    if (UFData.uFModuleID == "" || UFData.uFModuleID == "0") {
      setIsError(true);
      setErrorMessage("No Modules Selected");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
    dispatch(setActiveTab(name));
    AutoSaveUFData();
  };

  const AutoSaveUFData = async () => {
    const data = {
      userID: UFData.userID,
      projectID: UFData.projectID,
      caseID: UFData.caseID,
      treatmentName: "UF",
      treatmentObjID: UFData.treatmentObjID,
      fromTreatmentObjID: UFData.fromTreatmentObjID,
      treatmentSeqNo: UFData.treatmentSeqNo,
      recoveryTypeID: parseInt(UFData.recoveryTypeID),
      recovery: Number(UFData.recovery),
      feed: Number(UFData.feed),
      automatic: UFData.automatic,
      recoveryRo: Number(UFData.recoveryRo),
      compactionTemperature: Number(UFData.compactionTemperature),
      isCompactionFlux: UFData.isCompactionFlux,
      uFDesignFluxID: parseInt(UFData.uFDesignFluxID),
      caseTreatmentID: parseInt(UFData.caseTreatmentID),
      filtrateFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.filtrateFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      backwashFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.backwashFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      cEBFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.cEBFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      forwardFlushFlow: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.forwardFlushFlow, "m³/h", unit.selectedUnits[1]).toFixed(
          2,
        ),
      ),
      airFlow: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.airFlow, "Nm³/h", unit.selectedUnits[18]).toFixed(2),
      ),
      aerationAirFlow: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.aerationAirFlow,
          "Nm³/h",
          unit.selectedUnits[18],
        ).toFixed(2),
      ),
      recycleFlowRate: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.recycleFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(
          2,
        ),
      ),
      recycleFlowRate_MiniCIP: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.recycleFlowRate_MiniCIP,
          "m³/h",
          unit.selectedUnits[1],
        ).toFixed(2),
      ),
      uFModuleID: parseInt(UFData.uFModuleID),
      flow_FF1: Number(UFData.flow_FF1),
      flow_FF2: Number(UFData.flow_FF2),
      flow_FF3: Number(UFData.flow_FF3),
      flow_FF4: Number(UFData.flow_FF4),
      aDBWDisplacement: Number(UFData.aDBWDisplacement),
      fTLDisplacement: Number(UFData.fTLDisplacement),
      typicalWaitDuration_Dupont: Number(UFData.typicalWaitDuration_Dupont),
      typicalPumpRamp_Dupont: Number(UFData.typicalPumpRamp_Dupont),
      typicalWaitDuration_Inge: Number(UFData.typicalWaitDuration_Inge),
      typicalPumpRamp_Inge: Number(UFData.typicalPumpRamp_Inge),
      typicalWaitDuration_Memcor: Number(UFData.typicalWaitDuration_Memcor),
      typicalPumpRamp_Memcor: Number(UFData.typicalPumpRamp_Memcor),
      uFDesignCycleIntervalsID: parseInt(UFData.uFDesignCycleIntervalsID),
      backwash_design: Number(UFData.backwash_design),
      airScour_design: Number(UFData.airScour_design),
      acidCEB: Number(UFData.acidCEB),
      alkaliOxidantCEB: Number(UFData.alkaliOxidantCEB),
      cIP: Number(UFData.cIP),
      miniCIP: Number(UFData.miniCIP),
      disinfectionCEB: Number(UFData.disinfectionCEB),
      t_CEB_Rinse12: Number(UFData.t_CEB_Rinse12),
      t_CEB_Rinse2: Number(UFData.t_CEB_Rinse2),
      t_BW1_CEBrinse: Number(UFData.bwRinseDrainTop),
      t_BW2_CEBrinse: Number(UFData.bwRinseDrainBottom),
      bwRinseDrainTop: Number(UFData.bwRinseDrainTop),
      bwRinseDrainBottom: Number(UFData.bwRinseDrainBottom),
      uFBWCEBStandbyOptionID: parseInt(UFData.uFBWCEBStandbyOptionID),
      bWPerCEBstandbyTrains: parseInt(UFData.bWPerCEBstandbyTrains),
      uFConfigurationID: parseInt(UFData.uFConfigurationID),
      uFCIPStandbyTrainOptionID: parseInt(UFData.uFCIPStandbyTrainOptionID),
      cIPstandbyTrains: parseInt(UFData.cIPstandbyTrains),
      integraPackDesign_Ind: UFData.integraPackDesign_Ind,
      drinkingWaterInd: UFData.drinkingWaterInd,
      membraneintergrityoption_Ind: UFData.membraneintergrityoption_Ind,
      modulesPerSkid: parseInt(UFData.modulesPerSkid),
      modulesPerTrain: parseInt(UFData.modulesPerTrain),
      offlinetimepertrain: parseInt(UFData.offlinetimepertrain),
      onlineTrains: parseInt(UFData.onlineTrains),
      redundantStandbyTrains: parseInt(UFData.redundantStandbyTrains),
      skids: parseInt(UFData.skids),
      skidsPerTrain: parseInt(UFData.skidsPerTrain),
      uFStorageTankOptionID: parseInt(UFData.uFStorageTankOptionID),
      totalModules: parseInt(UFData.totalModules),
      totalTrains: parseInt(UFData.totalTrains),
      redundantTrains: parseInt(UFData.redundantTrains),
      isBWCEBStandbyTrainsEnabled: UFData.isBWCEBStandbyTrainsEnabled,
      radTR1: UFData.radTR1,
      radTR2: UFData.radTR2,
      radTR3: UFData.radTR3,
      radMR1: UFData.radMR1,
      radMR2: UFData.radMR2,
      radMR3: UFData.radMR3,
      uFFiltrationID: Number(UFData.uFFiltrationID),
      backwash_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.backwash_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      acidCEB_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.acidCEB_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      alkaliCEB_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.alkaliCEB_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      cIP_Filtration: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.cIP_Filtration, "bar", unit.selectedUnits[3]).toFixed(2),
      ),
      miniCIP_Filtration: Number(UFData.miniCIP_Filtration),
      strainerRecovery: Number(UFData.strainerRecovery),
      strainerSize: Number(UFData.strainerSize),
      uFTanksID: Number(UFData.uFTanksID),
      chemicalStorageTime: Number(UFData.chemicalStorageTime),
      bWTankRefillRate: Number(UFData.bWTankRefillRate),
      filterateTank: Number(UFData.filterateTank),
      bWTank: Number(UFData.bWTank),
      cIPTank: Number(UFData.cIPTank),
      uFEquipmentPressureID: Number(UFData.uFEquipmentPressureID),
      maxAirScourPressure: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.maxAirScourPressure,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      maxAirProcPressure: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.maxAirProcPressure,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      filteratePressure: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.filteratePressure, "bar", unit.selectedUnits[3]).toFixed(
          2,
        ),
      ),
      nonIntegraPacTrainPresDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.nonIntegraPacTrainPresDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      integraPacFiltrationPreDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.integraPacFiltrationPreDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      backwashPipingPreDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.backwashPipingPreDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      cIPPipingPreDrop: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.cIPPipingPreDrop, "bar", unit.selectedUnits[3]).toFixed(
          2,
        ),
      ),
      uFPowerID: Number(UFData.uFPowerID),
      pLCPowerReqPertrain: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.pLCPowerReqPertrain,
          "kW",
          unit.selectedUnits[9],
        ).toFixed(2),
      ),
      volvePowerReqPerTrain: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.volvePowerReqPerTrain,
          "kW",
          unit.selectedUnits[9],
        ).toFixed(2),
      ),
      uFValvesID: Number(UFData.uFValvesID),
      valvesPerTrain: Number(UFData.valvesPerTrain),
      valveOpenCloseDuration: Number(UFData.valveOpenCloseDuration),
      uFCEBID: Number(UFData.uFCEBID),
      uFCEBWaterTypeID: parseInt(UFData.uFCEBWaterTypeID),
      ceb_AirScour: Number(UFData.ceb_AirScour),
      backWash1_CEB: Number(UFData.backWash1_CEB),
      backWash2_CEB: Number(UFData.backWash2_CEB),
      cEBTemperature: parseInt(UFData.cEBTemperature),
      chemicalSoakingDuration_CEB: parseInt(UFData.chemicalSoakingDuration_CEB),
      drain: Number(UFData.drain),
      forwardFlush: Number(UFData.forwardFlush),
      designTemperature_Ind: UFData.designTemperature_Ind,
      ceb_LSI: Number(UFData.ceb_LSI),
      sameAsBackwash_Ind: UFData.sameAsBackwash_Ind,
      alkaliEnabled_Ind_CEB: UFData.alkaliEnabled_Ind_CEB,
      organicEnabled_Ind_CEB: UFData.organicEnabled_Ind_CEB,
      oxidantEnabled_Ind_CEB: UFData.oxidantEnabled_Ind_CEB,
      mineralEnabled_Ind_CEB: UFData.mineralEnabled_Ind_CEB,
      disOxidantEnabled_Ind_CEB: UFData.disOxidantEnabled_Ind_CEB,
      mineralValue_CEB: Number(UFData.mineralValue_CEB),
      organicValue_CEB: Number(UFData.organicValue_CEB),
      oxidantValue_CEB: Number(UFData.oxidantValue_CEB),
      alkaliValue_CEB: Number(UFData.alkaliValue_CEB),
      disOxidantValue_CEB: Number(UFData.disOxidantValue_CEB),
      alkaliChemId_CEB: UFData.alkaliChemId_CEB.toString(),
      mineralChemId_CEB: UFData.mineralChemId_CEB.toString(),
      organicChemId_CEB: UFData.organicChemId_CEB.toString(),
      oxidantChemId_CEB: UFData.oxidantChemId_CEB.toString(),
      disOxidantChemId_CEB: UFData.disOxidantChemId_CEB.toString(),
      alkaliValueInPh_Ind_CEB: UFData.alkaliValueInPh_Ind_CEB,
      mineralValueInPh_Ind_CEB: UFData.mineralValueInPh_Ind_CEB,
      uFCIPID: Number(UFData.uFCIPID),
      bWStepInCIP: parseInt(UFData.bWStepInCIP),
      chemicalSoakingDuration_CIP: Number(UFData.chemicalSoakingDuration_CIP),
      uFCIPWaterTypeID: parseInt(UFData.uFCIPWaterTypeID),
      heatingStepDuration: Number(UFData.heatingStepDuration),
      cip_LSI: Number(UFData.cip_LSI),
      recycleDuration: Number(UFData.recycleDuration),
      recycleTemperature: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.recycleTemperature, "°C", unit.selectedUnits[2]).toFixed(
          2,
        ),
      ),
      rinseBWCycle: parseInt(UFData.rinseBWCycle),
      cIPRinseSoakCycle: Number(UFData.cIPRinseSoakCycle),
      alkaliEnabled_Ind_CIP: UFData.alkaliEnabled_Ind_CIP,
      organicEnabled_Ind_CIP: UFData.organicEnabled_Ind_CIP,
      oxidantEnabled_Ind_CIP: UFData.oxidantEnabled_Ind_CIP,
      mineralEnabled_Ind_CIP: UFData.mineralEnabled_Ind_CIP,
      oxidant2Enabled_Ind_CIP: UFData.oxidant2Enabled_Ind_CIP,
      mineralValue_CIP: Number(UFData.mineralValue_CIP),
      organicValue_CIP: Number(UFData.organicValue_CIP),
      oxidantValue_CIP: Number(UFData.oxidantValue_CIP),
      alkaliValue_CIP: Number(UFData.alkaliValue_CIP),
      oxidant2Value_CIP: Number(UFData.oxidant2Value_CIP),
      alkaliChemId_CIP: UFData.alkaliChemId_CIP.toString(),
      mineralChemId_CIP: UFData.mineralChemId_CIP.toString(),
      organicChemId_CIP: UFData.organicChemId_CIP.toString(),
      oxidantChemId_CIP: UFData.oxidantChemId_CIP.toString(),
      oxidant2ChemId_CIP: UFData.oxidant2ChemId_CIP?.toString(),
      alkaliValueInPh_Ind_CIP: UFData.alkaliValueInPh_Ind_CIP,
      mineralValueInPh_Ind_CIP: UFData.mineralValueInPh_Ind_CIP,
      uFBackWashID: parseInt(UFData.uFBackWashID),
      bWTemperature: Number(UFData.bWTemperature),
      oxidantEnabled_Ind_BW: UFData.oxidantEnabled_Ind_BW,
      uFBWWaterTypeID: parseInt(UFData.uFBWWaterTypeID),
      uFBWFlushWaterTypeID: parseInt(UFData.uFBWFlushWaterTypeID),
      uFBWProtocolID: parseInt(UFData.uFBWProtocolID),
      oxidantChemId_BW: UFData.oxidantChemId_BW.toString(),
      oxidantValue_BW: Number(UFData.oxidantValue_BW),
      backwash_AirScour: Number(UFData.backwash_AirScour),
      backWash1_backWash: Number(UFData.backWash1_backWash),
      backWash2_backWash: Number(UFData.backWash2_backWash),
      drain_backWash: Number(UFData.drain_backWash),
      forwardFlush_backWash: Number(UFData.forwardFlush_backWash),
      lF: Number(UFData.lF),
      t_FTL: Number(UFData.t_FTL),
      t_BWBtnAirScour: parseInt(UFData.t_BWBtnAirScour),
      uFMiniCIPID: Number(UFData.uFMiniCIPID),
      bWStepInMiniCIP: Number(UFData.bWStepInMiniCIP),
      rinseBWCycle_MiniCIP: parseInt(UFData.rinseBWCycle_MiniCIP),
      chemicalSoakingDuration_MiniCIP: Number(UFData.chemicalSoakingDuration_MiniCIP),
      uFMiniCIPWaterTypeID: parseInt(UFData.uFMiniCIPWaterTypeID),
      heatingStepDuration_MiniCIP: Number(UFData.heatingStepDuration_MiniCIP),
      lSI_MiniCIP: Number(UFData.lSI_MiniCIP),
      recycleDuration_MiniCIP: Number(UFData.recycleDuration_MiniCIP),
      recycleTemperature_MiniCIP: Number(UFData.recycleTemperature_MiniCIP),
      cIPRinseSoakCycle_MiniCIP: Number(UFData.cIPRinseSoakCycle_MiniCIP),
      alkaliEnabled_Ind_MiniCIP: UFData.alkaliEnabled_Ind_MiniCIP,
      organicEnabled_Ind_MiniCIP: UFData.organicEnabled_Ind_MiniCIP,
      oxidantEnabled_Ind_MiniCIP: UFData.oxidantEnabled_Ind_MiniCIP,
      mineralEnabled_Ind_MiniCIP: UFData.mineralEnabled_Ind_MiniCIP,
      oxidant2Enabled_Ind_MiniCIP: UFData.oxidant2Enabled_Ind_MiniCIP,
      mineralValue_MiniCIP: Number(UFData.mineralValue_MiniCIP),
      organicValue_MiniCIP: Number(UFData.organicValue_MiniCIP),
      oxidantValue_MiniCIP: Number(UFData.oxidantValue_MiniCIP),
      alkaliValue_MiniCIP: Number(UFData.alkaliValue_MiniCIP),
      oxidant2Value_MiniCIP: Number(UFData.oxidant2Value_MiniCIP),
      alkaliChemId_MiniCIP: UFData.alkaliChemId_MiniCIP.toString(),
      mineralChemId_MiniCIP: UFData.mineralChemId_MiniCIP.toString(),
      organicChemId_MiniCIP: UFData.organicChemId_MiniCIP.toString(),
      oxidantChemId_MiniCIP: UFData.oxidantChemId_MiniCIP.toString(),
      oxidant2ChemId_MiniCIP: UFData.oxidant2ChemId_MiniCIP.toString(),
      alkaliValueInPh_Ind_MiniCIP: UFData.alkaliValueInPh_Ind_MiniCIP,
      mineralValueInPh_Ind_MiniCIP: UFData.mineralValueInPh_Ind_MiniCIP,
      ufSpecialFeatureID: parseInt(UFData.ufSpecialFeatureID),
      pUFTechnologyID: parseInt(UFData.pUFTechnologyID),
    };
    const MethodName = { Method: "uf/api/v1/AutoSaveUFData" };
    const UFRequestDetails = {
      ...MethodName,
      ...data,
      ["drinkingWater_Ind"]: isForDrinkingWater,
      ["userID"]: loggedInUserID,
      ["projectID"]: projectID,
      ["caseID"]: caseID,
    };
    await POSTUFAutoSaveData(UFRequestDetails);
    dispatch(setUfDataUpdate(false));
  };

  const callUFDetailsAPI = () => {
    if (!isUFDetailsFetched) {
      const params = {
        caseID,
        projectID,
        userID: loggedInUserID,
        treatmentObjID: caseTreatmentID,
      };
      const queryParams = new URLSearchParams(params).toString();
      const UFDetailsURL = `${"uf/api/v1/UFDetails"}?${queryParams}`;
      getUFDetails(UFDetailsURL);
    }
  };
  const callCEBAPI = () => {
    if (isChemicalsListUpdated || !cebDropdownData.isFetched) {
      const UFCEBURL = `uf/api/v${1}/UFCEB?userID=${loggedInUserID}&projectID=${projectID}`;
      getCEBData(UFCEBURL);
    }
  };
  const callCIPAPI = () => {
    //Retriving Default Values for UF fields
    const UFCIPURL = `uf/api/v${1}/UFCIP?userID=${loggedInUserID}&projectID=${projectID}`;
    getCIPData(UFCIPURL);
  };

  const createJsonForTheLSI = ph => ({
    designTemp: tempDesign,
    methodName: "normal",
    ph: ph,
    ph25: 0,
    chargeBalance: 0,
    totalDissolvedSolutes: 0,
    totalDissolvedSolids: 0,
    estimatedConductivity: 0,
    degas: 0,
    initialCO2: 0,
    equilibrateWith: 0,
    totalCO2: 0,
    LSITarg: "1",
    SDITarg: 0,
    adjustmentType: 0,
    addReagent: 0,
    cations: StreamStoreData.cations,
    anions: StreamStoreData.anions,
    neutrals: StreamStoreData.neutrals,
    chemicalAdjustment: [
      {
        CaSO4: 0,
        BaSO4: 0,
        SrSO4: 0,
        CaF2: 0,
        SiO2: 0,
        MgOH2: 0,
        LSI: 0,
        SDI: 0,
        ionicStrength: 0,
        pH: StreamStoreData?.pH || 0,
      },
    ],
  });

  useEffect(() => {
    const chemical = showInDropDown.find(chem => chem.iD == UFData.alkaliChemId_CEB);

    if (isValueInPh && chemical) {
      getCebLSIValue({
        Method: "masterdata/api/v1/CalculateChemicalAdjustment",
        ...createJsonForTheLSI(UFData.alkaliValue_CEB <= 14 ? UFData.alkaliValue_CEB : 12),
      });
    } else {
      updateStoreData({ data: { ceb_LSI: 0 } });
    }
  }, [UFData.alkaliValue_CEB, UFData.alkaliChemId_CEB, UFData.alkaliEnabled_Ind_CEB]);

  useEffect(() => {
    const chemical = showInDropDown.find(chem => chem.iD == UFData.alkaliChemId_CIP);
    if (isValueInPh && chemical) {
      getCipLSIValue({
        Method: "masterdata/api/v1/CalculateChemicalAdjustment",
        ...createJsonForTheLSI(UFData.alkaliValue_CIP <= 14 ? UFData.alkaliValue_CIP : 12),
      });
    } else {
      updateStoreData({ data: { cip_LSI: 0 } });
    }
  }, [UFData.alkaliValue_CIP, UFData.alkaliChemId_CIP, UFData.alkaliEnabled_Ind_CIP]);

  useEffect(() => {
    const chemical = showInDropDown.find(chem => chem.iD == UFData.alkaliChemId_MiniCIP);
    if (isValueInPh && chemical) {
      getMiniCipLSIValue({
        Method: "masterdata/api/v1/CalculateChemicalAdjustment",
        ...createJsonForTheLSI(UFData.alkaliValue_MiniCIP <= 14 ? UFData.alkaliValue_MiniCIP : 12),
      });
    } else {
      updateStoreData({ data: { lSI_MiniCIP: 0 } });
    }
  }, [UFData.alkaliValue_MiniCIP, UFData.alkaliChemId_MiniCIP, UFData.alkaliEnabled_Ind_MiniCIP]);

  useEffect(() => {
    if (responseCebLSIValue.isSuccess) {
      // dispatch(updateCebData())
      const cebLsi = responseCebLSIValue.data.chemicalAdjustment[0].LSI;
      updateStoreData({ data: { ceb_LSI: cebLsi == "NaN" ? 0 : Number(cebLsi).toFixed(2) } });
    }
  }, [responseCebLSIValue]);
  useEffect(() => {
    if (responseCipLSIValue.isSuccess) {
      // dispatch(updateCebData())
      const cebLsi = responseCipLSIValue.data.chemicalAdjustment[0].LSI;
      updateStoreData({ data: { cip_LSI: cebLsi == "NaN" ? 0 : Number(cebLsi).toFixed(2) } });
    }
  }, [responseCipLSIValue]);

  useEffect(() => {
    if (responseMiniCipLSIValue.isSuccess) {
      // dispatch(updateCebData())
      const miniCipLsi = responseMiniCipLSIValue.data.chemicalAdjustment[0].LSI;
      updateStoreData({ data: { lSI_MiniCIP: miniCipLsi == "NaN" ? 0 : Number(miniCipLsi).toFixed(2) } });
    }
  }, [responseMiniCipLSIValue]);
  const headerMenuIconStatus = useSelector(state => state.UFStore.headerMenuIcon);
  const [tabletView, setTabletView] = useState(false);
  const handleCloseSideMenu = () => {
    // setShowSideMenu(!showSideMenu);
    dispatch(updateMenuIconHeader(!headerMenuIconStatus));
  };

  const handleResize = () => {
    if (window.innerWidth <= 1200) {
      setTabletView(true);
      dispatch(updateTabletMenuIcon(tabletView));
    } else {
      setTabletView(false);
      dispatch(updateTabletMenuIcon(tabletView));
    }
  }; //show side menu for width >=1300

  useEffect(() => {
    handleResize(); // set initial state based on window size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabletView]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home");
    dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
    dispatch(updateTabAvailableForUF({ FeedSetup: false, UF: false }));
  };
  const selectPanel = activeTab => {
    switch (activeTab) {
      case "Design":
        return (
          <TabPanel>
            <Design />
          </TabPanel>
        );
      case "Configuration":
        return (
          <TabPanel>
            <Configuration />
          </TabPanel>
        );
      case "Backwash":
        return (
          <TabPanel>
            <Backwash />
          </TabPanel>
        );
      case "CEB":
        return (
          <TabPanel>
            <CEB />
          </TabPanel>
        );
      case "CIP":
        return (
          <TabPanel>
            <CIP />
          </TabPanel>
        );
      case "Additional Settings":
        return (
          <TabPanel>
            <AdditionalSetting />
          </TabPanel>
        );
      case "miniCIP":
        return (
          <TabPanel>
            <UFMiniCip />
          </TabPanel>
        );
      default:
        break;
    }
  };

  return (
    <DynamicLoadder isLoading={!isUFDetailsFetched}>
      <>
        <UFStyled tabletView={tabletView} headerMenuIconStatus={headerMenuIconStatus}>
          <Tabs>
            <div className='uf-tablist-column tablist_overlay'>
              <div className='wrapper'>
                {tabletView && (
                  <div className='tablet_menu_view'>
                    <div>
                      <button className='close_icon_btn' onClick={handleCloseSideMenu}>
                        <TabletCloseMenuIcon />
                      </button>
                    </div>
                    <WaveProBrandLogo handleNavigate={handleNavigate} />
                  </div>
                )}
                <TabList>
                  <div
                    onClick={() => updateUFSubMenus("Design")}
                    className={`tab ${activeTab == "Design" ? "selected" : ""}`}
                  >
                    Design
                  </div>
                  <div
                    onClick={() => updateUFSubMenus("Configuration")}
                    className={`tab ${activeTab == "Configuration" ? "selected" : ""}`}
                  >
                    Configuration
                  </div>
                  <div
                    onClick={() => updateUFSubMenus("Backwash")}
                    className={`tab ${activeTab == "Backwash" ? "selected" : ""}`}
                  >
                    Backwash
                  </div>
                  {(UFData.ufSpecialFeatureID == 1 || UFData.ufSpecialFeatureID == 3) && (
                    <div
                      onClick={() => updateUFSubMenus("CEB")}
                      className={`tab ${activeTab == "CEB" ? "selected" : ""}`}
                    >
                      CEB
                    </div>
                  )}
                  {(UFData.ufSpecialFeatureID == 2 || UFData.ufSpecialFeatureID == 3) && (
                    <div
                      onClick={() => updateUFSubMenus("miniCIP")}
                      className={`tab ${activeTab == "miniCIP" ? "selected" : ""}`}
                    >
                      mini-CIP
                    </div>
                  )}

                  <div
                    onClick={() => updateUFSubMenus("CIP")}
                    className={`tab ${activeTab == "CIP" ? "selected" : ""}`}
                  >
                    CIP
                  </div>
                  <div
                    onClick={() => updateUFSubMenus("Additional Settings")}
                    className={`tab ${activeTab == "Additional Settings" ? "selected" : ""}`}
                  >
                    Additional Settings
                  </div>
                </TabList>
              </div>
            </div>
            <div className='uf-tabPanel-column'>{selectPanel(activeTab)}</div>
          </Tabs>
        </UFStyled>
        {isError && (
          <ProjectErrorPopup
            show={isError}
            close={() => {
              setIsError(false);
            }}
            message={errorMessage}
          />
        )}
      </>
    </DynamicLoadder>
  );
};

export default UF;
