import React from "react";
import { Modal } from "react-bootstrap";

import CircularLoader from "@common/styles/components/circularLoader";
import StyledModal from "@common/styles/components/modals/CustomModal";

import "./index.css";

const LoaderPopUp = ({ loadingText }) => (
  <StyledModal
    show
    centered
    backdrop='static'
    maxWidth='412px'
    dialogClassName='primary-modal'
    backdropClassName='dark-backdrop'
    keyboard={false}
  >
    <Modal.Body>
      <div className='modal-body'>
        <CircularLoader />
        {loadingText && <div className='loading-text'>{loadingText}</div>}
      </div>
    </Modal.Body>
  </StyledModal>
);

export default LoaderPopUp;
