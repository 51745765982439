import React from "react";

import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

export const WPReportSideNav = () => (
  <>
    <WPTypogrpahy
      variant={typographyVariants.xsText}
      text=' You may select checkboxes below to view the details of a specific parameter on the right side.'
    />
  </>
);
