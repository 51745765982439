/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Col, Form, InputGroup, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useFeedSetupDataHandler from "@hooks/useFeedSetupDataHandler";
import useUFDataHandler from "@hooks/useUFDataHandler";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUpto2Digits } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import InfoIcon from "@common/icons/InfoIcon";
import AlertPopUp from "@common/notifications/AlertPopUp";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import WarningMessage from "@common/styles/components/headings/WarningMessage";
import CalcEngInputWithIcon from "@common/styles/components/inputs/CalcEngInputWithIcon";
import CustomTextArea from "@common/styles/components/inputs/CustomTextArea";
import CustomInputGroup from "@common/styles/components/inputs/InputTest";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { colors } from "@common/styles/Theme";
import DynamicLoadder from "@common/utils/DynamicLoadder";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import {
  updateUnitFlag,
  updateUnitTypeConductivity,
  updateUnitTypeOrganic,
  updateUnitTypeTemp,
} from "@common/utils/GlobalUnitConversionSlice";

import { useCreateDataMutation } from "@services/apiConfig";

import { updateLoader } from "../../home/CardListSlice";
import {
  detectDataChange,
  Feedsetupdetailsdata,
  UpdatedStream,
  updateFeedSetupStoreData,
} from "../feedsetup/FeedsetupSlice";
import { TECH_UF } from "../ix/constants/IXDConstants";
import { isIXTechnology } from "../ix/IXDUtilityFunction";

import { updateUFStore, updateWaterSubtypeFlag } from "./../uf/UFSlice";
import { FEED_STRINGS } from "./constants/FeedWaterConstants";
import FeedSetupTemperatures from "./FeedSetupTemperatures";
import FormEntryStyled from "./FormEntryStyled";
import FormInputBox from "./FormInputBox";

const FormEntry = () => {
  const Logger = DupontLogger("FormEntry");
  const dispatch = useDispatch();
  const { unitConversionByName } = useUnitConversion();
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const isImported = useSelector(state => state.projectInfo?.data?.isImported);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const { techNolist } = useSelector(state => state.processDiagramSlice);
  const { unitTypeOrganic, unitTypeConductivity } = useSelector(state => state.GUnitConversion);
  const {
    waterTypes,
    waterSubTypes,
    isDataFetched,
    title: StreamName,
    data: StoreData,
    streamData,
    selectedWaterSubType,
    isCopyToFeedWaterSuccess,
  } = useSelector(state => state.Feedsetupdetailsdatapanel);
  const FeedData = streamData?.lstrequestsavefeedwater?.[0].streams || [];

  const [cancelFlag, setCancelFlag] = useState(false);
  const { projectDetails, fetchWaterTypes, fetchWaterSubTypes, fetchFeedSetupData } = useUFDataHandler();

  const { fetchCIPChemicalData } = useFeedSetupDataHandler();
  const { projectid, userID, caseID, projectTechnology } = projectDetails;

  const [isDataLoading, setIsDataLaoding] = useState(true);
  const [Co2flag, setCo2flag] = useState(false);
  const [Co3flag, setCo3flag] = useState(false);
  const [HCo3flag, setHCo3flag] = useState(false);

  const [waterType, setwaterType] = useState([]);
  const [waterTypeid, setWaterTypeid] = useState();
  const [subwaterTypeid, setsubwaterTypeid] = useState(0);
  const [subwaterType, setsubwaterType] = useState([]);
  const [disabeldWaterType, setDisabeldWaterType] = useState(false);
  const [Stremdata, setStremdata] = useState();
  const [FeedWaterDetails, setFeedWaterDetails] = useState();
  const [getCalFeedwaterDetails] = useCreateDataMutation();

  const [showAlert, setAlertVisibility] = useState(false);
  const [alertData, setAlert] = useState({ type: "", message: "" });
  const [TotalppmCaCO3, setTotalppmCaCO3] = useState();
  const [isFocused, setIsFocused] = useState(null);

  const UFData = useSelector(state => state.UFStore.data);
  const [subWaterIDForUF, setSubWaterIDForUF] = useState(UFData.waterSubTypeID);
  const [designTemperatureForUF, setDesignTemperatureForUF] = useState(UFData.designTemp);
  const [isWaterSubTypeChanged, setIsWaterSubTypeChanged] = useState(false);
  const [isDesignTempChanged, setIsDesignTempChanged] = useState(false);
  const { cipDropdownData } = useSelector(state => state.UFStore);
  const isProjectTechnologyValid = useMemo(
    () => projectTechnology === TECH_UF || isIXTechnology(projectTechnology) || projectTechnology === "null",
    [projectTechnology],
  );

  useEffect(() => {
    setwaterType(waterTypes);
    if (waterTypes?.length) {
      setIsDataLaoding(false);
    }
  }, [waterTypes]);

  useEffect(() => setsubwaterType(waterSubTypes), [waterSubTypes]);

  useEffect(() => {
    const waterSubTypeID = streamData?.lstrequestsavefeedwater?.[0]?.streams[0]?.waterSubTypeID || subwaterTypeid;
    Logger.log(`Fetching fetchWaterSubTypes for waterTypeid ${waterTypeid}, waterSubTypeID ${waterSubTypeID}`);
    fetchWaterSubTypes(waterTypeid, waterSubTypeID);
  }, [waterTypeid]);

  useEffect(() => {
    setDisabeldWaterType(techNolist?.includes("IXD") || techNolist?.includes("IXMB"));
  }, [techNolist]);

  useEffect(() => {
    if (!cipDropdownData.isFetched) {
      fetchCIPChemicalData();
    }
  }, []);

  useEffect(() => {
    Logger.log("selectedWaterSubType changed:", selectedWaterSubType);
    if (selectedWaterSubType) {
      handleWaterSubTypeChange({ target: { value: selectedWaterSubType.waterSubTypeId } });
    }
  }, [selectedWaterSubType]);

  useEffect(() => {
    const obj = { ...UFData };
    obj["waterSubTypeID"] = subWaterIDForUF;
    obj["designTemp"] = designTemperatureForUF;
    obj["isWaterSubTypeChanged"] = isWaterSubTypeChanged;
    obj["isDesignTempChanged"] = isDesignTempChanged;
    dispatch(updateUFStore(obj));
  }, [subWaterIDForUF, designTemperatureForUF]);

  useEffect(() => {
    const convertedData =
      Stremdata &&
      Stremdata.map(data => {
        data = {
          ...data,
          toc: GlobalUnitConversion(GlobalUnitConversionStore, data.toc, unitTypeOrganic, unit.selectedUnits[19]),
        };
        return data;
      });
    const feeddata =
      FeedWaterDetails &&
      FeedWaterDetails?.map(data => {
        data = {
          ...data,
          estimatedConductivity: GlobalUnitConversion(
            GlobalUnitConversionStore,
            data.estimatedConductivity,
            unit.selectedUnits[17],
            unitTypeConductivity,
          ),
        };
        return data;
      });
    dispatch(updateLoader(true));
    setStremdata(convertedData);
    setFeedWaterDetails(feeddata);
    setTimeout(() => {
      dispatch(updateLoader(false));
    }, 5);
    dispatch(updateUnitTypeOrganic(unit.selectedUnits[19]));
    dispatch(updateUnitTypeTemp(unit.selectedUnits[2]));
    dispatch(updateUnitTypeConductivity(unit.selectedUnits[17]));
    dispatch(updateUnitFlag(false));
    // }
  }, [unit.selectedUnits]);

  useEffect(() => {
    const obj = { ...UFData };
    obj["waterSubTypeID"] = subWaterIDForUF;
    obj["designTemp"] = designTemperatureForUF;
    obj["isWaterSubTypeChanged"] = isWaterSubTypeChanged;
    obj["isDesignTempChanged"] = isDesignTempChanged;
    dispatch(updateUFStore(obj));
  }, [subWaterIDForUF, designTemperatureForUF]);

  const getFeedData = async feedWaterData => {
    if (feedWaterData && feedWaterData.length > 0) {
      const { cations, anions, neutrals, tempDesign } = feedWaterData[0];

      const calculateFeedWaterDataJson = {
        Method: "masterdata/api/v1/CalculateFeedWaterData",
        userID,
        projectID: projectid,
        caseID,
        typeFlag: 0,
        feedStream: {
          ...feedWaterData[0],
          designTemp: tempDesign,
          methodname: "normal",
          cations: [...cations],
          anions: [...anions],
          neutrals: [...neutrals],
        },
      };

      const responseCalFeedwaterDetails = await getCalFeedwaterDetails(calculateFeedWaterDataJson);
      if (responseCalFeedwaterDetails.data) {
        const calculatedData = responseCalFeedwaterDetails.data;
        dispatch(Feedsetupdetailsdata([calculatedData]));

        const newStreamData = [
          {
            ...feedWaterData[0],
            ...calculatedData,
          },
        ];
        setStremdata(newStreamData);
      }
    }
  };

  const onFeedDataFetched = feedWaterData => {
    const waterTypeId = feedWaterData[0].waterTypeId || 5;
    const waterSubTypeID = feedWaterData[0].waterSubTypeID;
    if (!waterTypes || waterTypes.length === 0) {
      fetchWaterTypes();
    } else {
      setIsDataLaoding(false);
    }
    fetchWaterSubTypes(waterTypeid, waterSubTypeID);
    setsubwaterTypeid(waterSubTypeID);
    setWaterTypeid(waterTypeId);
    setStremdata(feedWaterData);
    dispatch(detectDataChange(false));
  };

  useEffect(() => {
    const loadFeedWaterData = async () => {
      let feedWaterData;
      if (!isDataFetched) {
        feedWaterData = await fetchFeedSetupData();
      } else {
        const data = streamData.lstrequestsavefeedwater[0].streams[0];
        feedWaterData = [{ waterTypeId: data.waterTypeID, ...data }];
        // This need to be done only for imported project as they have data in meql only from desktop
        if (isImported) getFeedData(feedWaterData);
      }
      onFeedDataFetched(feedWaterData);
    };

    loadFeedWaterData();
  }, []);

  useEffect(() => {
    if (StoreData === 0) {
      <div>Loading....</div>;
    } else {
      setFeedWaterDetails(StoreData);
    }
  }, [StoreData]);

  useEffect(() => {
    if (isCopyToFeedWaterSuccess && FeedData) {
      setStremdata(FeedData);
      setWaterTypeid(FeedData[0].waterTypeID);
      setsubwaterTypeid(FeedData[0].waterSubTypeID);
      dispatch(updateFeedSetupStoreData({ isCopyToFeedWaterSuccess: false }));
    }
  }, [isCopyToFeedWaterSuccess]);
  useEffect(() => {
    if (StoreData === 0) {
      <div>Loading....</div>;
    } else {
      const neutralsmql = FeedWaterDetails?.map(a =>
        a.neutrals.reduce((total, neutralsValue) => (total = total + neutralsValue.mgL), 0),
      );
      const cationsmql = FeedWaterDetails?.map(a =>
        a.cations.reduce((total, currentValue) => (total = total + currentValue.mgL), 0),
      );
      const cationsmeql = FeedWaterDetails?.map(a =>
        a.cations.reduce((total, currentValue) => (total = total + currentValue.meqL), 0),
      );
      const cationsppm = FeedWaterDetails?.map(a =>
        a.cations.reduce((total, currentValue) => (total = total + currentValue.ppm), 0),
      );
      const anionsmql = FeedWaterDetails?.map(a =>
        a.anions.reduce((total, anionsValue) => (total = total + anionsValue.mgL), 0),
      );
      const anionsmeql = FeedWaterDetails?.map(a =>
        a.anions.reduce((total, anionsValue) => (total = total + anionsValue.meqL), 0),
      );
      const anionsppm = FeedWaterDetails?.map(a =>
        a.anions.reduce((total, anionsValue) => (total = total + anionsValue.ppm), 0),
      );

      setGridTotal([
        {
          CationsMql: cationsmql,
          CationsMeql: cationsmeql,
          Cationsppm: cationsppm,
          AnionsMql: anionsmql,
          AnionsMeql: anionsmeql,
          Anionsppm: anionsppm,
          neutralsMql: neutralsmql,
        },
      ]);

      if (cationsppm > anionsppm) {
        setTotalppmCaCO3(cationsppm);
      } else {
        setTotalppmCaCO3(anionsppm);
      }
    }
  }, [FeedWaterDetails]);

  // FeedWaterDatasave json
  useEffect(() => {
    FeedWaterDetails?.map(() => {
      const newArrycations = FeedWaterDetails[0].cations; // copying the old datas array
      const newArryanions = FeedWaterDetails[0].anions;
      const newArryneutrals = FeedWaterDetails[0].neutrals;
      const ph = FeedWaterDetails[0].ph;
      const ph25 = FeedWaterDetails[0].ph25;
      const vaildStremdata = Stremdata?.map(u => {
        const newwatertypeid = u.waterTypeID;
        const newwatersubtypeid = u.waterSubTypeID;

        setSubWaterIDForUF(u.waterSubTypeID);
        setDesignTemperatureForUF(u.tempDesign);

        const FeedWaterDatasave = [
          {
            Method: "masterdata/api/v1/FeedWaterData",
            userID: userID,
            projectID: projectid,
            caseID: caseID,
            lstrequestsavefeedwater: [
              {
                streams: [
                  {
                    waterTypeID: newwatertypeid,
                    waterSubTypeID: newwatersubtypeid,
                    feedStreamID: u.feedStreamId,
                    streamName: StreamName,
                    feedTitle: `Feed Setup - ${StreamName}`,
                    turbidity: u.turbidity,
                    tss: u.tss,
                    toc: u.toc,
                    sdi: u.sdi,
                    pH: ph,
                    ph25: ph25,
                    tempDesign: u.tempDesign,
                    tempMax: u.tempMax,
                    tempMin: u.tempMin,
                    tds: u.tds,
                    tcb: u.tcb,
                    percentContribution: 100,
                    balacedInd: true,
                    blendedStreamInd: true,
                    additionalFeedWaterInfo: u.additionalFeedWaterInfo,
                    totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
                    totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
                    chargeBalance: Number.parseFloat(FeedWaterDetails[0].chargeBalance).toFixed(6),
                    estimatedConductivity: GlobalUnitConversion(
                      GlobalUnitConversionStore,
                      FeedWaterDetails[0].estimatedConductivity,
                      "µS/cm",
                      unit.selectedUnits[17],
                    ),

                    pHDesign: FeedWaterDetails[0].pHDesign,
                    cations: newArrycations,
                    anions: newArryanions,
                    neutrals: newArryneutrals,
                  },
                ],
              },
            ],
          },
        ];
        dispatch(UpdatedStream(FeedWaterDatasave[0]));
        return FeedWaterDatasave;
      });

      return vaildStremdata;
    });
  }, [Stremdata, FeedWaterDetails, StreamName]);

  // select water type
  const handleWaterTypeChange = ({ target }) => {
    const waterTypeId = target.value;
    const selectedWaterType = waterType.find(data => data.waterTypeId == waterTypeId);
    Logger.log("handleWaterTypeChange waterTypeId, selectedWaterType: ", waterTypeId, selectedWaterType);
    setWaterTypeid(waterTypeId);
    setsubwaterTypeid(0);
    const updatedData = { waterTypeId, waterSource: selectedWaterType?.waterSource, waterSubTypeID: 0 };
    const updatedDataStream = {
      waterTypeID: waterTypeId,
      waterSource: selectedWaterType?.waterSource,
      waterSubTypeID: 0,
    };
    const StreamDetails = Stremdata?.map(item => ({ ...item, ...updatedDataStream }));
    const FeedWaterDetailsdata = FeedWaterDetails?.map(item => ({ ...item, ...updatedData }));

    setStremdata(StreamDetails);

    dispatch(detectDataChange(true));
    dispatch(updateFeedSetupStoreData({ isUpdateActiveModule: true }));
    dispatch(updateWaterSubtypeFlag(true));
    dispatch(Feedsetupdetailsdata([FeedWaterDetailsdata[0]]));
  };

  const handleWaterSubTypeChange = ({ target }) => {
    dispatch(detectDataChange(true));
    const waterSubTypeID = target.value;
    const StreamDetails = Stremdata?.map(item => ({ ...item, waterSubTypeID }));
    setsubwaterTypeid(waterSubTypeID);
    setSubWaterIDForUF(waterSubTypeID);
    setIsWaterSubTypeChanged(true);
    setStremdata(StreamDetails);
    dispatch(updateWaterSubtypeFlag(waterSubTypeID != subwaterTypeid));
  };

  const handleShowAlert = (type, message) => {
    setAlert({ type, message });
    setAlertVisibility(true);
  };
  const handleHideAlert = () => {
    setAlert({ type: "", message: "" });
    setAlertVisibility(false);
  };

  const [GridTotal, setGridTotal] = useState([
    {
      CationsMql: 0.0,
      CationsMeql: 0.0,
      Cationsppm: 0.0,
      AnionsMql: 0.0,
      AnionsMeql: 0.0,
      Anionsppm: 0.0,
      neutralsMql: 0.0,
    },
  ]);

  // API Call values calculation
  const CalengneAPIcall = async (payload, updatedData = Stremdata) => {
    dispatch(updateLoader(true));
    const responseCalFeedwaterDetails = await getCalFeedwaterDetails(payload);
    if (responseCalFeedwaterDetails.data != null) {
      const calculatedData = responseCalFeedwaterDetails.data;
      dispatch(Feedsetupdetailsdata([calculatedData]));
      const newStreamData = [
        {
          ...updatedData[0],
          ph: calculatedData.ph,
          ph25: calculatedData.ph25,
          anions: calculatedData.anions,
          cations: calculatedData.cations,
          neutrals: calculatedData.neutrals,
          tempDesign: calculatedData.designTemp,
          chargeBalance: calculatedData.chargeBalance,
          equilibrate_with: calculatedData.equilibrate_with,
          totalDissolvedSolids: calculatedData.totalDissolvedSolids,
          totalDissolvedSolutes: calculatedData.totalDissolvedSolutes,
          estimatedConductivity: calculatedData.estimatedConductivity,
        },
      ];
      setStremdata(newStreamData);
    }
  };

  //  On textchange event
  const onchangevalus = (title, subtitle, name, e, newStreamData = Stremdata) => {
    if (e == "" && e !== 0 && cancelFlag == false && title !== "inputPH") {
      dispatch(updateLoader(true));
      let inputString = name.replace(/₂/g, "2").replace(/₄/g, "4").replace(/₃/g, "3");
      if (!inputString.includes("Cl")) {
        inputString = inputString.toUpperCase();
      }
      const modifiedString = "inputRef" + inputString + subtitle;
      eval(modifiedString).current.value = 0.0;
      e = 0.0;
      setTimeout(() => {
        dispatch(updateLoader(false));
      }, 5);
    }
    const TDScount = FeedWaterDetails[0].totalDissolvedSolutes;
    const Userinput = e;
    const TotalTDScount = parseInt(Userinput) + parseInt(TDScount);
    if (TotalTDScount >= 1000000) {
      dispatch(updateLoader(true));
      const warningMessage = "The most recent entry caused the TDS to exceed 1000000, Please revise your input.";
      handleShowAlert("warning", warningMessage);
      setTimeout(() => {
        dispatch(updateLoader(false));
      }, 1);
    } else {
      setIsFocused(null);
      if (e != null || e.trim() !== "" || e >= 0) {
        if (title === "cations") {
          let indexget = 0;
          // const Inputvalues=0;
          const Cationinput = [...FeedWaterDetails[0].cations];
          const cationsdata = FeedWaterDetails[0].cations
            .filter(item => item.name === name)
            .map((u, index) => {
              const newinputmgL = Number.parseFloat(u.mgL).toFixed(3);
              const newinputmeqL = Number.parseFloat(u.meqL).toFixed(3);
              const newinputppm = Number.parseFloat(u.ppm).toFixed(3);
              if (subtitle == "mgL") {
                const num = Number.parseFloat(e).toFixed(3);
                u = { ...u, mgL: num, ppm: 0, meqL: 0 };
                indexget = FeedWaterDetails[0].cations.findIndex(i => i.name === name || i.mgL === subtitle);
                const Inputvalues = { ...u, mgL: num, meqL: newinputmeqL, ppm: newinputppm };
                Cationinput[indexget] = Inputvalues;
              } else if (subtitle == "meqL") {
                const num = Number.parseFloat(e).toFixed(3);
                u = { ...u, meqL: num, mgL: 0, ppm: 0 };
                const Inputvalues = { ...u, meqL: num, mgL: newinputmgL, ppm: newinputppm };
                indexget = FeedWaterDetails[0].cations.findIndex(i => i.name === name || i.meqL === subtitle);
                Cationinput[indexget] = Inputvalues;
              } else if (subtitle == "ppm") {
                const num = Number.parseFloat(e).toFixed(3);
                u = { ...u, ppm: num, meqL: 0, mgL: 0 };
                const Inputvalues = { ...u, ppm: num, meqL: newinputmeqL, mgL: newinputmgL };
                indexget = FeedWaterDetails[0].cations.findIndex(i => i.name === name || i.ppm === subtitle);
                Cationinput[indexget] = Inputvalues;
              }
              const newArrycations = [...FeedWaterDetails[0].cations]; // copying the old datas array
              newArrycations[indexget] = u; // replace e.target.value with whatever you want to change it to
              const newArryanions = [...FeedWaterDetails[0].anions];
              const newArryneutrals = [...FeedWaterDetails[0].neutrals];
              dispatch(
                Feedsetupdetailsdata([
                  {
                    ...StoreData,
                    ph: FeedWaterDetails[0].ph,
                    ph25: FeedWaterDetails[0].ph25,
                    totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
                    totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
                    chargeBalance: FeedWaterDetails[0].chargeBalance,
                    estimatedConductivity: FeedWaterDetails[0].estimatedConductivity,
                    anions: newArryanions,
                    cations: Cationinput,
                    neutrals: newArryneutrals,
                  },
                ]),
              );
              const CalculateFeedWaterDataJson = {
                Method: "masterdata/api/v1/CalculateFeedWaterData",
                userID: userID,
                projectID: projectid,
                caseID,
                typeFlag: 0,
                feedStream: {
                  designTemp: newStreamData[0].tempDesign,
                  methodname: "normal",
                  ph: FeedWaterDetails[0].ph,
                  ph25: FeedWaterDetails[0].ph25,
                  TotalDissolvedSolutes: 0.0,
                  TotalDissolvedSolids: 0.0,
                  ChargeBalance: 0.0,
                  EstimatedConductivity: 0.0,
                  Degas: 0.0,
                  percentage_of_initial_total_CO2_remaining: 100.0,
                  Equilibrate_with: 0.0,
                  Adjustment_Type: 0.0,
                  Add_Reagent: 0.0,
                  Total_CO2: 0.0,
                  cations: newArrycations,
                  anions: newArryanions,
                  neutrals: newArryneutrals,
                },
              };

              if (newinputmgL !== e && newinputmeqL !== e && newinputppm !== e) {
                CalengneAPIcall(CalculateFeedWaterDataJson);
              }

              return newArrycations;
            });
        } else if (title === "anions") {
          let indexget = 0;
          const Anionsinput = [...FeedWaterDetails[0].anions];
          FeedWaterDetails[0].anions
            .filter(item => item.name === name)
            .map((u, index) => {
              const newinputmgL = Number.parseFloat(u.mgL).toFixed(3);
              const newinputmeqL = Number.parseFloat(u.meqL).toFixed(3);
              const newinputppm = Number.parseFloat(u.ppm).toFixed(3);
              if (subtitle == "mgL") {
                const num = Number.parseFloat(e).toFixed(3);
                u = { ...u, mgL: num, meqL: 0, ppm: 0 };
                const Inputvalues = { ...u, mgL: num, meqL: newinputmeqL, ppm: newinputppm };
                indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.mgL === subtitle);
                Anionsinput[indexget] = Inputvalues;
              } else if (subtitle == "meqL") {
                const num = Number.parseFloat(e).toFixed(3);
                u = { ...u, meqL: num, mgL: 0, ppm: 0 };
                const Inputvalues = { ...u, meqL: num, mgL: newinputmgL, ppm: newinputppm };
                indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.meqL === subtitle);
                Anionsinput[indexget] = Inputvalues;
              } else if (subtitle == "ppm") {
                const num = Number.parseFloat(e).toFixed(3);
                u = { ...u, ppm: num, mgL: 0, meqL: 0 };
                const Inputvalues = { ...u, ppm: num, meqL: newinputmeqL, mgL: newinputmgL };
                indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.ppm === subtitle);
                Anionsinput[indexget] = Inputvalues;
              }
              const newArrycations = [...FeedWaterDetails[0].cations]; // copying the old datas array
              const newArryanions = [...FeedWaterDetails[0].anions];
              newArryanions[indexget] = u; // replace e.target.value with whatever you want to change it to
              const newArryneutrals = [...FeedWaterDetails[0].neutrals];
              dispatch(
                Feedsetupdetailsdata([
                  {
                    ...StoreData,
                    ph: FeedWaterDetails[0].ph,
                    ph25: FeedWaterDetails[0].ph25,
                    totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
                    totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
                    chargeBalance: FeedWaterDetails[0].chargeBalance,
                    estimatedConductivity: FeedWaterDetails[0].estimatedConductivity,
                    anions: Anionsinput,
                    cations: newArrycations,
                    neutrals: newArryneutrals,
                  },
                ]),
              );
              const CalculateFeedWaterDataJson = {
                Method: "masterdata/api/v1/CalculateFeedWaterData",
                userID: userID,
                projectID: projectid,
                caseID,
                typeFlag: 0,
                feedStream: {
                  designTemp: newStreamData[0].tempDesign,
                  methodname: "normal",
                  ph: FeedWaterDetails[0].ph,
                  ph25: FeedWaterDetails[0].ph25,
                  TotalDissolvedSolutes: 0.0,
                  TotalDissolvedSolids: 0.0,
                  ChargeBalance: 0.0,
                  EstimatedConductivity: 0.0,
                  Degas: 0.0,
                  percentage_of_initial_total_CO2_remaining: 100.0,
                  Equilibrate_with: 0.0,
                  Adjustment_Type: 0.0,
                  Add_Reagent: 0.0,
                  Total_CO2: 0.0,
                  cations: newArrycations,
                  anions: newArryanions,
                  neutrals: newArryneutrals,
                },
              };
              if (newinputmgL !== e && newinputmeqL !== e && newinputppm !== e) {
                CalengneAPIcall(CalculateFeedWaterDataJson);
              }
              return newArryanions;
            });
        } else if (title === "neutrals") {
          let indexget = 0;
          const neutralsinput = [...FeedWaterDetails[0].neutrals];
          FeedWaterDetails[0].neutrals
            .filter(item => item.name === name)
            .map((u, index) => {
              const newinputmgL = Number.parseFloat(u.mgL).toFixed(3);
              if (subtitle == "mgL") {
                const num = Number.parseFloat(e).toFixed(3);
                u = { ...u, mgL: num, meqL: 0, ppm: 0 };

                indexget = FeedWaterDetails[0].neutrals.findIndex(i => i.name === name || i.mgL === subtitle);
                const Inputvalues = { ...u, mgL: num, meqL: 0, ppm: 0 };
                neutralsinput[indexget] = Inputvalues;
              }
              const newArrycations = [...FeedWaterDetails[0].cations]; // copying the old datas array
              const newArryanions = [...FeedWaterDetails[0].anions];
              const newArryneutrals = [...FeedWaterDetails[0].neutrals];
              dispatch(
                Feedsetupdetailsdata([
                  {
                    ...StoreData,
                    ph: FeedWaterDetails[0].ph,
                    ph25: FeedWaterDetails[0].ph25,
                    totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
                    totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
                    chargeBalance: FeedWaterDetails[0].chargeBalance,
                    estimatedConductivity: FeedWaterDetails[0].estimatedConductivity,
                    anions: newArryanions,
                    cations: newArrycations,
                    neutrals: neutralsinput,
                  },
                ]),
              );

              newArryneutrals[indexget] = u; // replace e.target.value with whatever you want to change it to
              const CalculateFeedWaterDataJson = {
                Method: "masterdata/api/v1/CalculateFeedWaterData",
                userID: userID,
                projectID: projectid,
                caseID,
                typeFlag: 0,
                feedStream: {
                  designTemp: newStreamData[0].tempDesign,
                  methodname: "normal",
                  ph: FeedWaterDetails[0].ph,
                  ph25: FeedWaterDetails[0].ph25,
                  TotalDissolvedSolutes: 0.0,
                  TotalDissolvedSolids: 0.0,
                  ChargeBalance: 0.0,
                  EstimatedConductivity: 0.0,
                  Degas: 0.0,
                  percentage_of_initial_total_CO2_remaining: 100.0,
                  Equilibrate_with: 0.0,
                  Adjustment_Type: 0.0,
                  Add_Reagent: 0.0,
                  Total_CO2: 0.0,
                  cations: newArrycations,
                  anions: newArryanions,
                  neutrals: newArryneutrals,
                },
              };
              if (newinputmgL !== e) {
                CalengneAPIcall(CalculateFeedWaterDataJson);
              }
              return newArryneutrals;
            });
        } else if (title === "inputPH") {
          const newArrycations = [...FeedWaterDetails[0].cations]; // copying the old datas array
          const newArryanions = [...FeedWaterDetails[0].anions];
          const newArryneutrals = [...FeedWaterDetails[0].neutrals];
          dispatch(
            Feedsetupdetailsdata([
              {
                ...StoreData,
                ph: e,
                ph25: FeedWaterDetails[0].ph25,
                totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
                totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
                chargeBalance: FeedWaterDetails[0].chargeBalance,
                estimatedConductivity: FeedWaterDetails[0].estimatedConductivity,
                anions: newArryanions,
                cations: newArrycations,
                neutrals: newArryneutrals,
              },
            ]),
          );
          const CalculateFeedWaterDataJson = {
            Method: "masterdata/api/v1/CalculateFeedWaterData",
            userID: userID,
            projectID: projectid,
            caseID,
            typeFlag: 0,
            feedStream: {
              designTemp: newStreamData[0].tempDesign,
              methodname: "normal",
              ph: e,
              ph25: FeedWaterDetails[0].ph25,
              TotalDissolvedSolutes: 0.0,
              TotalDissolvedSolids: 0.0,
              ChargeBalance: 0.0,
              EstimatedConductivity: 0.0,
              Degas: 0.0,
              percentage_of_initial_total_CO2_remaining: 100.0,
              Equilibrate_with: 0.0,
              Adjustment_Type: 0.0,
              Add_Reagent: 0.0,
              Total_CO2: 0.0,
              cations: newArrycations,
              anions: newArryanions,
              neutrals: newArryneutrals,
            },
          };

          CalengneAPIcall(CalculateFeedWaterDataJson, newStreamData);
        }
      } else {
        const warningMessage = " Please enter a positive values !!";
        handleShowAlert("warning", warningMessage);
      }
      document.body.classList.remove("disable");
    }
  };

  const onchangevalusCo2 = (tital, subtitle, name, e) => {
    let duplicateCo2flag = "";

    const newArrycations = [...FeedWaterDetails[0].cations]; // copying the old datas array
    const newArryanions = [...FeedWaterDetails[0].anions];
    const newArryneutrals = [...FeedWaterDetails[0].neutrals];

    const Co2mgL = FeedWaterDetails[0].neutrals
      .filter(item => item.name === name)
      .map((u, index) => {
        u = u.mgL;
        return u;
      });

    const co2mglinput = Number.parseFloat(Co2mgL[0]).toFixed(3);

    if (Co2flag == false) {
      if (co2mglinput >= 0) {
        duplicateCo2flag = "true";
      } else {
        duplicateCo2flag = "false";
      }
    }
    if (e == 0) {
      const num = Number.parseFloat(0.00000001).toFixed(8);
      e = num;
    }

    let indexget = 0;
    const neutralsinput = [...FeedWaterDetails[0].neutrals];
    let newinputmgL = 0;
    if (duplicateCo2flag == "true" || Co2flag == true) {
      setCo2flag(true);
      indexget = FeedWaterDetails[0].neutrals.findIndex(i => i.name === name || i.mgL === subtitle);

      newArryneutrals?.map((u, i) => {
        if (u.name == "CO₂") {
          newinputmgL = Number.parseFloat(u.mgL).toFixed(3);
          const newinputmeqL = Number.parseFloat(u.meqL).toFixed(3);
          const newinputppm = Number.parseFloat(u.ppm).toFixed(3);
          // let num = Number.parseFloat(e).toFixed(3);
          u = { ...u, mgL: e, meqL: 0, ppm: 0 };
          newArryneutrals[indexget] = u;
          const Inputvalues = { ...u, mgL: e, meqL: newinputmeqL, ppm: newinputppm };
          neutralsinput[indexget] = Inputvalues;
        }
        return u;
      });
    }

    const Anionsinput = [...FeedWaterDetails[0].anions];
    if (Co3flag == false) {
      newArryanions?.map(u => {
        if (u.name == "CO₃") {
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === "CO₃");
          u = { ...u, mgL: 0, meqL: 0, ppm: 0 };
          newArryanions[indexget] = u;
        }
        return u;
      });
    }

    if (HCo3flag == false) {
      newArryanions?.map(u => {
        if (u.name == "HCO₃") {
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === "HCO₃");
          u = { ...u, mgL: 0, meqL: 0, ppm: 0 };
          newArryanions[indexget] = u;
        }
        return u;
      });
    }

    dispatch(
      Feedsetupdetailsdata([
        {
          ...StoreData,
          ph: FeedWaterDetails[0].ph,
          ph25: FeedWaterDetails[0].ph25,
          totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
          totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
          chargeBalance: FeedWaterDetails[0].chargeBalance,
          estimatedConductivity: FeedWaterDetails[0].estimatedConductivity,
          anions: Anionsinput,
          cations: newArrycations,
          neutrals: neutralsinput,
        },
      ]),
    );
    const CalculateFeedWaterDataJson = {
      Method: "masterdata/api/v1/CalculateFeedWaterData",
      userID: userID,
      projectID: projectid,
      caseID,
      typeFlag: 0,
      feedStream: {
        designTemp: Stremdata[0].tempDesign,
        methodname: "normal",
        ph: FeedWaterDetails[0].ph,
        ph25: FeedWaterDetails[0].ph25,
        TotalDissolvedSolutes: 0.0,
        TotalDissolvedSolids: 0.0,
        ChargeBalance: 0.0,
        EstimatedConductivity: 0.0,
        Degas: 0.0,
        percentage_of_initial_total_CO2_remaining: 100.0,
        Equilibrate_with: 0.0,
        Adjustment_Type: 0.0,
        Add_Reagent: 0.0,
        Total_CO2: 0.0,
        cations: newArrycations,
        anions: newArryanions,
        neutrals: newArryneutrals,
      },
    };
    if (newinputmgL !== e) {
      CalengneAPIcall(CalculateFeedWaterDataJson);
    }
  };

  const onchangevalusCo3 = (tital, subtitle, name, e) => {
    const newArrycations = [...FeedWaterDetails[0].cations]; // copying the old datas array
    const newArryanions = [...FeedWaterDetails[0].anions];
    const newArryneutrals = [...FeedWaterDetails[0].neutrals];

    if (e == 0) {
      const num = Number.parseFloat(0.00000001).toFixed(8);
      e = num;
    } else {
      const num = Number.parseFloat(e).toFixed(3);
      e = num;
    }
    let indexget = 0;
    const Anionsinput = [...FeedWaterDetails[0].anions];
    let newinputmgL = 0;
    let newinputmeqL = 0;
    let newinputppm = 0;

    newArryanions?.map(u => {
      if (u.name == "CO₃") {
        newinputmgL = Number.parseFloat(u.mgL).toFixed(3);
        newinputmeqL = Number.parseFloat(u.meqL).toFixed(3);
        newinputppm = Number.parseFloat(u.ppm).toFixed(3);

        if (subtitle == "mgL") {
          // const num = Number.parseFloat(e).toFixed(3);
          u = { ...u, mgL: e, meqL: 0, ppm: 0 };
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.mgL === subtitle);
          const Inputvalues = { ...u, mgL: e, meqL: newinputmeqL, ppm: newinputppm };
          Anionsinput[indexget] = Inputvalues;
        } else if (subtitle == "meqL") {
          // const num = Number.parseFloat(e).toFixed(3);
          u = { ...u, meqL: e, mgL: 0, ppm: 0 };
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.meqL === subtitle);
          const Inputvalues = { ...u, meqL: e, mgL: newinputmgL, ppm: newinputppm };
          Anionsinput[indexget] = Inputvalues;
        } else if (subtitle == "ppm") {
          // const num = Number.parseFloat(e).toFixed(3);
          u = { ...u, ppm: e, mgL: 0, meqL: 0 };
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.ppm === subtitle);
          const Inputvalues = { ...u, ppm: e, meqL: newinputmeqL, mgL: newinputmgL };
          Anionsinput[indexget] = Inputvalues;
        }
        newArryanions[indexget] = u;
      }

      return u;
    });

    const neutralsinput = FeedWaterDetails[0].neutrals;
    if (Co2flag == false) {
      FeedWaterDetails[0].neutrals?.map((u, i) => {
        if (u.name == "CO₂") {
          indexget = FeedWaterDetails[0].neutrals.findIndex(i => i.name === "CO₂");
          u = { ...u, mgL: 0, meqL: 0, ppm: 0 };
          newArryneutrals[indexget] = u;
        }
      });
    }

    if (HCo3flag == false) {
      FeedWaterDetails[0].anions?.map((u, i) => {
        if (u.name == "HCO₃") {
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === "HCO₃");
          u = { ...u, mgL: 0, meqL: 0, ppm: 0 };
          newArryanions[indexget] = u;
        }
        return u;
      });
    }

    dispatch(
      Feedsetupdetailsdata([
        {
          ...StoreData,
          ph: FeedWaterDetails[0].ph,
          ph25: FeedWaterDetails[0].ph25,
          totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
          totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
          chargeBalance: FeedWaterDetails[0].chargeBalance,
          estimatedConductivity: FeedWaterDetails[0].estimatedConductivity,
          anions: Anionsinput,
          cations: newArrycations,
          neutrals: neutralsinput,
        },
      ]),
    );
    setCo3flag(true);

    const CalculateFeedWaterDataJson = {
      Method: "masterdata/api/v1/CalculateFeedWaterData",
      userID: userID,
      projectID: projectid,
      caseID,
      typeFlag: 0,
      feedStream: {
        designTemp: Stremdata[0].tempDesign,
        methodname: "normal",
        ph: FeedWaterDetails[0].ph,
        ph25: FeedWaterDetails[0].ph25,
        TotalDissolvedSolutes: 0.0,
        TotalDissolvedSolids: 0.0,
        ChargeBalance: 0.0,
        EstimatedConductivity: 0.0,
        Degas: 0.0,
        percentage_of_initial_total_CO2_remaining: 100.0,
        Equilibrate_with: 0.0,
        Adjustment_Type: 0.0,
        Add_Reagent: 0.0,
        Total_CO2: 0.0,
        cations: newArrycations,
        anions: newArryanions,
        neutrals: newArryneutrals,
      },
    };
    if (newinputmgL !== e && newinputmeqL !== e && newinputppm !== e) {
      CalengneAPIcall(CalculateFeedWaterDataJson);
    }
  };

  const onchangevalusHCo3 = (tital, subtitle, name, e) => {
    const newArrycations = [...FeedWaterDetails[0].cations]; // copying the old datas array
    const newArryanions = [...FeedWaterDetails[0].anions];
    const newArryneutrals = [...FeedWaterDetails[0].neutrals];

    let indexget = 0;
    if (e == 0) {
      const num = Number.parseFloat(0.00000001).toFixed(8);
      e = num;
    } else {
      const num = Number.parseFloat(e).toFixed(3);
      e = num;
    }

    const Anionsinput = [...FeedWaterDetails[0].anions];
    let newinputmgL = 0;
    let newinputmeqL = 0;
    let newinputppm = 0;
    newArryanions?.map(u => {
      if (u.name == "HCO₃") {
        newinputmgL = Number.parseFloat(u.mgL).toFixed(3);
        newinputmeqL = Number.parseFloat(u.meqL).toFixed(3);
        newinputppm = Number.parseFloat(u.ppm).toFixed(3);
        if (subtitle == "mgL") {
          u = { ...u, mgL: e, meqL: 0, ppm: 0 };
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.mgL === subtitle);
          const Inputvalues = { ...u, mgL: e, meqL: newinputmeqL, ppm: newinputppm };
          Anionsinput[indexget] = Inputvalues;
        } else if (subtitle == "meqL") {
          u = { ...u, meqL: e, mgL: 0, ppm: 0 };
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.meqL === subtitle);
          const Inputvalues = { ...u, meqL: e, mgL: newinputmgL, ppm: newinputppm };
          Anionsinput[indexget] = Inputvalues;
        } else if (subtitle == "ppm") {
          u = { ...u, ppm: e, mgL: 0, meqL: 0 };
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === name || i.ppm === subtitle);
          const Inputvalues = { ...u, ppm: e, mgL: newinputmgL, meqL: newinputmeqL };
          Anionsinput[indexget] = Inputvalues;
        }
        newArryanions[indexget] = u;
      }
      return u;
    });

    const neutralsinput = FeedWaterDetails[0].neutrals;
    if (Co2flag == false) {
      newArryneutrals?.map(u => {
        if (u.name == "CO₂") {
          indexget = FeedWaterDetails[0].neutrals.findIndex(i => i.name === "CO₂");
          u = { ...u, mgL: 0, meqL: 0, ppm: 0 };
          newArryneutrals[indexget] = u;
        }
      });
    }

    if (Co3flag == false) {
      newArryanions?.map(u => {
        if (u.name == "CO₃") {
          indexget = FeedWaterDetails[0].anions.findIndex(i => i.name === "CO₃");
          u = { ...u, mgL: 0, meqL: 0, ppm: 0 };
          newArryanions[indexget] = u;
        }
        return u;
      });
    }

    dispatch(
      Feedsetupdetailsdata([
        {
          ...StoreData,
          ph: FeedWaterDetails[0].ph,
          ph25: FeedWaterDetails[0].ph25,
          totalDissolvedSolutes: FeedWaterDetails[0].totalDissolvedSolutes,
          totalDissolvedSolids: FeedWaterDetails[0].totalDissolvedSolids,
          chargeBalance: FeedWaterDetails[0].chargeBalance,
          estimatedConductivity: FeedWaterDetails[0].estimatedConductivity,
          anions: Anionsinput,
          cations: newArrycations,
          neutrals: neutralsinput,
        },
      ]),
    );

    setHCo3flag(true);

    const CalculateFeedWaterDataJson = {
      Method: "masterdata/api/v1/CalculateFeedWaterData",
      userID: userID,
      projectID: projectid,
      caseID,
      typeFlag: 0,
      feedStream: {
        designTemp: Stremdata[0].tempDesign,
        methodname: "normal",
        ph: FeedWaterDetails[0].ph,
        ph25: FeedWaterDetails[0].ph25,
        TotalDissolvedSolutes: 0.0,
        TotalDissolvedSolids: 0.0,
        ChargeBalance: 0.0,
        EstimatedConductivity: 0.0,
        Degas: 0.0,
        percentage_of_initial_total_CO2_remaining: 100.0,
        Equilibrate_with: 0.0,
        Adjustment_Type: 0.0,
        Add_Reagent: 0.0,
        Total_CO2: 0.0,
        cations: newArrycations,
        anions: newArryanions,
        neutrals: newArryneutrals,
      },
    };
    if (newinputmgL !== e && newinputmeqL !== e && newinputppm !== e) {
      CalengneAPIcall(CalculateFeedWaterDataJson);
    }
  };

  const [TurbidityUFIXisError, setTurbidityUFIXisError] = useState(false);
  const [TurbidityUFIXisWarning, setTurbidityUFIXisWarning] = useState(false);
  const [TSSUFIXisError, setTSSUFIXisError] = useState(false);
  const [TSSUFIXisWarning, setTSSUFIXisWarning] = useState(false);
  const [SDIUFIXisError, setSDIUFIXisError] = useState(false);
  const [SDIUFIXisWarning, setSDIUFIXisWarning] = useState(false);
  const [TOCUFIXisError, setTOCUFIXisError] = useState(false);
  const [TOCUFIXisWarning, setTOCUFIXisWarning] = useState(false);
  const [PHisError, setPHisError] = useState(false);
  const [PHisWarning, setPHisWarning] = useState(false);

  //turbutit
  const getData = feedwaterData => {
    if (isIXTechnology(projectTechnology)) {
      return {
        waterSubTypeId: 0,
        description: 0,
        ...feedwaterData,
      };
    } else {
      for (let i = 0; i < subwaterType.length; i++) {
        let str = subwaterType[i].description;
        str = str.replaceAll("≥", ">=");
        str = str.replaceAll("≤", "<=");
        str = str.replaceAll("<", "<");
        str = str.replaceAll(",", "&&");
        str = str.replaceAll("NTU", Number(feedwaterData.NTU));
        str = str.replaceAll("TSS", Number(feedwaterData.TSS));
        str = str.replaceAll("TOC", Number(feedwaterData.TOC));
        if (eval(str)) {
          if (feedwaterData.TSS == 0) {
            feedwaterData.TSS = feedwaterData.NTU * 2;
          }
          return {
            waterSubTypeId: subwaterType[i].waterSubTypeId,
            description: subwaterType[i].description,
            ...feedwaterData,
          };
        }
      }
      return {
        waterSubTypeId: subwaterType[subwaterType.length - 1].waterSubTypeId,
        description: subwaterType[subwaterType.length - 1].description,
        ...feedwaterData,
        TSS: feedwaterData.TSS == 0 ? feedwaterData.NTU * 2 : feedwaterData.TSS,
      };
    }
  };

  const textchange = (textname, e, type) => {
    dispatch(detectDataChange(true));
    let TOCValuesSoft40 = 0;
    let TOCValuesSoft41 = 0;
    let TOCValuesHard101 = 0;

    if (unit.selectedUnits[19] === "mg/L KMnO₄") {
      TOCValuesSoft40 = 200;
      TOCValuesSoft41 = 201;
      TOCValuesHard101 = 501;
    } else if (unit.selectedUnits[19] === "mg/L TOC") {
      TOCValuesSoft40 = 40;
      TOCValuesSoft41 = 41;
      TOCValuesHard101 = 101;
    }

    setIsDesignTempChanged(true);

    if (textname === "Turbidity") {
      if (isProjectTechnologyValid) {
        if (e >= 0 && e < 301) {
          setTurbidityUFIXisWarning(false);
          setTurbidityUFIXisError(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: e, TSS: Stremdata[0]?.tss, TOC: Stremdata[0]?.toc });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else if (e > 300 && e < 1001) {
          setTurbidityUFIXisWarning(true);
          setTurbidityUFIXisError(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: e, TSS: Stremdata[0]?.tss, TOC: Stremdata[0]?.toc });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else {
          setTurbidityUFIXisError(true);
          setTurbidityUFIXisWarning(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: i.turbidity };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);

            setTurbidityUFIXisWarning(false);
            setTurbidityUFIXisError(false);
          }
        }
      }
    } else if (textname === "TSS") {
      if (projectTechnology === "UF" || isIXTechnology(projectTechnology) || projectTechnology === "null") {
        if (e == "") {
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: Stremdata[0]?.turbidity, TSS: 0, TOC: Stremdata[0]?.toc });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else if (e >= 0 && e < 101) {
          setTSSUFIXisError(false);
          setTSSUFIXisWarning(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: Stremdata[0]?.turbidity, TSS: e, TOC: Stremdata[0]?.toc });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else if (e > 100 && e < 1001) {
          setTSSUFIXisWarning(true);
          setTSSUFIXisError(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: Stremdata[0]?.turbidity, TSS: e, TOC: Stremdata[0]?.toc });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else {
          setTSSUFIXisError(true);
          setTSSUFIXisWarning(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, tss: i.tss };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
            setTSSUFIXisWarning(false);
            setTSSUFIXisError(false);
          }
        }
      }
    } else if (textname === "SDI") {
      if (projectTechnology === "UF" || isIXTechnology(projectTechnology) || projectTechnology === "null") {
        if (e >= 0 && e <= 6.0) {
          setSDIUFIXisError(false);
          setSDIUFIXisWarning(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, sdi: e };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else if (e > 6.0 && e <= 6.6) {
          setSDIUFIXisWarning(true);
          setSDIUFIXisError(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, sdi: e };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else {
          setSDIUFIXisError(true);
          setSDIUFIXisWarning(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, sdi: i.sdi };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
            setSDIUFIXisWarning(false);
            setSDIUFIXisError(false);
          }
        }
      }
    } else if (textname === "TOC") {
      if (projectTechnology === "UF" || isIXTechnology(projectTechnology) || projectTechnology === "null") {
        if (e == "") {
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: Stremdata[0]?.turbidity, TSS: Stremdata[0]?.tss, TOC: 0 });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else if (parseFloat(e) >= 0 && parseFloat(e) < parseFloat(TOCValuesSoft41)) {
          setTOCUFIXisWarning(false);
          setTOCUFIXisError(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: Stremdata[0]?.turbidity, TSS: Stremdata[0]?.tss, TOC: e });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else if (parseFloat(e) > parseFloat(TOCValuesSoft40) && parseFloat(e) < parseFloat(TOCValuesHard101)) {
          setTOCUFIXisWarning(true);
          setTOCUFIXisError(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const Data = getData({ NTU: Stremdata[0]?.turbidity, TSS: Stremdata[0]?.tss, TOC: e });
            handleWaterSubTypeChange({ target: { value: Data.waterSubTypeId } });
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, turbidity: Data.NTU, tss: Data.TSS, toc: Data.TOC, waterSubTypeID: Data.waterSubTypeId };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
          }
        } else {
          setTOCUFIXisError(true);
          setTOCUFIXisWarning(false);
          if (type === "TextonBlur") {
            dispatch(updateLoader(true));
            const StreamDetails = Stremdata?.map(i => {
              i = { ...i, toc: i.toc };
              return i;
            });
            setStremdata(StreamDetails);
            setTimeout(() => {
              dispatch(updateLoader(false));
            }, 5);
            setTOCUFIXisWarning(false);
            setTOCUFIXisError(false);
          }
        }
      }
    } else if (textname === "PH") {
      if (e == "") {
        if (type === "TextonBlur") {
          dispatch(updateLoader(true));
          const StreamDetails = Stremdata?.map(i => {
            i = { ...i, ph: 0.0 };
            return i;
          });
          setStremdata(StreamDetails);
          setTimeout(() => {
            dispatch(updateLoader(false));
          }, 5);
        }
      } else if (e >= 0 && e < 14) {
        setPHisError(false);
        setPHisWarning(false);
        if (type === "TextonBlur") {
          dispatch(updateLoader(true));
          onchangevalus("inputPH", "ph", "Ph", e);
          setTimeout(() => {
            dispatch(updateLoader(false));
          }, 5);
        }
      } else if (e > 13.9 && e < 14.1) {
        setPHisError(false);
        setPHisWarning(true);
        if (type === "TextonBlur") {
          dispatch(updateLoader(true));
          onchangevalus("inputPH", "ph", "Ph", e);
          setTimeout(() => {
            dispatch(updateLoader(false));
          }, 5);
        }
      } else {
        setPHisError(true);
        setPHisWarning(false);
        if (type === "TextonBlur") {
          dispatch(updateLoader(true));
          onchangevalus("inputPH", "ph", "Ph", FeedWaterDetails[0].ph);
          setTimeout(() => {
            dispatch(updateLoader(false));
          }, 5);
          setPHisWarning(false);
          setPHisError(false);
        }
      }
    } else if (textname === "txtArea") {
      if (type === "TextonBlur") {
        dispatch(updateLoader(true));
        const StreamDetails = Stremdata?.map(i => {
          i = { ...i, additionalFeedWaterInfo: e };
          return i;
        });
        setStremdata(StreamDetails);
        setTimeout(() => {
          dispatch(updateLoader(false));
        }, 5);
      }
    }
  };

  const inputRefB = useRef(null);
  const inputRefCO2 = useRef(null);
  const inputRefSIO = useRef(null);
  const inputRefSO4meqL = useRef(null);
  const inputRefSO4mgL = useRef(null);
  const inputRefSO4ppm = useRef(null);
  const inputRefPO4meqL = useRef(null);
  const inputRefPO4mgL = useRef(null);
  const inputRefPO4ppm = useRef(null);
  const inputRefNO3meqL = useRef(null);
  const inputRefNO3mgL = useRef(null);
  const inputRefNO3ppm = useRef(null);
  const inputRefHCO3meqL = useRef(null);
  const inputRefHCO3mgL = useRef(null);
  const inputRefHCO3ppm = useRef(null);
  const inputRefFmeqL = useRef(null);
  const inputRefFmgL = useRef(null);
  const inputRefFppm = useRef(null);
  const inputRefCO3meqL = useRef(null);
  const inputRefCO3mgL = useRef(null);
  const inputRefCO3ppm = useRef(null);
  const inputRefClmeqL = useRef(null);
  const inputRefClmgL = useRef(null);
  const inputRefClppm = useRef(null);
  const inputRefBRmeqL = useRef(null);
  const inputRefBRmgL = useRef(null);
  const inputRefBRppm = useRef(null);
  const inputRefBAmeqL = useRef(null);
  const inputRefBAmgL = useRef(null);
  const inputRefBAppm = useRef(null);
  const inputRefCAmeqL = useRef(null);
  const inputRefCAmgL = useRef(null);
  const inputRefCAppm = useRef(null);
  const inputRefNAmeqL = useRef(null);
  const inputRefNAmgL = useRef(null);
  const inputRefNAppm = useRef(null);
  const inputRefKmeqL = useRef(null);
  const inputRefKmgL = useRef(null);
  const inputRefKppm = useRef(null);
  const inputRefMGmeqL = useRef(null);
  const inputRefMGmgL = useRef(null);
  const inputRefMGppm = useRef(null);
  const inputRefNH4meqL = useRef(null);
  const inputRefNH4mgL = useRef(null);
  const inputRefNH4ppm = useRef(null);
  const inputRefSRmeqL = useRef(null);
  const inputRefSRmgL = useRef(null);
  const inputRefSRppm = useRef(null);

  const onCancelClick = (e, title, subtitle, name) => {
    dispatch(updateLoader(true));
    setCancelFlag(true);
    if (e == "inputRefB") {
      inputRefB.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefCO2") {
      inputRefCO2.current.value = 0;
      onchangevalusCo2(title, subtitle, name, 0);
    } else if (e == "inputRefSIO") {
      inputRefSIO.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefBAmeqL") {
      inputRefBAmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefBAmgL") {
      inputRefBAmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefBAppm") {
      inputRefBAppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefCAmeqL") {
      inputRefCAmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefCAmgL") {
      inputRefCAmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefCAppm") {
      inputRefCAppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNAmeqL") {
      inputRefNAmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNAmgL") {
      inputRefNAmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNAppm") {
      inputRefNAppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefKmeqL") {
      inputRefKmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefKmgL") {
      inputRefKmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefKppm") {
      inputRefKppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefMGmeqL") {
      inputRefMGmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefMGmgL") {
      inputRefMGmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefMGppm") {
      inputRefMGppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNH4meqL") {
      inputRefNH4meqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNH4mgL") {
      inputRefNH4mgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNH4ppm") {
      inputRefNH4ppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefSRmeqL") {
      inputRefSRmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefSRmgL") {
      inputRefSRmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefSRppm") {
      inputRefSRppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefBRmeqL") {
      inputRefBRmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefBRmgL") {
      inputRefBRmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefBRppm") {
      inputRefBRppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefClmeqL") {
      inputRefClmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefClmgL") {
      inputRefClmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefClppm") {
      inputRefClppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefCO3meqL") {
      inputRefCO3meqL.current.value = 0;
      onchangevalusCo3(title, subtitle, name, 0);
    } else if (e == "inputRefCO3mgL") {
      inputRefCO3mgL.current.value = 0;
      onchangevalusCo3(title, subtitle, name, 0);
    } else if (e == "inputRefCO3ppm") {
      inputRefCO3ppm.current.value = 0;
      onchangevalusCo3(title, subtitle, name, 0);
    } else if (e == "inputRefFmeqL") {
      inputRefFmeqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefFmgL") {
      inputRefFmgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefFppm") {
      inputRefFppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefHCO3meqL") {
      inputRefHCO3meqL.current.value = 0;
      onchangevalusHCo3(title, subtitle, name, 0);
    } else if (e == "inputRefHCO3mgL") {
      inputRefHCO3mgL.current.value = 0;
      onchangevalusHCo3(title, subtitle, name, 0);
    } else if (e == "inputRefHCO3ppm") {
      inputRefHCO3ppm.current.value = 0;
      onchangevalusHCo3(title, subtitle, name, 0);
    } else if (e == "inputRefSO4meqL") {
      inputRefSO4meqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefSO4mgL") {
      inputRefSO4mgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefSO4ppm") {
      inputRefSO4ppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefPO4meqL") {
      inputRefPO4meqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefPO4mgL") {
      inputRefPO4mgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefPO4ppm") {
      inputRefPO4ppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNO3meqL") {
      inputRefNO3meqL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNO3mgL") {
      inputRefNO3mgL.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    } else if (e == "inputRefNO3ppm") {
      inputRefNO3ppm.current.value = 0.0;
      onchangevalus(title, subtitle, name, 0.0);
    }
    setCancelFlag(false);
    setTimeout(() => {
      dispatch(updateLoader(false));
    }, 5);
  };
  const handleFocus = id => {
    setIsFocused(id);
  };

  const handleKeyPressfeedsetup = (e, text) => {
    if (e.key === "Enter") {
      let nextRowInput;
      if (text == "Turbidity") {
        nextRowInput = document.getElementById("TSS");
      } else if (text == "TSS") {
        nextRowInput = document.getElementById("SDI");
        if (e.target.value == "") {
          textchange("TSS", e.target.value, "Text");
        }
      } else if (text == "SDI") {
        nextRowInput = document.getElementById("TOC");
      } else if (text == "TOC") {
        nextRowInput = document.getElementById("Minimum");
        if (e.target.value == "") {
          textchange("TOC", e.target.value, "Text");
        }
      } else if (text == "Minimum") {
        nextRowInput = document.getElementById("Design");
      } else if (text == "Design") {
        nextRowInput = document.getElementById("Maximum");
      } else if (text == "Maximum") {
        nextRowInput = document.getElementById("PH");
      } else if (text == "PH") {
        nextRowInput = document.getElementById("input-1-1");
      } else if (text == "neutrals-3-1") {
        nextRowInput = document.getElementById("txtArea");
      } else {
        nextRowInput = document.getElementById(text);
      }
      if (nextRowInput) {
        nextRowInput.focus();
        nextRowInput.select();
      }
    }
  };
  const handleKeyPress = (e, rowIndex, colIndex, type) => {
    if (e.key === "Enter") {
      const nextRowIndex = rowIndex + 1;
      let nextRowInput;
      if (type == "a") {
        nextRowInput = document.getElementById(`anion-${nextRowIndex}-${colIndex}`);
      } else if (type == "n") {
        nextRowInput = document.getElementById(`neutrals-${nextRowIndex}-${colIndex}`);
      } else {
        nextRowInput = document.getElementById(`input-${nextRowIndex}-${colIndex}`);
      }
      if (nextRowInput) {
        nextRowInput.focus();
        nextRowInput.select();
      }
    }
  };

  const handleBlur = event => {
    const { id, value } = event.target;
    setIsFocused(null);
    textchange(id, value, "TextonBlur");
  };

  const handleKeyDown = evt => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault();
  const handleOnWheel = e => e.target.blur();

  const handleTempChange = data => {
    const { temperatureData, isDesignTempUpdated } = data;
    const newStreamData = Stremdata?.map(strem => ({
      ...strem,
      tempMin: +temperatureData.Minimum,
      tempDesign: +temperatureData.Design,
      tempMax: +temperatureData.Maximum,
    }));
    setStremdata(newStreamData);
    if (isDesignTempUpdated) {
      dispatch(updateLoader(true));
      const phValue = FeedWaterDetails[0]?.ph;
      setDesignTemperatureForUF(temperatureData.Design);
      setIsDesignTempChanged(true);
      onchangevalus("inputPH", "ph", "Ph", phValue, newStreamData);
    }
  };

  const getPhTempLabel = designTemp => {
    if (unit.selectedUnits[2] !== UNITS.celsius) {
      designTemp = unitConversionByName(designTemp, unit.selectedUnits[2], UNITS.celsius);
    }
    return "pH @" + Number.parseFloat(designTemp).toFixed(1) + unit.selectedUnits[2];
  };

  return (
    <DynamicLoadder isLoading={isDataLoading}>
      <>
        {Stremdata &&
          FeedWaterDetails[0] &&
          Stremdata?.map((streamValue, index) => (
            <FormEntryStyled className='form-entry-row' key={index}>
              <div className='feed-parameter-solid-content-temp-wrapper'>
                <div className='form-entry-column'>
                  <StyledCard className='feed-parameters'>
                    <Card.Header className='form-heading'>
                      <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Feed Parameters' />
                      <IconWithTooltip
                        label='UF & RO: Water Type and Subtype determine the design guidelines, default values, & warning limits.'
                        icon={<InfoIcon />}
                      />
                    </Card.Header>
                    <div className='water-type'>
                      <CustomLabel label='Water Type' mandatoryIcon={!disabeldWaterType} />
                      <CustomSelect
                        name='ddwatertypeId'
                        value={disabeldWaterType ? "N/A" : waterTypeid}
                        onChange={handleWaterTypeChange}
                        disabled={disabeldWaterType}
                      >
                        {disabeldWaterType && <option>N/A</option>}
                        {waterType?.map((data, i) => (
                          <option key={i} value={data.waterTypeId}>
                            {data.waterSource}
                          </option>
                        ))}
                        ;
                      </CustomSelect>
                    </div>

                    <div className='water-sub-type'>
                      <CustomLabel label='Water Sub-Type' mandatoryIcon={!disabeldWaterType} />

                      <CustomSelect
                        name='ddwatertypeId'
                        onChange={handleWaterSubTypeChange}
                        value={disabeldWaterType ? "N/A" : subwaterTypeid}
                        disabled
                      >
                        {disabeldWaterType && <option>N/A</option>}
                        {subwaterType.length == 0 ? (
                          <option></option>
                        ) : (
                          subwaterType.map((data, i) => (
                            <option key={i} value={data.waterSubTypeId}>
                              {data.description}
                            </option>
                          ))
                        )}
                      </CustomSelect>
                    </div>
                  </StyledCard>
                </div>
                <div className='form-entry-column'>
                  <StyledCard className='solid-content'>
                    <Card.Header className='form-heading'>
                      <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Suspended Solids Content' />
                      <IconWithTooltip
                        label='Turbidity, TSS, TOC for UF; SDI15 for RO Water Subtype choice. IX uses TSS and TOC for warnings.'
                        icon={<InfoIcon />}
                      />
                    </Card.Header>
                    <div className='wrapper'>
                      <div className='turbidity'>
                        <CustomLabel label='Turbidity' />
                        <InputWithText
                          onKeyPress={e => handleKeyPressfeedsetup(e, "Turbidity")}
                          id='Turbidity'
                          type='number'
                          className='turbidity-input'
                          isError={TurbidityUFIXisError}
                          isWarning={TurbidityUFIXisWarning}
                          value={convertUpto2Digits(streamValue.turbidity)}
                          onWheel={handleOnWheel}
                          onKeyDown={handleKeyDown}
                          onChange={e => textchange("Turbidity", e.target.value, "Text")}
                          inputText='NTU'
                          onBlur={handleBlur}
                          onFocus={() => handleFocus(1)}
                          isFocused={isFocused === 1}
                        />
                        {TurbidityUFIXisError ? (
                          <ErrorMessage texMsg='Values out of range' />
                        ) : TurbidityUFIXisWarning ? (
                          <WarningMessage txtMsg='Warning Ranges Hard(0-1000)' />
                        ) : (
                          <InputReferenceText refText='Ranges 0-1000' />
                        )}
                      </div>
                      <div className=' tss'>
                        <CustomLabel label='TSS' />
                        <InputWithText
                          onKeyPress={e => handleKeyPressfeedsetup(e, "TSS")}
                          id='TSS'
                          isError={TSSUFIXisError}
                          isWarning={TSSUFIXisWarning}
                          inputText='mg/L'
                          type='number'
                          onWheel={handleOnWheel}
                          onKeyDown={handleKeyDown}
                          value={convertUpto2Digits(streamValue.tss)}
                          onChange={e => textchange("TSS", e.target.value, "Text")}
                          onBlur={handleBlur}
                          onFocus={() => handleFocus(2)}
                          isFocused={isFocused === 2}
                        />
                        {TSSUFIXisError ? (
                          <ErrorMessage texMsg='Values out of range' />
                        ) : TSSUFIXisWarning ? (
                          <WarningMessage txtMsg='Warning Ranges Hard(0-1000)' />
                        ) : (
                          <InputReferenceText refText='Ranges 0-1000' />
                        )}
                      </div>
                    </div>
                    <div className='sdi'>
                      <CustomLabel label='SDI₁₅' />
                      <InputWithText
                        onKeyPress={e => handleKeyPressfeedsetup(e, "SDI")}
                        id='SDI'
                        isError={SDIUFIXisError}
                        isWarning={SDIUFIXisWarning}
                        type='number'
                        onWheel={handleOnWheel}
                        onKeyDown={handleKeyDown}
                        value={convertUpto2Digits(streamValue.sdi)}
                        onChange={e => textchange("SDI", e.target.value, "Text")}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(3)}
                        isFocused={isFocused === 3}
                      />
                      {SDIUFIXisError ? (
                        <ErrorMessage texMsg='Values out of range' />
                      ) : SDIUFIXisWarning ? (
                        <WarningMessage txtMsg='Warning Ranges Hard(0.00-6.60)' />
                      ) : (
                        <InputReferenceText refText='Ranges 0.00-6.60' />
                      )}
                    </div>
                  </StyledCard>
                  <StyledCard className='organic-content'>
                    <Card.Header className='form-heading'>
                      <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Organic Content' />
                      <IconWithTooltip
                        label='Concentration of Natural Organic Matter in the water, removed by some IX and used for warnings.'
                        icon={<InfoIcon />}
                      />
                    </Card.Header>
                    <div className=''>
                      {unit.selectedUnits[19] === "mg/L KMnO₄" ? (
                        <CustomLabel label={"Organics (COD as KMnO₄)"} />
                      ) : (
                        <CustomLabel label='Organics (TOC)' />
                      )}

                      <InputWithText
                        onKeyPress={e => handleKeyPressfeedsetup(e, "TOC")}
                        id='TOC'
                        isError={TOCUFIXisError}
                        isWarning={TOCUFIXisWarning}
                        type='number'
                        inputText='mg/L'
                        onWheel={handleOnWheel}
                        onKeyDown={handleKeyDown}
                        value={convertUpto2Digits(streamValue.toc)}
                        onChange={e => textchange("TOC", e.target.value, "Text")}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(4)}
                        isFocused={isFocused === 4}
                      />
                      {TOCUFIXisError ? (
                        <ErrorMessage texMsg='Values out of range' />
                      ) : TOCUFIXisWarning ? (
                        unit.selectedUnits[19] === "mg/L KMnO₄" ? (
                          <WarningMessage txtMsg='Warning Ranges Hard(0-500)' />
                        ) : (
                          <WarningMessage txtMsg='Warning Ranges Hard(0-100)' />
                        )
                      ) : unit.selectedUnits[19] === "mg/L KMnO₄" ? (
                        <InputReferenceText refText='Ranges 0-500' />
                      ) : (
                        <InputReferenceText refText='Ranges 0-100' />
                      )}
                    </div>
                  </StyledCard>
                </div>
                <div className='form-entry-column temp_ph_wrapper'>
                  <StyledCard className='temperature'>
                    <Card.Header className='form-heading'>
                      <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Temperature' mandatoryIcon />
                      <IconWithTooltip
                        label='Min ≤ Design ≤ Max Temperature. Calculation done at Design, some warnings based on min & max.'
                        icon={<InfoIcon />}
                      />
                    </Card.Header>
                    <div className='wrapper'>
                      <FeedSetupTemperatures
                        Stremdata={Stremdata}
                        handleKeyPressfeedsetup={handleKeyPressfeedsetup}
                        handleTempChange={handleTempChange}
                      />
                    </div>
                  </StyledCard>
                  <StyledCard className='ph'>
                    <Card.Header className='form-heading'>
                      <CustomHeading color={colors.PrimaryDarkAquaMarine} label='pH' mandatoryIcon />
                      <IconWithTooltip
                        label='pH at both the Design Temp. & 25 °C; differ because of temperature dependent acid/base equilibrium.'
                        icon={<InfoIcon />}
                      />
                    </Card.Header>
                    <div className='wrapper'>
                      <div>
                        <CustomLabel mandatoryIcon label={getPhTempLabel(streamValue.tempDesign)} />
                        <InputWithText
                          id='PH'
                          type='number'
                          onWheel={handleOnWheel}
                          onKeyDown={handleKeyDown}
                          value={convertUpto2Digits(FeedWaterDetails[0].ph)}
                          onChange={e => textchange("PH", e.target.value, "Text")}
                          onBlur={handleBlur}
                          onFocus={() => handleFocus(20)}
                          isFocused={isFocused === 20}
                          isError={PHisError}
                          isWarning={PHisWarning}
                          onKeyPress={e => handleKeyPressfeedsetup(e, "PH")}
                          showCancelIcon
                          onCancelIconClick={() => {
                            onchangevalus("inputPH", "ph", "Ph", 0);
                          }}
                        />
                        {PHisError ? (
                          <ErrorMessage texMsg='Values out of range' />
                        ) : PHisWarning ? (
                          <WarningMessage txtMsg='Warning Ranges Hard(0-14)' />
                        ) : (
                          <InputReferenceText refText='Ranges 0-14' />
                        )}
                      </div>
                      <div>
                        <CustomLabel label={"pH @" + (unit.selectedUnits[2] == "°C" ? "25.0°C" : "77.0°F")} />
                        <CalcEngInputWithIcon
                          onKeyPress={e => handleKeyPressfeedsetup(e, "PH25")}
                          id='PH25'
                          isAutoPopulated
                          disabled
                          type='text'
                          value={Number.parseFloat(FeedWaterDetails[0].ph25).toFixed(2)}
                          onBlur={handleBlur}
                          onFocus={() => handleFocus(9)}
                          isFocused={isFocused === 9}
                        />
                      </div>
                    </div>
                  </StyledCard>
                </div>
              </div>
              <div className='cation-anion-neutrals-wrapper'>
                <StyledCard className='cations-card'>
                  <div className='cations-heading'>
                    <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Cations' mandatoryIcon />
                    <IconWithTooltip
                      label='Cation conc. in mg/L as ion, ppm as CaCO3, or meq/L; automatically convert between these units.'
                      icon={<InfoIcon />}
                    />
                  </div>
                  <Table className='cations-table'>
                    <thead className='table-header'>
                      <tr className='header-row'>
                        <th>
                          <CustomHeading label='Symbol' fontFamily='NotoSansSemiBold' />
                        </th>
                        <th>
                          <div className='icon-heading'>
                            <CustomHeading label='mg/L' fontFamily='NotoSansSemiBold' />
                          </div>
                        </th>
                        <th>
                          <div className='icon-heading'>
                            <CustomHeading label='ppm CaCO₃' fontFamily='NotoSansSemiBold' />
                          </div>
                        </th>
                        <th>
                          <div className='icon-heading'>
                            <CustomHeading label='meq/L' fontFamily='NotoSansSemiBold' />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {FeedWaterDetails &&
                        FeedWaterDetails?.map((cationsValue, index) => (
                          <>
                            <tr>
                              {cationsValue?.cations.length &&
                                cationsValue?.cations
                                  .filter(item => item.name === "NH₄")
                                  .map(item => (
                                    <>
                                      <th>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 22}
                                          onKeyPress={e => handleKeyPress(e, 1, 1)}
                                        >
                                          <FormInputBox
                                            id='input-1-1'
                                            inputRef={inputRefNH4mgL}
                                            name='name'
                                            value={item.mgL}
                                            onBlur={e => onchangevalus("cations", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(22)}
                                            isFocused={isFocused === 22}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNH4mgL", "cations", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 23}
                                          onKeyPress={e => handleKeyPress(e, 1, 2)}
                                        >
                                          <FormInputBox
                                            id='input-1-2'
                                            inputRef={inputRefNH4ppm}
                                            value={item.ppm}
                                            onBlur={e => onchangevalus("cations", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(23)}
                                            isFocused={isFocused === 23}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNH4ppm", "cations", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 24}
                                          onKeyPress={e => handleKeyPress(e, 1, 3)}
                                        >
                                          <FormInputBox
                                            id='input-1-3'
                                            inputRef={inputRefNH4meqL}
                                            value={item.meqL}
                                            onBlur={e => onchangevalus("cations", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(24)}
                                            isFocused={isFocused === 24}
                                          />
                                          <InputGroup.Text
                                            onClick={e =>
                                              onCancelClick("inputRefNH4meqL", "cations", "meqL", item.name)
                                            }
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>

                            <tr>
                              {cationsValue?.cations.length &&
                                cationsValue?.cations
                                  .filter(item => item.name === "Na")
                                  .map(item => (
                                    <>
                                      <th>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 25}
                                          onKeyPress={e => handleKeyPress(e, 2, 1)}
                                        >
                                          <FormInputBox
                                            id='input-2-1'
                                            inputRef={inputRefNAmgL}
                                            value={item.mgL}
                                            onBlur={e => onchangevalus("cations", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(25)}
                                            isFocused={isFocused === 25}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNAmgL", "cations", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 26}
                                          onKeyPress={e => handleKeyPress(e, 2, 2)}
                                        >
                                          <FormInputBox
                                            id='input-2-2'
                                            inputRef={inputRefNAppm}
                                            value={item.ppm}
                                            onBlur={e => onchangevalus("cations", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(26)}
                                            isFocused={isFocused === 26}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNAppm", "cations", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 27}
                                          onKeyPress={e => handleKeyPress(e, 2, 3)}
                                        >
                                          <FormInputBox
                                            id='input-2-3'
                                            inputRef={inputRefNAmeqL}
                                            value={item.meqL}
                                            onBlur={e => onchangevalus("cations", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(27)}
                                            isFocused={isFocused === 27}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNAmeqL", "cations", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr>
                              {cationsValue?.cations.length &&
                                cationsValue?.cations
                                  .filter(item => item.name === "K")
                                  .map(item => (
                                    <>
                                      <td>
                                        <CustomLabel label={item.name} key={index} />
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 28}
                                          onKeyPress={e => handleKeyPress(e, 3, 1)}
                                        >
                                          <FormInputBox
                                            id='input-3-1'
                                            inputRef={inputRefKmgL}
                                            value={item.mgL}
                                            onBlur={e => onchangevalus("cations", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(28)}
                                            isFocused={isFocused === 28}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefKmgL", "cations", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 29}
                                          onKeyPress={e => handleKeyPress(e, 3, 2)}
                                        >
                                          <FormInputBox
                                            id='input-3-2'
                                            inputRef={inputRefKppm}
                                            value={item.ppm}
                                            onBlur={e => onchangevalus("cations", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(29)}
                                            isFocused={isFocused === 29}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefKppm", "cations", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 30}
                                          onKeyPress={e => handleKeyPress(e, 3, 3)}
                                        >
                                          <FormInputBox
                                            id='input-3-3'
                                            inputRef={inputRefKmeqL}
                                            value={item.meqL}
                                            onBlur={e => onchangevalus("cations", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(30)}
                                            isFocused={isFocused === 30}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefKmeqL", "cations", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr>
                              {cationsValue?.cations.length &&
                                cationsValue?.cations
                                  .filter(item => item.name === "Mg")
                                  .map(item => (
                                    <>
                                      <th>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 31}
                                          onKeyPress={e => handleKeyPress(e, 4, 1)}
                                        >
                                          <FormInputBox
                                            id='input-4-1'
                                            inputRef={inputRefMGmgL}
                                            value={item.mgL}
                                            onBlur={e => onchangevalus("cations", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(31)}
                                            isFocused={isFocused === 31}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefMGmgL", "cations", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 32}
                                          onKeyPress={e => handleKeyPress(e, 4, 2)}
                                        >
                                          <FormInputBox
                                            id='input-4-2'
                                            inputRef={inputRefMGppm}
                                            value={item.ppm}
                                            onBlur={e => onchangevalus("cations", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(32)}
                                            isFocused={isFocused === 32}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefMGppm", "cations", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 33}
                                          onKeyPress={e => handleKeyPress(e, 4, 3)}
                                        >
                                          <FormInputBox
                                            id='input-4-3'
                                            inputRef={inputRefMGmeqL}
                                            value={item.meqL}
                                            onBlur={e => onchangevalus("cations", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(33)}
                                            isFocused={isFocused === 33}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefMGmeqL", "cations", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr>
                              {cationsValue?.cations.length &&
                                cationsValue?.cations
                                  .filter(item => item.name === "Ca")
                                  .map(item => (
                                    <>
                                      <th>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 34}
                                          onKeyPress={e => handleKeyPress(e, 5, 1)}
                                        >
                                          <FormInputBox
                                            id='input-5-1'
                                            inputRef={inputRefCAmgL}
                                            value={item.mgL}
                                            onBlur={e => onchangevalus("cations", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(34)}
                                            isFocused={isFocused === 34}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefCAmgL", "cations", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 35}
                                          onKeyPress={e => handleKeyPress(e, 5, 2)}
                                        >
                                          <FormInputBox
                                            id='input-5-2'
                                            inputRef={inputRefCAppm}
                                            value={item.ppm}
                                            onBlur={e => onchangevalus("cations", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(35)}
                                            isFocused={isFocused === 35}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefCAppm", "cations", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 36}
                                          onKeyPress={e => handleKeyPress(e, 5, 3)}
                                        >
                                          <FormInputBox
                                            id='input-5-3'
                                            inputRef={inputRefCAmeqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("cations", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(36)}
                                            isFocused={isFocused === 36}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefCAmeqL", "cations", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>

                            <tr>
                              {cationsValue?.cations.length &&
                                cationsValue?.cations
                                  .filter(item => item.name === "Sr")
                                  .map(item => (
                                    <>
                                      <th>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 37}
                                          onKeyPress={e => handleKeyPress(e, 6, 1)}
                                        >
                                          <FormInputBox
                                            id='input-6-1'
                                            inputRef={inputRefSRmgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("cations", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(37)}
                                            isFocused={isFocused === 37}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefSRmgL", "cations", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 38}
                                          onKeyPress={e => handleKeyPress(e, 6, 2)}
                                        >
                                          <FormInputBox
                                            id='input-6-2'
                                            inputRef={inputRefSRppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("cations", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(38)}
                                            isFocused={isFocused === 38}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefSRppm", "cations", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 39}
                                          onKeyPress={e => handleKeyPress(e, 6, 3)}
                                        >
                                          <FormInputBox
                                            id='input-6-3'
                                            inputRef={inputRefSRmeqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("cations", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(39)}
                                            isFocused={isFocused === 39}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefSRmeqL", "cations", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr>
                              {cationsValue?.cations.length &&
                                cationsValue?.cations
                                  .filter(item => item.name === "Ba")
                                  .map(item => (
                                    <>
                                      <th>
                                        <CustomLabel key={index} label={item.name} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 40}
                                          onKeyPress={e => handleKeyPress(e, 7, 1)}
                                        >
                                          <FormInputBox
                                            id='input-7-1'
                                            inputRef={inputRefBAmgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("cations", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(40)}
                                            isFocused={isFocused === 40}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefBAmgL", "cations", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 41}
                                          onKeyPress={e => handleKeyPress(e, 7, 2)}
                                        >
                                          <FormInputBox
                                            id='input-7-2'
                                            inputRef={inputRefBAppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("cations", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(41)}
                                            isFocused={isFocused === 41}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefBAppm", "cations", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 42}
                                          onKeyPress={e => handleKeyPress(e, 7, 3)}
                                        >
                                          <FormInputBox
                                            id='input-7-3'
                                            inputRef={inputRefBAmeqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("cations", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(42)}
                                            isFocused={isFocused === 42}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefBAmeqL", "cations", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                          </>
                        ))}
                    </tbody>
                    <tfoot className=''>
                      {GridTotal &&
                        GridTotal.length &&
                        GridTotal?.map(u => (
                          <tr key=''>
                            <th>
                              <CustomHeading label='Total:' fontFamily='NotoSansSemiBold' />
                            </th>
                            <td>
                              <CustomHeading
                                label={Number.parseFloat(u.CationsMql).toFixed(3)}
                                fontFamily='NotoSansSemiBold'
                              />
                            </td>
                            <td>
                              <CustomHeading
                                label={Number.parseFloat(u.Cationsppm).toFixed(3)}
                                fontFamily='NotoSansSemiBold'
                              />
                            </td>
                            <td>
                              <CustomHeading
                                label={Number.parseFloat(u.CationsMeql).toFixed(3)}
                                fontFamily='NotoSansSemiBold'
                              />
                            </td>
                          </tr>
                        ))}
                    </tfoot>
                  </Table>
                </StyledCard>

                <StyledCard className='anions-card'>
                  <div className='anions-heading'>
                    <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Anions' mandatoryIcon />
                    <IconWithTooltip
                      label='Anion conc. in mg/L as ion, ppm as CaCO3, or meq/L; automatically convert between these units.'
                      icon={<InfoIcon />}
                    />
                  </div>
                  <Table className='anions-table'>
                    <thead className=''>
                      <tr>
                        <th>
                          <CustomHeading label='Symbol' fontFamily='NotoSansSemiBold' />
                        </th>
                        <th>
                          <div className='icon-heading'>
                            <CustomHeading label='mg/L' fontFamily='NotoSansSemiBold' />
                          </div>
                        </th>
                        <th>
                          <div className='icon-heading'>
                            <CustomHeading label='ppm CaCO₃' fontFamily='NotoSansSemiBold' />
                          </div>
                        </th>
                        <th>
                          <div className='icon-heading'>
                            <CustomHeading label='meq/L' fontFamily='NotoSansSemiBold' />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {FeedWaterDetails &&
                        FeedWaterDetails?.map((anionsValue, index) => (
                          <>
                            <tr>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "CO₃")
                                  .map(item => (
                                    <>
                                      <th className=''>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 42}
                                          disabled={FeedWaterDetails[0].ph < 6}
                                          onKeyPress={e => handleKeyPress(e, 1, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-1-1'
                                            inputRef={inputRefCO3mgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalusCo3("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(42)}
                                            isFocused={isFocused === 42}
                                            disabled={FeedWaterDetails[0].ph < 6}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefCO3mgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 43}
                                          disabled={FeedWaterDetails[0].ph < 6}
                                          onKeyPress={e => handleKeyPress(e, 1, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-1-2'
                                            inputRef={inputRefCO3ppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalusCo3("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(43)}
                                            disabled={FeedWaterDetails[0].ph < 6}
                                            isFocused={isFocused === 43}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefCO3ppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 245}
                                          disabled={FeedWaterDetails[0].ph < 6}
                                          onKeyPress={e => handleKeyPress(e, 1, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-1-3'
                                            inputRef={inputRefCO3meqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalusCo3("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(245)}
                                            isFocused={isFocused === 245}
                                            disabled={FeedWaterDetails[0].ph < 6}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefCO3meqL", "anions", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr className=''>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "HCO₃")
                                  .map(item => (
                                    <>
                                      <th className=''>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 246}
                                          disabled={FeedWaterDetails[0].ph < 3}
                                          onKeyPress={e => handleKeyPress(e, 2, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-2-1'
                                            inputRef={inputRefHCO3mgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalusHCo3("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(246)}
                                            isFocused={isFocused === 246}
                                            disabled={FeedWaterDetails[0].ph < 3}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefHCO3mgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 247}
                                          disabled={FeedWaterDetails[0].ph < 3}
                                          onKeyPress={e => handleKeyPress(e, 2, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-2-2'
                                            inputRef={inputRefHCO3ppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalusHCo3("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(247)}
                                            isFocused={isFocused === 247}
                                            disabled={FeedWaterDetails[0].ph < 3}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefHCO3ppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 248}
                                          disabled={FeedWaterDetails[0].ph < 3}
                                          onKeyPress={e => handleKeyPress(e, 2, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-2-3'
                                            inputRef={inputRefHCO3meqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalusHCo3("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(248)}
                                            isFocused={isFocused === 248}
                                            disabled={FeedWaterDetails[0].ph < 3}
                                          />
                                          <InputGroup.Text
                                            onClick={e =>
                                              onCancelClick("inputRefHCO3meqL", "anions", "meqL", item.name)
                                            }
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr className=''>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "NO₃")
                                  .map(item => (
                                    <>
                                      <th className=''>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 249}
                                          onKeyPress={e => handleKeyPress(e, 3, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-3-1'
                                            inputRef={inputRefNO3mgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(249)}
                                            isFocused={isFocused === 249}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNO3mgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 50}
                                          onKeyPress={e => handleKeyPress(e, 3, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-3-2'
                                            inputRef={inputRefNO3ppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(50)}
                                            isFocused={isFocused === 50}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNO3ppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 51}
                                          onKeyPress={e => handleKeyPress(e, 3, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-3-3'
                                            inputRef={inputRefNO3meqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(51)}
                                            isFocused={isFocused === 51}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefNO3meqL", "anions", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "F")
                                  .map(item => (
                                    <>
                                      <th className=''>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 52}
                                          onKeyPress={e => handleKeyPress(e, 4, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-4-1'
                                            inputRef={inputRefFmgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(52)}
                                            isFocused={isFocused === 52}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefFmgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 53}
                                          onKeyPress={e => handleKeyPress(e, 4, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-4-2'
                                            inputRef={inputRefFppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(53)}
                                            isFocused={isFocused === 53}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefFppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 54}
                                          onKeyPress={e => handleKeyPress(e, 4, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-4-3'
                                            inputRef={inputRefFmeqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(54)}
                                            isFocused={isFocused === 54}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefFmeqL", "anions", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "Cl")
                                  .map(item => (
                                    <>
                                      <td className=''>
                                        <CustomLabel label={item.name} key={index} />
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 55}
                                          onKeyPress={e => handleKeyPress(e, 5, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-5-1'
                                            inputRef={inputRefClmgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(55)}
                                            isFocused={isFocused === 55}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefClmgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 56}
                                          onKeyPress={e => handleKeyPress(e, 5, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-5-2'
                                            inputRef={inputRefClppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(56)}
                                            isFocused={isFocused === 56}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefClppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 57}
                                          onKeyPress={e => handleKeyPress(e, 5, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-5-3'
                                            inputRef={inputRefClmeqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(57)}
                                            isFocused={isFocused === 57}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefClmeqL", "anions", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr className=''>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "Br")
                                  .map(item => (
                                    <>
                                      <td className=''>
                                        <CustomLabel label={item.name} key={index} />
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 58}
                                          onKeyPress={e => handleKeyPress(e, 6, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-6-1'
                                            inputRef={inputRefBRmgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(58)}
                                            isFocused={isFocused === 58}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefBRmgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 59}
                                          onKeyPress={e => handleKeyPress(e, 6, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-6-2'
                                            inputRef={inputRefBRppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(59)}
                                            isFocused={isFocused === 59}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefBRppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td className=''>
                                        <CustomInputGroup
                                          isFocused={isFocused === 60}
                                          onKeyPress={e => handleKeyPress(e, 6, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-6-3'
                                            inputRef={inputRefBRmeqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(60)}
                                            isFocused={isFocused === 60}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefBRmeqL", "anions", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr className=''>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "SO₄")
                                  .map(item => (
                                    <>
                                      <th>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 61}
                                          onKeyPress={e => handleKeyPress(e, 7, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-7-1'
                                            inputRef={inputRefSO4mgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(61)}
                                            isFocused={isFocused === 61}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefSO4mgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 62}
                                          onKeyPress={e => handleKeyPress(e, 7, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-7-2'
                                            inputRef={inputRefSO4ppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(62)}
                                            isFocused={isFocused === 62}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefSO4ppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 63}
                                          onKeyPress={e => handleKeyPress(e, 7, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-7-3'
                                            inputRef={inputRefSO4meqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(63)}
                                            isFocused={isFocused === 63}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefSO4meqL", "anions", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                            <tr>
                              {anionsValue.anions.length &&
                                anionsValue?.anions
                                  .filter(item => item.name === "PO₄")
                                  .map(item => (
                                    <>
                                      <th className=''>
                                        <CustomLabel label={item.name} key={index} />
                                      </th>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 64}
                                          onKeyPress={e => handleKeyPress(e, 8, 1, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-8-1'
                                            inputRef={inputRefPO4mgL}
                                            value={Number.parseFloat(item.mgL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "mgL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(64)}
                                            isFocused={isFocused === 64}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefPO4mgL", "anions", "mgL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 65}
                                          onKeyPress={e => handleKeyPress(e, 8, 2, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-8-2'
                                            inputRef={inputRefPO4ppm}
                                            value={Number.parseFloat(item.ppm).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "ppm", item.name, e.target.value)}
                                            onFocus={() => handleFocus(65)}
                                            isFocused={isFocused === 65}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefPO4ppm", "anions", "ppm", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                      <td>
                                        <CustomInputGroup
                                          isFocused={isFocused === 66}
                                          onKeyPress={e => handleKeyPress(e, 8, 3, "a")}
                                        >
                                          <FormInputBox
                                            id='anion-8-3'
                                            inputRef={inputRefPO4meqL}
                                            value={Number.parseFloat(item.meqL).toFixed(3)}
                                            onBlur={e => onchangevalus("anions", "meqL", item.name, e.target.value)}
                                            onFocus={() => handleFocus(66)}
                                            isFocused={isFocused === 66}
                                          />
                                          <InputGroup.Text
                                            onClick={e => onCancelClick("inputRefPO4meqL", "anions", "meqL", item.name)}
                                          >
                                            <CloseCircleGreenIcon />
                                          </InputGroup.Text>
                                        </CustomInputGroup>
                                      </td>
                                    </>
                                  ))}
                            </tr>
                          </>
                        ))}
                    </tbody>
                    <tfoot className=''>
                      {GridTotal &&
                        GridTotal.length &&
                        GridTotal?.map(anionsValue => (
                          <tr key=''>
                            <th className='anions-title'>
                              <CustomHeading label='Total:' fontFamily='NotoSansSemiBold' />
                            </th>
                            <th className='anions-title'>
                              <CustomHeading
                                label={Number.parseFloat(anionsValue.AnionsMql).toFixed(3)}
                                fontFamily='NotoSansSemiBold'
                              />
                            </th>
                            <th className='anions-title'>
                              <CustomHeading
                                label={Number.parseFloat(anionsValue.Anionsppm).toFixed(3)}
                                fontFamily='NotoSansSemiBold'
                              />
                            </th>
                            <th className='anions-title'>
                              <CustomHeading
                                label={Number.parseFloat(anionsValue.AnionsMeql).toFixed(3)}
                                fontFamily='NotoSansSemiBold'
                              />
                            </th>
                          </tr>
                        ))}
                    </tfoot>
                  </Table>
                </StyledCard>

                <StyledCard className='neutrals-card'>
                  <div className='neutrals-heading'>
                    <CustomHeading color={colors.PrimaryDarkAquaMarine} label='Neutrals' mandatoryIcon />
                    <IconWithTooltip label='Neutral concentration in mg/L.' icon={<InfoIcon />} />
                  </div>
                  <Table className='neutrals-table'>
                    <thead>
                      <tr>
                        <th>
                          <CustomHeading label='Symbol' fontFamily='NotoSansSemiBold' />
                        </th>
                        <th>
                          <div className='icon-heading'>
                            <CustomHeading label='mg/L' fontFamily='NotoSansSemiBold' />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {FeedWaterDetails &&
                        FeedWaterDetails?.map((neutralsValue, index) => (
                          <>
                            <tr className=''>
                              {neutralsValue?.neutrals
                                .filter(item => item.name === "SiO₂")
                                .map(item => (
                                  <>
                                    <th>
                                      <CustomLabel label={item.name} key={index} />
                                    </th>
                                    <td>
                                      <CustomInputGroup
                                        isFocused={isFocused === 67}
                                        onKeyPress={e => handleKeyPress(e, 1, 1, "n")}
                                      >
                                        <FormInputBox
                                          id='neutrals-1-1'
                                          inputRef={inputRefSIO}
                                          value={Number.parseFloat(item.mgL).toFixed(3)}
                                          onBlur={e => onchangevalus("neutrals", "mgL", item.name, e.target.value)}
                                          onFocus={() => handleFocus(67)}
                                          isFocused={isFocused === 67}
                                        />
                                        <InputGroup.Text
                                          onClick={e => onCancelClick("inputRefSIO", "neutrals", "mgL", item.name)}
                                        >
                                          <CloseCircleGreenIcon />
                                        </InputGroup.Text>
                                      </CustomInputGroup>
                                    </td>
                                  </>
                                ))}
                            </tr>
                            <tr className=''>
                              {neutralsValue?.neutrals
                                .filter(item => item.name === "B")
                                .map(item => (
                                  <>
                                    <th>
                                      <CustomLabel label={item.name} key={index} />
                                    </th>
                                    <td>
                                      <CustomInputGroup
                                        isFocused={isFocused === 68}
                                        onKeyPress={e => handleKeyPress(e, 2, 1, "n")}
                                      >
                                        <FormInputBox
                                          id='neutrals-2-1'
                                          inputRef={inputRefB}
                                          value={Number.parseFloat(item.mgL).toFixed(3)}
                                          onBlur={e => onchangevalus("neutrals", "mgL", item.name, e.target.value)}
                                          onFocus={() => handleFocus(68)}
                                          isFocused={isFocused === 68}
                                        />
                                        <InputGroup.Text
                                          onClick={e => onCancelClick("inputRefB", "neutrals", "mgL", item.name)}
                                        >
                                          <CloseCircleGreenIcon />
                                        </InputGroup.Text>
                                      </CustomInputGroup>
                                    </td>
                                  </>
                                ))}
                            </tr>
                            <tr className=''>
                              {neutralsValue?.neutrals
                                .filter(item => item.name === "CO₂")
                                .map(item => (
                                  <>
                                    <th>
                                      <CustomLabel label={item.name} key={index} />
                                    </th>
                                    <td>
                                      <CustomInputGroup
                                        isFocused={isFocused === 69}
                                        disabled={FeedWaterDetails[0].ph > 9}
                                        onKeyPress={e => handleKeyPressfeedsetup(e, "neutrals-3-1")}
                                      >
                                        <FormInputBox
                                          id='neutrals-3-1'
                                          inputRef={inputRefCO2}
                                          value={Number.parseFloat(item.mgL).toFixed(3)}
                                          onBlur={e => onchangevalusCo2("neutrals", "mgL", item.name, e.target.value)}
                                          onFocus={() => handleFocus(69)}
                                          isFocused={isFocused === 69}
                                          disabled={FeedWaterDetails[0].ph > 9}
                                        />
                                        <InputGroup.Text
                                          onClick={e => onCancelClick("inputRefCO2", "neutrals", "mgL", item.name)}
                                        >
                                          <CloseCircleGreenIcon />
                                        </InputGroup.Text>
                                      </CustomInputGroup>
                                    </td>
                                  </>
                                ))}
                            </tr>

                            <tr className='blank-row'>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            <tr className='blank-row'>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            <tr className='blank-row'>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            <tr className='blank-row'>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                            <tr className='blank-row'>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                    <tfoot className='neutrals-title-footer'>
                      {GridTotal &&
                        GridTotal.length &&
                        GridTotal?.map(neutralsValue => (
                          <tr key=''>
                            <th className='neutrals-title'>
                              <CustomHeading label='Total:' fontFamily='NotoSansSemiBold' />
                            </th>
                            <th className='neutrals-title'>
                              <CustomHeading
                                label={Number.parseFloat(neutralsValue.neutralsMql).toFixed(3)}
                                fontFamily='NotoSansSemiBold'
                              />
                            </th>
                          </tr>
                        ))}
                    </tfoot>
                  </Table>
                </StyledCard>
              </div>

              <Col lg={12} md={12} sm={12} xs={12} className='summarized-feed-setup-column'>
                <Card className='summarized-feed-setup-card'>
                  <div className='form-heading'>
                    <h4>Summarized Feed Setup Values</h4>
                  </div>
                  <>
                    <div className='card-body-wrapper'>
                      {FeedWaterDetails &&
                        FeedWaterDetails?.map((summarizedData, index) => (
                          <>
                            <Card.Body key={index}>
                              {projectTechnology === "UF" ? (
                                <>
                                  <Card.Text>
                                    Total Dissolved Solids:&nbsp;
                                    {Number.parseFloat(summarizedData.totalDissolvedSolids).toFixed(3)} mg/L
                                  </Card.Text>
                                </>
                              ) : isIXTechnology(projectTechnology) ? (
                                <>
                                  <Card.Text>
                                    Total Dissolved Solutes:&nbsp;
                                    {Number.parseFloat(summarizedData.totalDissolvedSolutes).toFixed(3)} mg/L
                                  </Card.Text>
                                  {TotalppmCaCO3 &&
                                    TotalppmCaCO3?.map((u, index) => (
                                      <Card.Text key={index}>
                                        Total ppm CaCO₃:&nbsp;{Number.parseFloat(u).toFixed(3)}
                                      </Card.Text>
                                    ))}
                                </>
                              ) : projectTechnology === "Multiple" ? (
                                <>
                                  <Card.Text>
                                    Total Dissolved Solutes:&nbsp;
                                    {Number.parseFloat(summarizedData.totalDissolvedSolutes).toFixed(3)} mg/L
                                  </Card.Text>
                                  <Card.Text>
                                    Total Dissolved Solids:&nbsp;
                                    {Number.parseFloat(summarizedData.totalDissolvedSolids).toFixed(3)} mg/L (Not for
                                    Ix)
                                  </Card.Text>
                                  {TotalppmCaCO3 &&
                                    TotalppmCaCO3?.map((u, index) => (
                                      <Card.Text key={index}>
                                        Total ppm CaCO₃:&nbsp;{Number.parseFloat(u).toFixed(3)}
                                      </Card.Text>
                                    ))}
                                </>
                              ) : projectTechnology === "null" ? (
                                <>
                                  <Card.Text>
                                    Total Dissolved Solutes:&nbsp;
                                    {Number.parseFloat(summarizedData.totalDissolvedSolutes).toFixed(3)} mg/L
                                  </Card.Text>
                                </>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                            <Card.Body className='center-card-body'>
                              <Card.Text>
                                Charge Balance:&nbsp;{Number.parseFloat(summarizedData.chargeBalance).toFixed(6)} meq/L
                              </Card.Text>
                            </Card.Body>
                            <Card.Body className='right-card-body'>
                              <Card.Text>
                                Estimated Conductivity:&nbsp;
                                {`${Number.parseFloat(summarizedData.estimatedConductivity).toFixed(2)} ${unit.selectedUnits[17]}`}
                              </Card.Text>
                            </Card.Body>
                          </>
                        ))}
                    </div>
                  </>
                </Card>
              </Col>

              <Col lg={12} md={12} sm={12} xs={12} className='additional-feed-water-information-column'>
                <Card className='additional-feed-water-information-card'>
                  <div className='form-heading'>
                    <h4>Additional Feed Water Information</h4>
                  </div>
                  <CustomTextArea
                    rows={4}
                    type='textarea'
                    id='txtArea'
                    defaultValue={streamValue.additionalFeedWaterInfo}
                    className='additional_feed_setup_info'
                    placeholder={FEED_STRINGS.additionalInfoPlaceHolder}
                    onChange={e => textchange("txtArea", e.target.value)}
                    onBlur={handleBlur}
                  />
                </Card>
              </Col>
            </FormEntryStyled>
          ))}
        {showAlert ? <AlertPopUp type={alertData?.type} message={alertData?.message} close={handleHideAlert} /> : null}
      </>
    </DynamicLoadder>
  );
};

export default FormEntry;
