import html2canvas from "html2canvas";
import moment from "moment";

import { CHEMICALS_SYMBOL_MAPPING, DATE_FORMAT } from "@constants/global.constants";

import DupontLogger from "./DupontLogger";

const featureIdToField = {
  1: "mCIP",
  2: "CEB",
};

export const getDisabledUFTech = ufSpecialFeatureID => featureIdToField[ufSpecialFeatureID] || "";

export const convertUpto2Digits = value => (isNaN(parseFloat(value)) ? "0" : parseFloat(value).toFixed(2));

export const convertUptoDigits = (value, digits = 2) => {
  const tempValue = isNaN(value) ? 0 : parseFloat(value);
  return (tempValue && tempValue.toFixed(digits)) || 0;
};

/**
 * Changes the format of a chemical symbol based on a predefined mapping.
 * Replace each occurrence of the 'from' symbol with the 'to' symbol
 *
 * @param {string} chemicalSymbol - The chemical symbol to be formatted.
 * @return {string} - The formatted chemical symbol.
 */
export const changeChemicalFormat = chemicalSymbol => {
  if (!chemicalSymbol) return "0";

  for (const [from, to] of Object.entries(CHEMICALS_SYMBOL_MAPPING)) {
    chemicalSymbol = `${chemicalSymbol}`.replaceAll(from, to);
  }

  return chemicalSymbol;
};

/**
 * Converts a source object into a new object with keys appended by a type.
 * @param {Object} source - The source object.
 * @param {string} type - The type to append to each key.
 * @return {Object} - The new object with keys appended by the type.
 */
export const getChemicalObject = (source, type, disabledField) =>
  Object.entries(source).reduce((result, [key, value]) => {
    const paramValue = disabledField && key.includes(disabledField) ? "0" : value;
    result[`${key}_${type}`] = paramValue;
    return result;
  }, {});

/**
 * Checks if a given technology is disabled.
 *
 * This function checks if the application is running in a production environment
 * and if the provided technology name is "ix" (case insensitive). If both conditions
 * are met, the technology is considered disabled and the function returns true.
 * Otherwise, it returns false.
 *
 * @param {string} techName - The name of the technology to check.
 * @returns {boolean} - Returns true if the technology is disabled, false otherwise.
 */
export const isTechDisabled = techName => {
  const { REACT_APP_IS_IX_DISABLED } = process.env;
  return REACT_APP_IS_IX_DISABLED?.toLowerCase() === "true" && techName?.toLowerCase() === "ix";
};

export const getFolderName = foldername =>
  foldername?.length > 20 ? `${foldername?.substring(0, 20)}...` : foldername;

export const debounce = (callback, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

const validData = (data, label, flag) => {
  const den = flag ? "0" : 0;
  return data ? data[label] : den;
};

export const formatChemicalSymbol = (chemicalSymbol, flag, field = "symbol") =>
  changeChemicalFormat(validData(chemicalSymbol, field, flag)).toString();

export const calculateSum = arr => {
  const sum = arr.reduce((acc, val) => {
    const value = parseFloat(val) || 0;
    return acc + value;
  }, 0);
  return sum;
};

// function returns true if all doses are disabled or total dose value is 0
export const isDoseErr = ({ indicators, doseValues }) => {
  const isAllDosesDisabled = indicators.every(indicator => !indicator);
  const totalDoseValue = calculateSum(doseValues);
  return isAllDosesDisabled || totalDoseValue === 0;
};

export const loadExternalScript = url => {
  if (url) {
    const script = document.createElement("script");
    script.src = url;
    document.body.appendChild(script);
  }
};

export const isValueInRange = (value, ranges) => {
  if (isNaN(value) || !ranges) return false;
  const { minValue, maxValue } = ranges;

  const val = parseFloat(value);
  return val >= minValue && val <= maxValue;
};

export const groupBy = (array, key) => {
  if (!array || !key) return {};
  return array.reduce((acc, currentValue) => {
    const groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};

/**
 * Capitalizes the first character of the input string.
 *
 * @param {string} value - The input string to be capitalized.
 * @return {string} - The input string with the first character capitalized.
 */
export const capitalize = value => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Formats a date using the moment library.
 *
 * @param {string|Date} date - The date to be formatted.
 * @param {string} format - The format string to use.
 * @return {string} - The formatted date string.
 */
export const formatDate = (date, format = DATE_FORMAT) => (date ? moment(date).format(format) : "-");

/**
 * Converts the HTML content of a specified selector to a Base64-encoded PNG image.
 *
 * @param {string} selector - The CSS selector of the HTML element to convert.
 * @param {Object} options - The options to pass to the html2canvas function.
 * @returns {Promise<string>} A promise that resolves to a Base64-encoded PNG image.
 * @throws Will log an error message if the conversion fails.
 */
export const convertHtmlToBase64 = async (selector, options) => {
  try {
    const generatedCanvas = await html2canvas(document.querySelector(selector), options);
    return generatedCanvas.toDataURL("image/png");
  } catch (error) {
    DupontLogger("error", "Error in converting system diagram to base64", error);
  }
};

/**
 * Combines multiple class names into a single string.
 *
 * @param {...string} classes - The class names to combine.
 * @returns {string} A single string with all the class names combined, separated by spaces.
 */
export const combineClassNames = (...classes) => classes.filter(Boolean).join(" ").trim();

/**
 * Checks if a value is null or undefined.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} True if the value is null or undefined, false otherwise.
 */
export const isNullOrUndefined = value => value === null || value === undefined;
