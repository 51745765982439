import { UFDesignDiagram } from "@features/feedwater/uf/UFSystemDesignDiagram";

import { WPReportSectionHeader } from "../WPReportSectionHeader";

/*currently directly rendering UF Design Diagram
 This component will be used to render other technology Design Diagram in the report */
export const WPReportDesignDiagram = () => (
  <>
    <WPReportSectionHeader text='UF System Overview' />
    <UFDesignDiagram />
  </>
);
