/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { PERCENTAGE_RANGE } from "@constants/global.constants";
import { UNITS } from "@constants/units.constant";

import useFPAValidation from "@hooks/useFPAValidation";

import { convertUpto2Digits } from "@utils/appUtils";

import InfoIcon from "@common/icons/InfoIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomRadio from "@common/styles/components/radios/CustomRadio";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { colors } from "@common/styles/Theme";
import CalculateNACondValue from "@common/utils/CalculateNACondValue";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import UnitConversion from "@common/utils/UnitConversion";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import {
  ALERT_MSG,
  EffluentQulatiyRanges,
  REGENERANT_DOSE_ID,
  REGENERANT_RATIO,
  REGENERATION_RATIO,
  REGENERATION_RATIO_RANGE,
  SAC_SAFETY_FACTOR,
  SAFETY_FACTOR_RANGE,
  SBA_SAFETY_FACTOR,
  TECH_IXMB,
  WAC_OVERRUN,
  WAC_RESIN_ID,
  WAC_SAFETY_FACTOR,
  WBA_OVERRUN,
  WBA_RESIN_ID,
  WBA_SAFETY_FACTOR,
} from "./constants/IXDConstants";
import { getRangesInCurrentUnit, isAvgEndPointValuesOutOfRange, isValueOutOfRange } from "./IXConfiguration/IXhelper";
import { updateAdvRegenAniondata, updateAdvRegenCationdata, updateProductQualityRegenerantDose } from "./IXDSlice";
import ProductQualityRegenerantDoseStyled from "./ProductQualityRegenerantDoseStyled";
import SystemDiagram from "./SystemDiagram";

const regenrantChemicals = [
  {
    ixregenerantId: 1,
    chemicalId: "28246",
    displayName: "HCl",
  },
  {
    ixregenerantId: 2,
    chemicalId: "28249",
    displayName: "H₂SO₄",
  },
  {
    ixregenerantId: 4,
    chemicalId: "28251",
    displayName: "NaOH",
  },
];
const ProductQualityRegenerantDose = () => {
  const [isFocused, setIsFocused] = useState(null);
  const { updateParameterSettings } = useFPAValidation();
  const [getRangesValues, responseRangesValues] = useLazyGetAllDataQuery();
  const ixStore = useSelector(state => state.IXStore.data);
  const reageantCation = ixStore.listRegenConds[0];
  const reageantAnion = ixStore.listRegenConds[1];
  const [radioOptionEnabled, setRadioOptionEnabled] = useState(true);
  const [RadionCheckEnable, setRadionCheckEnable] = useState(0);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const dispatch = useDispatch();

  const ixStoreCation = useSelector(state => state.IXStore.data.listProductQualityandregeneration[0]);
  const ixStoreAnion = useSelector(state => state.IXStore.data.listProductQualityandregeneration[1]);
  const Cation_regenerantID = useSelector(state => state.IXStore.data.listRegenConds[0]?.chemicalID);
  const Anion_regenerantID = useSelector(state => state.IXStore.data.listRegenConds[1]?.chemicalID);
  const [isSumValid, setIsSumValid] = useState(false);
  const regenerantListCation = useSelector(state =>
    state.IXStore.regenerantListValue?.regenerantList1?.find(val => val.chemicalId == Cation_regenerantID),
  );
  const regenerantListAnion = useSelector(state =>
    state.IXStore.regenerantListValue?.regenerantList2?.find(val => val.chemicalId == Anion_regenerantID),
  );
  const regen = useSelector(state => state.IXStore?.data);
  const resinVal = useSelector(state => state.IXStore.data?.listRegenConds);
  const { validDesignID, vessel1, vessel2, vessel3, vessel4, treatmentName } = useSelector(state => state.IXStore.data);
  const cationAdvRegen = useSelector(state => state.IXStore?.updateCationDataAdvRegen);
  const anionAdvRegen = useSelector(state => state.IXStore?.updateAnionDataAdvRegen);
  const isIXMB = useMemo(() => treatmentName === TECH_IXMB, [treatmentName]);

  const regenerantListCationValue = `Dose ${
    regenerantListCation
      ? regenerantListCation.displayName
      : regenrantChemicals.find(item => item.ixregenerantId == reageantCation.regenerantID)?.displayName
  }`;
  const regenerantListCationValueArry = regenerantListCationValue.split("(");
  const regenerantListCationValueNew =
    regenerantListCationValueArry.length > 0 ? regenerantListCationValueArry[0] : null;
  const regenerantListAnionValue = `Dose  ${
    regenerantListAnion
      ? regenerantListAnion.displayName
      : regenrantChemicals.find(item => item.ixregenerantId == reageantAnion.regenerantID)?.displayName
  }`;
  const regenerantListAnionValueArry = regenerantListAnionValue.split("(");
  const regenerantListAnionValueNew = regenerantListAnionValueArry.length > 0 ? regenerantListAnionValueArry[0] : null;
  const [message, setMessage] = useState("");
  const [getAndRegenerantDose, responseAndRegenerantDose] = useLazyGetAllDataQuery();
  const [Res_AndRegenerantDose, setRes_AndRegenerantDose] = useState([]);
  const [TRes_AndRegenerantDose, tsetRes_AndRegenerantDose] = useState([]);
  const [TTRes_AndRegenerantDose, ttsetRes_AndRegenerantDose] = useState([]);
  const Modeling = useSelector(state => state.IXStore.modeling);
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 0;
  const [Na_Average, setNa_Average] = useState(false);
  const [Na_End, setNa_End] = useState(false);

  const [Cond_Average, setCond_Average] = useState(false);
  const [Cond_End, setCond_End] = useState(false);

  const validdesignID = useSelector(state => state.IXStore.data.validDesignID);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const projectID = ProjectInfoStore.projectID;

  const Req_AndRegenerantDose = {
    userID: userID,
    projectID: projectID,
    validdesignID: validdesignID,
    regenerantID: 1,
    columnNum: 1,
  };

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsSumValid(false);
    }
  };
  useEffect(() => {
    let apiUrl1 = `${"ix/api/v1/AdvancedRegen"}?userID=${regen.userID}&projectID=${regen.projectID}&validdesignID=${validDesignID}&regenerantID=${resinVal[0]?.regenerantID}&columnNum=${1}`;
    if (vessel1 !== null) {
      apiUrl1 += `&vessel1=${vessel1}`;
    }
    if (vessel2 !== null) {
      apiUrl1 += `&vessel2=${vessel2}`;
    }
    if (vessel3 !== null) {
      apiUrl1 += `&vessel3=${vessel3}`;
    }
    if (vessel4 !== null) {
      apiUrl1 += `&vessel4=${vessel4}`;
    }
    try {
      getRangesValues(apiUrl1).then(responseRangesValues => {
        if (responseRangesValues.isLoading) {
          console.log("Loading");
        } else {
          if (responseRangesValues.isSuccess === true) {
            dispatch(updateAdvRegenCationdata(responseRangesValues.data));
          }
        }
      });
    } catch {
      console.log("Error: Fetch Range data");
    }
  }, []);
  useEffect(() => {
    let apiUrl1 = `${"ix/api/v1/AdvancedRegen"}?userID=${regen.userID}&projectID=${regen.projectID}&validdesignID=${validDesignID}&regenerantID=${resinVal[1]?.regenerantID}&columnNum=${2}`;
    if (vessel1 !== null) {
      apiUrl1 += `&vessel1=${vessel1}`;
    }
    if (vessel2 !== null) {
      apiUrl1 += `&vessel2=${vessel2}`;
    }
    if (vessel3 !== null) {
      apiUrl1 += `&vessel3=${vessel3}`;
    }
    if (vessel4 !== null) {
      apiUrl1 += `&vessel4=${vessel4}`;
    }
    try {
      getRangesValues(apiUrl1).then(responseRangesValues => {
        if (responseRangesValues.isLoading) {
          console.log("Loading");
        } else {
          if (responseRangesValues.isSuccess === true) {
            dispatch(updateAdvRegenAniondata(responseRangesValues.data));
            // setRangeAnion(responseRangesValues.data);
          }
        }
      });
    } catch {
      console.log("Error: Fetch Range data");
    }
  }, []);

  //---------After report getting IXD data starts---------------//
  const { calcChemDose, calcOverrun } = useSelector(state => state.IXStore);
  useEffect(() => {
    if (ixStoreCation?.overAllComputation === 1) {
      if (calcChemDose.length > 0 && calcOverrun.length > 0) {
        let CationDose = "";
        let AnionDose = "";
        let cation = true;
        calcChemDose.forEach(Dose => {
          if (Dose != "" && cation == true) {
            CationDose = GlobalUnitConversion(
              GlobalUnitConversionStore,
              Math.round(Dose),
              "g/L",
              unit.selectedUnits[14],
            );
            cation = false;
          } else if (Dose != "" && cation == false) {
            AnionDose = GlobalUnitConversion(
              GlobalUnitConversionStore,
              Math.round(Dose),
              "g/L",
              unit.selectedUnits[14],
            );
          }
        });
        let CationOverrun = "";
        let AnionOverrun = "";
        const cationOverrun = true;
        calcOverrun.forEach(Overrun => {
          if (Overrun != "" && cationOverrun == true) {
            CationDose = Overrun;
            CationOverrun = false;
          } else if (Overrun != "" && cationOverrun == false) {
            AnionOverrun = Overrun;
          }
        });
        dispatch(
          updateProductQualityRegenerantDose([
            {
              ...ixStoreCation,
              regenerantDoseVal4: CationDose,
              regenerantDoseVal2: CationOverrun,
            },
            {
              ...ixStoreAnion,
              regenerantDoseVal4: AnionDose,
              regenerantDoseVal2: AnionOverrun,
            },
          ]),
        );
        //}
      }
    }
  }, [calcChemDose, calcOverrun]);

  useEffect(() => {
    try {
      const apiUrl = `${"ix/api/v1/ProductQualityAndRegenerantDose"}?userID=${Req_AndRegenerantDose.userID}&projectID=${Req_AndRegenerantDose.projectID}&validdesignID=${Req_AndRegenerantDose.validdesignID}&regenerantID=${Req_AndRegenerantDose.regenerantID}
          &columnNum=${Req_AndRegenerantDose.columnNum}&treatmentName=${treatmentName}`;
      getAndRegenerantDose(apiUrl);
    } catch {
      // console.log("Error: Fetch VesselRegenertion data");
    }
  }, []);
  useEffect(() => {
    if (responseAndRegenerantDose.isSuccess === true) {
      setRes_AndRegenerantDose(responseAndRegenerantDose.data);
    }
  }, [responseAndRegenerantDose]);

  useEffect(() => {
    if (Res_AndRegenerantDose?.ixRegenerationDoseName_column1?.length > 0) {
      tsetRes_AndRegenerantDose([]);
      let Flag1 = 0,
        Flag2 = 0,
        Flag3 = 0,
        Flag4 = 0;
      let Fs1 = 0;
      Res_AndRegenerantDose?.ixRegenerationDoseName_column1?.forEach(element => {
        Fs1 = Fs1 + 1;
        if (parseInt(ixStoreCation.saftyFactorLbl) == parseInt(element.ixregenerantDoseId) && Flag1 == 0) {
          Flag1 = 1;
          tsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreCation.saftyFactorVal,
            },
          ]);
        } else if (parseInt(ixStoreCation.regenerantDoseLbl1ID) == parseInt(element.ixregenerantDoseId) && Flag2 == 0) {
          Flag2 = 1;
          tsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreCation.regeneratDoseVal1,
            },
          ]);
        } else if (parseInt(ixStoreCation.regenerantDoseLbl2ID) == parseInt(element.ixregenerantDoseId) && Flag3 == 0) {
          Flag3 = 1;
          tsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreCation.regenerantDoseVal2,
            },
          ]);
        } else if (parseInt(ixStoreCation.regenerantDoseLbl3ID) == parseInt(element.ixregenerantDoseId) && Flag4 == 0) {
          Flag4 = 1;
          tsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreCation.regenerantDoseVal3,
            },
          ]);
        } else {
          if (element.langText == "WAC Overrun") {
            tsetRes_AndRegenerantDose(current => [
              ...current,
              {
                ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
                langText: element.langText,
                txtValue: 0.0,
              },
            ]);
          } else {
            tsetRes_AndRegenerantDose(current => [
              ...current,
              {
                ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
                langText: element.langText,
                txtValue: element.defaultValue,
              },
            ]);
          }
        }
      });
    }
  }, [Res_AndRegenerantDose]);

  useEffect(() => {
    if (Res_AndRegenerantDose?.ixRegenerationDoseName_column2?.length > 0) {
      ttsetRes_AndRegenerantDose([]);
      let Flag1 = 0,
        Flag2 = 0,
        Flag3 = 0,
        Flag4 = 0;
      let Fs2 = 0;
      Res_AndRegenerantDose?.ixRegenerationDoseName_column2?.forEach(element => {
        Fs2 = Fs2 + 1;
        if (parseInt(ixStoreAnion.saftyFactorLbl) == parseInt(element.ixregenerantDoseId) && Flag1 == 0) {
          Flag1 = 1;
          ttsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreAnion.saftyFactorVal,
            },
          ]);
        } else if (parseInt(ixStoreAnion.regenerantDoseLbl1ID) == parseInt(element.ixregenerantDoseId) && Flag2 == 0) {
          Flag2 = 1;
          ttsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreAnion.regeneratDoseVal1,
            },
          ]);
        } else if (parseInt(ixStoreAnion.regenerantDoseLbl2ID) == parseInt(element.ixregenerantDoseId) && Flag3 == 0) {
          Flag3 = 1;
          ttsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreAnion.regenerantDoseVal2,
            },
          ]);
        } else if (parseInt(ixStoreAnion.regenerantDoseLbl3ID) == parseInt(element.ixregenerantDoseId) && Flag4 == 0) {
          Flag4 = 1;
          ttsetRes_AndRegenerantDose(current => [
            ...current,
            {
              ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
              langText: element.langText,
              txtValue: ixStoreAnion.regenerantDoseVal3,
            },
          ]);
        } else {
          if (element.langText == "WBA Overrun") {
            ttsetRes_AndRegenerantDose(current => [
              ...current,
              {
                ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
                langText: element.langText,
                txtValue: 0.0,
              },
            ]);
          } else {
            ttsetRes_AndRegenerantDose(current => [
              ...current,
              {
                ixregenerantDoseId: parseInt(element.ixregenerantDoseId),
                langText: element.langText,
                txtValue: element.defaultValue,
              },
            ]);
          }
        }
      });
    }
  }, [Res_AndRegenerantDose]);

  const CationRegenerantDoseCheck = (e, labelvalue, labeltext) => {
    let temTRes_AndRegenerantDose;
    if (e.target.name === "CatRegenerantDose-0") {
      temTRes_AndRegenerantDose = TRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue) && st.langText == labeltext) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      tsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            saftyFactorLbl: parseInt(labelvalue),
            saftyFactorVal: parseFloat(e.target.value),
          },
          ixStoreAnion,
        ]),
      );
    } else if (e.target.name === "CatRegenerantDose-1") {
      temTRes_AndRegenerantDose = TRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue) && st.langText == labeltext) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      tsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            regenerantDoseLbl1ID: parseInt(labelvalue),
            regeneratDoseVal1: parseFloat(e.target.value),
          },
          ixStoreAnion,
        ]),
      );
    } else if (e.target.name === "CatRegenerantDose-2") {
      temTRes_AndRegenerantDose = TRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue) && st.langText == labeltext) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      tsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            regenerantDoseLbl2ID: parseInt(labelvalue),
            regenerantDoseVal2: parseFloat(e.target.value),
          },
          ixStoreAnion,
        ]),
      );
    } else {
      temTRes_AndRegenerantDose = TRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue) && st.langText == labeltext) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      tsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            regenerantDoseLbl3ID: parseInt(labelvalue),
            regenerantDoseVal3: parseFloat(e.target.value),
          },
          ixStoreAnion,
        ]),
      );
    }
    updateParameterSettings();
  };
  const AnionRegenerantDoseCheck = (e, labelvalue) => {
    let temTRes_AndRegenerantDose;
    if (e.target.name === "ANRegenerantDose-0") {
      temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue)) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            saftyFactorLbl: parseInt(labelvalue),
            saftyFactorVal: e.target.value,
          },
        ]),
      );
    } else if (e.target.name === "ANRegenerantDose-1") {
      temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue)) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            regenerantDoseLbl1ID: parseInt(labelvalue),
            regeneratDoseVal1: e.target.value,
          },
        ]),
      );
    } else if (e.target.name === "ANRegenerantDose-2") {
      temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue)) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            regenerantDoseLbl2ID: parseInt(labelvalue),
            regenerantDoseVal2: e.target.value,
          },
        ]),
      );
    } else {
      temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
        if (st.ixregenerantDoseId === parseInt(labelvalue)) {
          st = {
            ...st,
            txtValue: parseFloat(e.target.value),
            ixregenerantDoseId: parseInt(labelvalue),
          };
        }
        return st;
      });
      ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            regenerantDoseLbl3ID: parseInt(labelvalue),
            regenerantDoseVal3: e.target.value,
          },
        ]),
      );
    }
    updateParameterSettings();
  };
  const CationCheck = e => {
    dispatch(
      updateProductQualityRegenerantDose([
        {
          ...ixStoreCation,
          [e.target.name]: parseInt(e.target.value),
        },
        ixStoreAnion,
      ]),
    );
    updateParameterSettings();
  };
  const AnionCheck = e => {
    if (e.target.name === "Anion_regenerantDoseLbl4ID") {
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            regenerantDoseLbl4ID: parseInt(e.target.value),
          },
        ]),
      );
    } else {
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            speciesLblNameID: parseInt(e.target.value),
          },
        ]),
      );
    }
    updateParameterSettings();
  };
  const SPECIES_UNIT_MICROGRAM_LITRE = 1;
  const SPECIES_UNIT_MILIEQUIVALENT_PER_LITER = 2;
  const SPECIES_UNIT_MILIGRAM_PER_LITER = 3;
  const SPECIES_UNIT_PPMV = 4;

  const getUnitName = speciesUnit => {
    switch (speciesUnit) {
      case SPECIES_UNIT_MICROGRAM_LITRE:
        return UNITS.MICROGRAM_LITRE;
      case SPECIES_UNIT_MILIEQUIVALENT_PER_LITER:
        return UNITS.MiliEquivalentPerLiter;
      case SPECIES_UNIT_MILIGRAM_PER_LITER:
        return UNITS.MiligramPerLiter;
      case SPECIES_UNIT_PPMV:
        return UNITS.ppmv;
      default:
        return "";
    }
  };
  const handleFocus = e => {
    setIsFocused(e);
  };

  const handleInvalidInput = (message, e) => {
    setIsSumValid(true);
    setIsFocused(1);
    setMessage(message);
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const validateInput = (value, min, max, unitName, e) => {
    const parsedValue = parseFloat(value);
    if (parsedValue < min || parsedValue > max || isNaN(parsedValue)) {
      handleInvalidInput(
        `The value entered is outside the allowed range(${min} to ${max} ${unitName}). Please revise your input.`,
        e,
      );
      return false;
    }
    return true;
  };

  const handleNaAverage = (value, FromUnit) => {
    if (Na_Average) {
      setNa_Average(false);
      const Conductivity = CalculateNACondValue("Na-Avg", value, FromUnit, 35);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            averageSpeciesVal: parseFloat(value).toFixed(2),
            averageConductivityVal: convertUpto2Digits(Conductivity),
          },
          ixStoreAnion,
        ]),
      );
    }
  };

  const handleBlur = event => {
    const unitName = getUnitName(ixStoreCation.speciesUnit);
    const minRange = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit)?.min;
    const maxRange = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit)?.max;
    const { value } = event.target;
    if (!validateInput(value, minRange, maxRange, unitName, event)) return;
    if (parseFloat(value) > parseFloat(ixStoreCation.endpoingSpeciesVal) || isNaN(parseFloat(value))) {
      handleInvalidInput(ALERT_MSG.EFFLUENT_QUALITY_CATION_CHANGE, event);
    } else {
      handleNaAverage(value, ixStoreCation.speciesUnit);
    }
    setIsFocused(null);
  };

  const handleBlurConduct = e => {
    const MinValue =
      unit.selectedUnits[17] === "µS/cm"
        ? 0.055
        : GlobalUnitConversion(GlobalUnitConversionStore, 0.055, unit.selectedUnits[17], "µS/cm")?.toFixed(2);

    const MaxValue =
      unit.selectedUnits[17] === "µS/cm"
        ? 49301.62
        : GlobalUnitConversion(GlobalUnitConversionStore, 49301.62, unit.selectedUnits[17], "µS/cm")?.toFixed(2);

    if (
      parseFloat(e.target.value) > parseFloat(MaxValue) ||
      parseFloat(e.target.value) < parseFloat(MinValue) ||
      isNaN(e.target.value)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage(
        `The value entered is outside the allowed range(${MinValue} to ${MaxValue} ${unit.selectedUnits[17]}). Please revise your input.`,
      );
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else if (
      parseFloat(e.target.value) > parseFloat(ixStoreCation.endpointConductivityVal) ||
      isNaN(e.target.value)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("The Conductivity Average Leakage value is larger than the Endpoint value, Please revise your input.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      if (Cond_Average == true) {
        setCond_Average(false);
        const FromUnit = ixStoreCation.speciesUnit;
        const NaValue = CalculateNACondValue("Cond-Avg", e.target.value, FromUnit, 34);

        dispatch(
          updateProductQualityRegenerantDose([
            {
              ...ixStoreCation,
              averageSpeciesVal: NaValue,
              averageConductivityVal: parseFloat(e.target.value).toFixed(2),
            },
            ixStoreAnion,
          ]),
        );
      }
    }
    setIsFocused(null);
  };
  const handleBlurAnion = event => {
    const unitName = getUnitName(ixStoreAnion.speciesUnit);
    const minValue = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreAnion.speciesUnit)?.min;
    const maxValue = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreAnion.speciesUnit)?.max;
    const { value } = event.target;
    if (!validateInput(value, minValue, maxValue, unitName, event)) return;
    if (parseFloat(value) > parseFloat(ixStoreAnion.endpoingSpeciesVal) || isNaN(value)) {
      handleInvalidInput(ALERT_MSG.EFFLUENT_QUALITY_ANION_CHANGE, event);
    } else {
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            averageSpeciesVal: parseFloat(value).toFixed(2),
          },
        ]),
      );
    }

    setIsFocused(null);
  };
  const handleBlurEnd = event => {
    const unitName = getUnitName(ixStoreCation.speciesUnit);
    const minValue = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit)?.min;
    const maxValue = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit)?.max;

    const { name, value } = event.target;
    if (!validateInput(value, minValue, maxValue, unitName, event)) return;
    if (parseFloat(value) < parseFloat(ixStoreCation.averageSpeciesVal) || isNaN(value)) {
      handleInvalidInput(ALERT_MSG.EFFLUENT_QUALITY_CATION_CHANGE, event);
    } else {
      if (Na_End == true) {
        setNa_End(false);
        const FromUnit = ixStoreCation.speciesUnit;
        const Conductivity = CalculateNACondValue("Na-End", value, FromUnit, 35);
        dispatch(
          updateProductQualityRegenerantDose([
            {
              ...ixStoreCation,
              [name]: parseFloat(value).toFixed(2),
              endpointConductivityVal: convertUpto2Digits(Conductivity),
            },
            ixStoreAnion,
          ]),
        );
      }
    }

    setIsFocused(null);
  };
  const handleBlurEndConduct = e => {
    const MinValue =
      unit.selectedUnits[17] === "µS/cm"
        ? 0.055
        : GlobalUnitConversion(GlobalUnitConversionStore, 0.055, unit.selectedUnits[17], "µS/cm")?.toFixed(2);

    const MaxValue =
      unit.selectedUnits[17] === "µS/cm"
        ? 49301.62
        : GlobalUnitConversion(GlobalUnitConversionStore, 49301.62, unit.selectedUnits[17], "µS/cm")?.toFixed(2);

    if (
      parseFloat(e.target.value) < parseFloat(MinValue) ||
      parseFloat(e.target.value) > parseFloat(MaxValue) ||
      isNaN(e.target.value)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage(
        `The value entered is outside the allowed range(${MinValue} to ${MaxValue} ${unit.selectedUnits[17]} ). Please revise your input.`,
      );
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else if (parseFloat(e.target.value) < parseFloat(ixStoreCation.averageConductivityVal) || isNaN(e.target.value)) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("The Conductivity Average Leakage value is larger than the Endpoint value, Please revise your input.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      if (Cond_End == true) {
        setCond_End(false);

        const FromUnit = ixStoreCation.speciesUnit;
        const NaValue = CalculateNACondValue("Cond-Avg", e.target.value, FromUnit, 34);

        dispatch(
          updateProductQualityRegenerantDose([
            {
              ...ixStoreCation,
              [e.target.name]: parseFloat(e.target.value).toFixed(2),
              endpoingSpeciesVal: NaValue,
            },
            ixStoreAnion,
          ]),
        );
      }
    }
    setIsFocused(null);
  };
  const handleBlurOrganic = e => {
    if (e.target.value < 0 || e.target.value > 100 || isNaN(e.target.value)) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("The value entered is outside the allowed range(0 to 100%), Pleas revise your input.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            [e.target.name]: parseFloat(e.target.value).toFixed(2),
          },
        ]),
      );
    }
    setIsFocused(null);
  };
  const handleBlurSelectDoseCation = e => {
    const MinValue =
      unit.selectedUnits[14] === "g/L"
        ? cationAdvRegen?.minMaxRange?.regenerantDoseMIN
        : GlobalUnitConversion(
            GlobalUnitConversionStore,
            cationAdvRegen?.minMaxRange?.regenerantDoseMIN,
            unit.selectedUnits[14],
            "g/L",
          )?.toFixed(2);

    const MaxValue =
      unit.selectedUnits[14] === "g/L"
        ? cationAdvRegen?.minMaxRange?.regenerantDoseMAX
        : GlobalUnitConversion(
            GlobalUnitConversionStore,
            cationAdvRegen?.minMaxRange?.regenerantDoseMAX,
            unit.selectedUnits[14],
            "g/L",
          )?.toFixed(2);
    if (
      parseFloat(e.target.value) < parseFloat(MinValue) ||
      parseFloat(e.target.value) > parseFloat(MaxValue) ||
      isNaN(e.target.value)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      if (unit.selectedUnits[14] == "g/L") {
        setMessage(
          "The value entered is outside the allowed range(" +
            MinValue +
            " to " +
            MaxValue +
            " g/l), Please revise your input..",
        );
      } else {
        setMessage(
          "The value entered is outside the allowed range(" +
            MinValue +
            " to " +
            MaxValue +
            " lb/ft³), Please revise your input..",
        );
      }
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            [e.target.name]: parseFloat(e.target.value).toFixed(2),
            regenerationRatio: parseFloat(0),
          },
          ixStoreAnion,
        ]),
      );
    }
  };
  const handleBlurRegeneration = (e, isCation) => {
    const { value } = e.target;
    const parsedValue = parseFloat(value);
    if (
      parsedValue < REGENERATION_RATIO_RANGE.min ||
      parsedValue > REGENERATION_RATIO_RANGE.max ||
      isNaN(parsedValue)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("The value entered is outside the allowed range (100 to 10000%), Please revise your input.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      const updatedStore = isCation
        ? [
            {
              ...ixStoreCation,
              [e.target.name]: parsedValue,
              regenerantDoseVal4: 0,
            },
            ixStoreAnion,
          ]
        : [
            ixStoreCation,
            {
              ...ixStoreAnion,
              [e.target.name]: parsedValue,
              regenerantDoseVal4: 0,
            },
          ];

      dispatch(updateProductQualityRegenerantDose(updatedStore));
    }
  };

  const handleBlurSelectDoseAnion = e => {
    const MinValue =
      unit.selectedUnits[14] === "g/L"
        ? anionAdvRegen?.minMaxRange?.regenerantDoseMIN
        : GlobalUnitConversion(
            GlobalUnitConversionStore,
            anionAdvRegen?.minMaxRange?.regenerantDoseMIN,
            unit.selectedUnits[14],
            "g/L",
          )?.toFixed(2);

    const MaxValue =
      unit.selectedUnits[14] === "g/L"
        ? anionAdvRegen?.minMaxRange?.regenerantDoseMAX
        : GlobalUnitConversion(
            GlobalUnitConversionStore,
            anionAdvRegen?.minMaxRange?.regenerantDoseMAX,
            unit.selectedUnits[14],
            "g/L",
          )?.toFixed(2);

    if (
      parseFloat(e.target.value) < parseFloat(MinValue) ||
      parseFloat(e.target.value) > parseFloat(MaxValue) ||
      isNaN(e.target.value)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      if (unit.selectedUnits[14] == "g/L") {
        setMessage(
          "The value entered is outside the allowed range(" +
            MinValue +
            " to " +
            MaxValue +
            " g/l), Please revise your input..",
        );
      } else {
        setMessage(
          "The value entered is outside the allowed range(" +
            MinValue +
            " to " +
            MaxValue +
            " lb/ft³), Please revise your input..",
        );
      }
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            [e.target.name]: parseFloat(e.target.value).toFixed(2),
            regenerationRatio: parseFloat(0),
          },
        ]),
      );
    }
  };
  const handleBlurDoseCation = (e, labelValue, labelID) => {
    const { value } = e.target;
    if ((value < PERCENTAGE_RANGE.min || value > PERCENTAGE_RANGE.max || isNaN(value)) && labelValue === WAC_OVERRUN) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("Please Enter WAC Overrun between of the Range.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else if (
      (value < REGENERATION_RATIO_RANGE.min || value > REGENERATION_RATIO_RANGE.max || isNaN(value)) &&
      labelValue === REGENERATION_RATIO
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("Please Enter Regeneration Ratio between of the Range.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else if (
      (value < SAFETY_FACTOR_RANGE.min || value > SAFETY_FACTOR_RANGE.max || isNaN(value)) &&
      (labelValue === SAC_SAFETY_FACTOR || labelValue === WAC_SAFETY_FACTOR)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("Please Enter Regenerant Dose between of the Range.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      let temTRes_AndRegenerantDose;
      if (e.target.name === "CatRegenerantDose-0") {
        temTRes_AndRegenerantDose = TRes_AndRegenerantDose?.map(st => {
          if (st.ixregenerantDoseId === parseInt(labelID)) {
            st = {
              ...st,
              txtValue: parseFloat(value),
              ixregenerantDoseId: parseInt(labelID),
            };
          }
          return st;
        });

        tsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
        dispatch(
          updateProductQualityRegenerantDose([
            {
              ...ixStoreCation,
              saftyFactorLbl: parseInt(labelID),
              saftyFactorVal: parseFloat(value),
            },
            ixStoreAnion,
          ]),
        );
      } else if (e.target.name === "CatRegenerantDose-1") {
        temTRes_AndRegenerantDose = TRes_AndRegenerantDose?.map(st => {
          if (st.ixregenerantDoseId === parseInt(labelID)) {
            st = {
              ...st,
              txtValue: parseFloat(value),
              ixregenerantDoseId: parseInt(labelID),
            };
          }
          return st;
        });
        tsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
        dispatch(
          updateProductQualityRegenerantDose([
            {
              ...ixStoreCation,
              regenerantDoseLbl1ID: parseInt(labelID),
              regeneratDoseVal1: parseFloat(value),
            },
            ixStoreAnion,
          ]),
        );
      } else if (e.target.name === "CatRegenerantDose-2") {
        temTRes_AndRegenerantDose = TRes_AndRegenerantDose?.map(st => {
          if (st.ixregenerantDoseId === parseInt(labelID)) {
            st = {
              ...st,
              txtValue: parseFloat(value),
              ixregenerantDoseId: parseInt(labelID),
            };
          }
          return st;
        });
        tsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
        dispatch(
          updateProductQualityRegenerantDose([
            {
              ...ixStoreCation,
              regenerantDoseLbl2ID: parseInt(labelID),
              regenerantDoseVal2: parseFloat(value),
            },
            ixStoreAnion,
          ]),
        );
      }
    }
    setIsFocused(null);
  };
  const handleBlurDoseAnion = (e, labelValue, labelID) => {
    const { value } = e.target;
    if ((value < PERCENTAGE_RANGE.min || value > PERCENTAGE_RANGE.max || isNaN(value)) && labelValue === WBA_OVERRUN) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("Please Enter WBA Overrun between of the Range.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else if (
      (value < REGENERATION_RATIO_RANGE.min || value > REGENERATION_RATIO_RANGE.max || isNaN(value)) &&
      labelValue === REGENERATION_RATIO
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("Please Enter Regeneration Ratio between of the Range.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else if (
      (value < SAFETY_FACTOR_RANGE.min || value > SAFETY_FACTOR_RANGE.max || isNaN(value)) &&
      (labelValue === SBA_SAFETY_FACTOR || labelValue === WBA_SAFETY_FACTOR)
    ) {
      setIsSumValid(true);
      setIsFocused(1);
      setMessage("Please Enter Regenerant Dose between of the Range.");
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else {
      let temTRes_AndRegenerantDose;
      if (e.target.name === "ANRegenerantDose-0") {
        temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
          if (st.ixregenerantDoseId === parseInt(labelID)) {
            st = {
              ...st,
              txtValue: parseFloat(value).toFixed(2),
              ixregenerantDoseId: parseInt(labelID),
            };
          }
          return st;
        });
        ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
        dispatch(
          updateProductQualityRegenerantDose([
            ixStoreCation,
            {
              ...ixStoreAnion,
              saftyFactorLbl: parseInt(labelID),
              saftyFactorVal: parseFloat(e.target.value).toFixed(2),
            },
          ]),
        );
      } else if (e.target.name === "ANRegenerantDose-1") {
        temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
          if (st.ixregenerantDoseId === parseInt(labelID)) {
            st = {
              ...st,
              txtValue: parseFloat(e.target.value).toFixed(2),
              ixregenerantDoseId: parseInt(labelID),
            };
          }
          return st;
        });
        ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
        dispatch(
          updateProductQualityRegenerantDose([
            ixStoreCation,
            {
              ...ixStoreAnion,
              regenerantDoseLbl1ID: parseInt(labelID),
              regeneratDoseVal1: parseFloat(e.target.value).toFixed(2),
            },
          ]),
        );
      } else if (e.target.name === "ANRegenerantDose-2") {
        temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
          if (st.ixregenerantDoseId === parseInt(labelID)) {
            st = {
              ...st,
              txtValue: parseFloat(e.target.value).toFixed(2),
              ixregenerantDoseId: parseInt(labelID),
            };
          }
          return st;
        });
        ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
        dispatch(
          updateProductQualityRegenerantDose([
            ixStoreCation,
            {
              ...ixStoreAnion,
              regenerantDoseLbl2ID: parseInt(labelID),
              regenerantDoseVal2: parseFloat(e.target.value).toFixed(2),
            },
          ]),
        );
      } else {
        temTRes_AndRegenerantDose = TTRes_AndRegenerantDose?.map(st => {
          if (st.ixregenerantDoseId === parseInt(labelID)) {
            st = {
              ...st,
              txtValue: parseFloat(e.target.value).toFixed(2),
              ixregenerantDoseId: parseInt(labelID),
            };
          }
          return st;
        });
        ttsetRes_AndRegenerantDose(temTRes_AndRegenerantDose);
        dispatch(
          updateProductQualityRegenerantDose([
            ixStoreCation,
            {
              ...ixStoreAnion,
              regenerantDoseLbl3ID: parseInt(labelID),
              regenerantDoseVal3: parseFloat(e.target.value).toFixed(2),
            },
          ]),
        );
      }
    }
    setIsFocused(null);
  };
  const handleBlurEndAnion = event => {
    const unitName = getUnitName(ixStoreAnion.speciesUnit);
    const minValue = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreAnion.speciesUnit)?.min;
    const maxValue = getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreAnion.speciesUnit)?.max;
    const { name, value } = event.target;
    if (!validateInput(value, minValue, maxValue, unitName, event)) return;
    if (parseFloat(value) < parseFloat(ixStoreAnion.averageSpeciesVal) || isNaN(parseFloat(value))) {
      handleInvalidInput(ALERT_MSG.EFFLUENT_QUALITY_ANION_CHANGE, event);
    } else {
      dispatch(
        updateProductQualityRegenerantDose([
          ixStoreCation,
          {
            ...ixStoreAnion,
            [name]: parseFloat(value).toFixed(2),
          },
        ]),
      );
    }

    setIsFocused(null);
  };
  const txtChangeCation = e => {
    if (e.target.name == "averageSpeciesVal") {
      setNa_Average(true);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            [e.target.name]: e.target.value,
          },
          ixStoreAnion,
        ]),
      );
    } else if (e.target.name == "endpoingSpeciesVal") {
      setNa_End(true);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            [e.target.name]: e.target.value,
          },
          ixStoreAnion,
        ]),
      );
    } else if (e.target.name == "averageConductivityVal") {
      setCond_Average(true);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            [e.target.name]: e.target.value,
          },
          ixStoreAnion,
        ]),
      );
    } else if (e.target.name == "endpointConductivityVal") {
      setCond_End(true);
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            [e.target.name]: e.target.value,
          },
          ixStoreAnion,
        ]),
      );
    } else {
      dispatch(
        updateProductQualityRegenerantDose([
          {
            ...ixStoreCation,
            [e.target.name]: e.target.value,
          },
          ixStoreAnion,
        ]),
      );
    }
    updateParameterSettings();
  };
  const handlechangeCationUnit = e => {
    const ToUnit = e.target.value,
      FromUnit = ixStoreCation.speciesUnit;
    const ResultAv = UnitConversion("IXD", ixStoreCation.averageSpeciesVal, ToUnit, FromUnit);

    const ResultEnd = UnitConversion("IXD", ixStoreCation.endpoingSpeciesVal, ToUnit, FromUnit);
    dispatch(
      updateProductQualityRegenerantDose([
        {
          ...ixStoreCation,
          averageSpeciesVal: ResultAv,
          endpoingSpeciesVal: ResultEnd,
          [e.target.name]: parseInt(e.target.value),
        },
        ixStoreAnion,
      ]),
    );
    updateParameterSettings();
  };
  const handlechangeAnionUnit = e => {
    const ToUnit = e.target.value,
      FromUnit = ixStoreAnion.speciesUnit;
    const ResultAv = UnitConversion("IXD", ixStoreAnion.averageSpeciesVal, ToUnit, FromUnit);
    const ResultEnd = UnitConversion("IXD", ixStoreAnion.endpoingSpeciesVal, ToUnit, FromUnit);
    dispatch(
      updateProductQualityRegenerantDose([
        ixStoreCation,
        {
          ...ixStoreAnion,
          speciesUnit: parseInt(e.target.value),
          averageSpeciesVal: ResultAv,
          endpoingSpeciesVal: ResultEnd,
        },
      ]),
    );
    updateParameterSettings();
  };
  const txtChangeAnion = e => {
    dispatch(
      updateProductQualityRegenerantDose([
        ixStoreCation,
        {
          ...ixStoreAnion,
          [e.target.name]: e.target.value,
        },
      ]),
    );
    updateParameterSettings();
  };
  const radioCheck = e => {
    setRadionCheckEnable(e.target.value);
    updateParameterSettings();
  };

  return (
    <>
      <ProductQualityRegenerantDoseStyled>
        <SystemDiagram />
        <div className='resin-regeneration'>
          <StyledCard className='cation-resin-regeneration-card'>
            <Card.Header>
              <CustomHeading
                label='Cation Resin Regeneration'
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
              />
              <Card.Title>
                <IconWithTooltip
                  label='For each regeneration, provide target leakages, resin safety factors, and chemical dose (100%).'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body className='cation-resin-regeneration-card-body'>
              <div className='effluent-quality'>
                <div className='vertical-line-heading'>
                  <CustomHeading
                    className='effluent-heading'
                    label='Effluent Quality'
                    fontFamily='NotoSansSemiBold'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                  />
                  <span className='vertical-line effluent'></span>
                </div>
                <div
                  className='na'
                  hidden={
                    (ixStore.anionResin === 1 ||
                      ixStore.anionResin === 4 ||
                      ixStore.anionResin === 11 ||
                      ixStore.anionResin === 12 ||
                      ixStore.anionResin === 13) &&
                    ixStore.cationResin == 3 &&
                    (ixStore.vessel1 == 0 || ixStore.vessel2 == 0 || ixStore.vessel3 == 0)
                      ? true
                      : false
                  }
                >
                  <div className='radio-box'>
                    <CustomRadio
                      type='radio'
                      disabled={false}
                      name='speciesLblNameID'
                      checked={ixStoreCation.speciesLblNameID == 1 ? true : false}
                      value={1}
                      onChange={CationCheck}
                      id='radioNA'
                      label='Na'
                    />
                  </div>
                  <div className='top-wrapper'>
                    <div className='input-box-wrapper'>
                      <CustomHeading
                        label='Average'
                        fontFamily='NotoSansRegular'
                        fontSize='14px'
                        fontWeight='400'
                        color={colors.PrimaryDarkAquaMarine}
                      />
                      <CustomInput
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        isError={isAvgEndPointValuesOutOfRange(
                          ixStoreCation.averageSpeciesVal,
                          EffluentQulatiyRanges.Average,
                          ixStoreCation.speciesUnit,
                        )}
                        type='number'
                        disabled={ixStoreCation?.speciesLblNameID == 2}
                        id='inputField'
                        className=''
                        name='averageSpeciesVal'
                        onChange={txtChangeCation}
                        value={ixStoreCation.averageSpeciesVal}
                        onBlur={e => handleBlur(e)}
                        onFocus={() => handleFocus(1)}
                      />
                      <InputReferenceText
                        refText={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).min} 
                        - ${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).max}`}
                      />
                    </div>
                    <div className='end-point-wrapper'>
                      <div className='input-box-wrapper'>
                        <CustomHeading
                          label='End Point'
                          fontFamily='NotoSansRegular'
                          fontSize='14px'
                          fontWeight='400'
                          color={colors.PrimaryDarkAquaMarine}
                        />
                        <CustomInput
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={isAvgEndPointValuesOutOfRange(
                            ixStoreCation.endpoingSpeciesVal,
                            EffluentQulatiyRanges.Average,
                            ixStoreCation.speciesUnit,
                          )}
                          type='number'
                          disabled={ixStoreCation?.speciesLblNameID == 2}
                          id='inputField'
                          className=''
                          name='endpoingSpeciesVal'
                          onChange={txtChangeCation}
                          value={ixStoreCation.endpoingSpeciesVal}
                          onBlur={e => handleBlurEnd(e)}
                          onFocus={() => handleFocus(1)}
                        />
                        <InputReferenceText
                          refText={`${getRangesInCurrentUnit(EffluentQulatiyRanges.EndPoint, ixStoreCation.speciesUnit).min} - ${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).max}`}
                        />
                      </div>
                      <div className='select-options-div'>
                        <CustomHeading
                          className='hidden'
                          label='End Point'
                          fontFamily='NotoSansRegular'
                          fontSize='14px'
                          fontWeight='400'
                          color={colors.PrimaryDarkAquaMarine}
                        />
                        <CustomSelect
                          id='selectNA'
                          onChange={handlechangeCationUnit}
                          value={ixStoreCation.speciesUnit}
                          name='speciesUnit'
                          disabled={ixStoreCation?.speciesLblNameID == 2}
                        >
                          {Res_AndRegenerantDose &&
                            Res_AndRegenerantDose?.iXunitname_column1?.map(Unit => (
                              <option key={Unit.ixregenDoseSpecies1Id} value={Unit.ixregenDoseSpecies1Id}>
                                {Unit.unitName}
                              </option>
                            ))}
                          ;
                        </CustomSelect>
                        <InputReferenceText refText='xx-yy' className='hidden' />
                      </div>
                    </div>
                  </div>
                </div>
                <CustomHeading
                  hidden={
                    (ixStore.anionResin === 1 ||
                      ixStore.anionResin === 4 ||
                      ixStore.anionResin === 11 ||
                      ixStore.anionResin === 12 ||
                      ixStore.anionResin === 13) &&
                    ixStore.cationResin == 3 &&
                    (ixStore.vessel1 == 0 || ixStore.vessel2 == 0 || ixStore.vessel3 == 0)
                      ? true
                      : false
                  }
                  label='OR'
                  fontFamily='DiodrumSemiBold'
                  fontSize='14px'
                  fontWeight='600'
                  color={colors.Black}
                />
                <div
                  className='conductivity'
                  hidden={
                    (ixStore.anionResin === 1 ||
                      ixStore.anionResin === 4 ||
                      ixStore.anionResin === 11 ||
                      ixStore.anionResin === 12 ||
                      ixStore.anionResin === 13) &&
                    ixStore.cationResin == 3 &&
                    (ixStore.vessel1 == 0 || ixStore.vessel2 == 0 || ixStore.vessel3 == 0)
                      ? true
                      : false
                  }
                >
                  <div className='radio-box'>
                    <CustomRadio
                      disabled={false}
                      type='radio'
                      name='speciesLblNameID'
                      checked={ixStoreCation.speciesLblNameID == 2 ? true : false}
                      value={2}
                      onChange={CationCheck}
                      id='radioConductivity'
                      label='Conductivity'
                    />
                  </div>
                  <div className='top-wrapper'>
                    <div className='input-box-wrapper'>
                      <CustomHeading
                        className='hidden'
                        label='End Point'
                        fontFamily='NotoSansRegular'
                        fontSize='14px'
                        fontWeight='400'
                        color={colors.PrimaryDarkAquaMarine}
                      />
                      <CustomInput
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        isError={false}
                        isWarning={false}
                        type='number'
                        disabled={ixStoreCation?.speciesLblNameID == 1}
                        id='inputField'
                        className=''
                        name='averageConductivityVal'
                        onChange={txtChangeCation}
                        value={ixStoreCation.averageConductivityVal}
                        onBlur={e => handleBlurConduct(e)}
                        onFocus={() => handleFocus(1)}
                      />
                      <InputReferenceText
                        refText={`${
                          unit.selectedUnits[17] === "µS/cm"
                            ? 0.055
                            : GlobalUnitConversion(
                                GlobalUnitConversionStore,
                                0.055,
                                unit.selectedUnits[17],
                                "µS/cm",
                              )?.toFixed(2)
                        }-${
                          unit.selectedUnits[17] === "µS/cm"
                            ? 49301.62
                            : GlobalUnitConversion(
                                GlobalUnitConversionStore,
                                49301.62,
                                unit.selectedUnits[17],
                                "µS/cm",
                              )?.toFixed(2)
                        }`}
                      />
                    </div>
                    <div className='input-box-wrapper long-input'>
                      <CustomHeading
                        className='hidden'
                        label='End Point'
                        fontFamily='NotoSansRegular'
                        fontSize='14px'
                        fontWeight='400'
                        color={colors.PrimaryDarkAquaMarine}
                      />
                      <InputWithText
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        isError={false}
                        isWarning={false}
                        inputText={unit.selectedUnits[17]}
                        type='number'
                        disabled={ixStoreCation?.speciesLblNameID == 1}
                        id='inputField'
                        className=''
                        name='endpointConductivityVal'
                        onChange={txtChangeCation}
                        value={ixStoreCation.endpointConductivityVal}
                        onBlur={e => handleBlurEndConduct(e)}
                        onFocus={() => handleFocus(1)}
                      />
                      <InputReferenceText
                        refText={`${
                          unit.selectedUnits[17] === "µS/cm"
                            ? 0.055
                            : GlobalUnitConversion(
                                GlobalUnitConversionStore,
                                0.055,
                                unit.selectedUnits[17],
                                "µS/cm",
                              )?.toFixed(2)
                        }-${
                          unit.selectedUnits[17] === "µS/cm"
                            ? 49301.62
                            : GlobalUnitConversion(
                                GlobalUnitConversionStore,
                                49301.62,
                                unit.selectedUnits[17],
                                "µS/cm",
                              )?.toFixed(2)
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='na'
                  hidden={
                    (ixStore.anionResin === 1 ||
                      ixStore.anionResin === 4 ||
                      ixStore.anionResin === 11 ||
                      ixStore.anionResin === 12 ||
                      ixStore.anionResin === 13) &&
                    ixStore.cationResin == 3 &&
                    (ixStore.vessel1 == 0 || ixStore.vessel2 == 0 || ixStore.vessel3 == 0)
                      ? false
                      : true
                  }
                >
                  <div className='radio-box'>
                    <CustomLabel hidden={false} id='radioNA' label='Hardness' />
                  </div>
                  <div className='top-wrapper'>
                    <div className='input-box-wrapper'>
                      <CustomHeading
                        label='Average'
                        fontFamily='NotoSansRegular'
                        fontSize='14px'
                        fontWeight='400'
                        color={colors.PrimaryDarkAquaMarine}
                      />
                      <CustomInput
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        isError={false}
                        isWarning={false}
                        min={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).min}`}
                        max={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).max}`}
                        type='number'
                        disabled={ixStoreCation?.speciesLblNameID == 2}
                        id='inputField'
                        className=''
                        name='averageSpeciesVal'
                        onChange={txtChangeCation}
                        value={ixStoreCation.averageSpeciesVal}
                        onBlur={e => handleBlur(e)}
                        onFocus={() => handleFocus(1)}
                      />
                      <InputReferenceText
                        refText={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).min} - 
                        ${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).max}`}
                      />
                    </div>
                    <div className='end-point-wrapper'>
                      <div className='input-box-wrapper'>
                        <CustomHeading
                          label='End Point'
                          fontFamily='NotoSansRegular'
                          fontSize='14px'
                          fontWeight='400'
                          color={colors.PrimaryDarkAquaMarine}
                        />
                        <CustomInput
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={false}
                          isWarning={false}
                          min={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).min}`}
                          max={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).max}`}
                          type='number'
                          disabled={ixStoreCation?.speciesLblNameID == 2}
                          id='inputField'
                          className=''
                          name='endpoingSpeciesVal'
                          onChange={txtChangeCation}
                          value={ixStoreCation.endpoingSpeciesVal}
                          onBlur={e => handleBlurEnd(e)}
                          onFocus={() => handleFocus(1)}
                        />
                        <InputReferenceText
                          refText={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).min} - 
                        ${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreCation.speciesUnit).max}`}
                        />
                      </div>
                      <div className='select-options-div'>
                        <CustomHeading
                          className='hidden'
                          label='End Point'
                          fontFamily='NotoSansRegular'
                          fontSize='14px'
                          fontWeight='400'
                          color={colors.PrimaryDarkAquaMarine}
                        />
                        <CustomSelect
                          id='selectNA'
                          onChange={handlechangeCationUnit}
                          value={ixStoreCation.speciesUnit}
                          name='speciesUnit'
                          disabled={ixStoreCation?.speciesLblNameID == 2}
                        >
                          {Res_AndRegenerantDose &&
                            Res_AndRegenerantDose?.iXunitname_column1?.map(Unit => (
                              <option key={Unit.ixregenDoseSpecies1Id} value={Unit.ixregenDoseSpecies1Id}>
                                {Unit.unitName}
                              </option>
                            ))}
                          ;
                        </CustomSelect>
                        <InputReferenceText refText='xx-yy' className='hidden' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='regenerant-dose'>
                <div className='vertical-line-heading'>
                  <CustomHeading
                    className='regenerant-heading'
                    label='Regenerant Dose'
                    fontFamily='NotoSansSemiBold'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                  />
                  <span className='vertical-line'></span>
                </div>
                <div className='regenerant-dose-wrapper'>
                  <div className='sac-safety-factor'>
                    {TRes_AndRegenerantDose &&
                      TRes_AndRegenerantDose?.map((Dosevalue, i) => (
                        <>
                          <CustomLabel label={Dosevalue.langText} />
                          <div className='input-reference'>
                            <CustomInput
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              isError={isValueOutOfRange(Dosevalue.txtValue, Dosevalue.langText)}
                              isWarning={false}
                              min='0.001'
                              max='2.00'
                              type='number'
                              id='sacInput'
                              name={"CatRegenerantDose-" + i}
                              onChange={e =>
                                CationRegenerantDoseCheck(e, Dosevalue.ixregenerantDoseId, Dosevalue.langText)
                              }
                              className=''
                              value={Dosevalue.txtValue}
                              onBlur={e => handleBlurDoseCation(e, Dosevalue.langText, Dosevalue.ixregenerantDoseId)}
                              onFocus={() => handleFocus(1)}
                            />
                            {Dosevalue.langText === WAC_OVERRUN ? (
                              ixStore.newPlant_ind ? (
                                <InputReferenceText refText='0%–100%' />
                              ) : (
                                <InputReferenceText refText='0%–200%' />
                              )
                            ) : Dosevalue.langText === REGENERATION_RATIO ? (
                              <InputReferenceText refText='100–10000%' />
                            ) : (
                              <InputReferenceText refText='0.001–2.00' />
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
              <div className={radioOptionEnabled ? "select-options disabled_div" : "select-options"}>
                {!isIXMB && (
                  <div className='vertical-line-heading'>
                    <CustomHeading
                      className='radio-option-heading'
                      label='Select one of the options'
                      fontFamily='NotoSansSemiBold'
                      fontSize='14px'
                      fontWeight='400'
                      color={radioOptionEnabled ? colors.Grey96 : colors.Black}
                    />
                    <span className='vertical-line option'></span>
                  </div>
                )}
                <div className='radio-options-wrapper'>
                  <div className='doseh2so4' disabled={ixStore?.newPlant_ind && ixStore?.cationResin === WAC_RESIN_ID}>
                    {!isIXMB ? (
                      <CustomRadio
                        type='radio'
                        name='regenerantDoseLbl4ID'
                        disabled={ixStore?.newPlant_ind && ixStore?.cationResin === WAC_RESIN_ID}
                        id='doseH2SO4Radio'
                        value={1}
                        checked={ixStoreCation.regenerantDoseLbl4ID == 1 ? true : false}
                        onChange={CationCheck}
                        label={regenerantListCationValueNew}
                      />
                    ) : (
                      <CustomHeading label={regenerantListCationValueNew} />
                    )}
                    <div className='input-reference'>
                      <InputWithText
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        isWarning={false}
                        isError={false}
                        disabled={
                          (ixStore?.newPlant_ind && ixStore?.cationResin === WAC_RESIN_ID) ||
                          ixStoreCation.regenerantDoseLbl4ID !== REGENERANT_DOSE_ID
                        }
                        ype='number'
                        id='inputField'
                        inputText={unit.selectedUnits[14]}
                        className=''
                        name='regenerantDoseVal4'
                        onChange={txtChangeCation}
                        onBlur={e => handleBlurSelectDoseCation(e)}
                        value={ixStoreCation.regenerantDoseLbl4ID === REGENERANT_DOSE_ID ? ixStoreCation.regenerantDoseVal4 : 0}
                      />
                    </div>
                  </div>
                  {!isIXMB && (
                    <div className='dose-wrapper'>
                      <div className='volume'>
                        <CustomRadio
                          type='radio'
                          disabled={Modeling != "EvaluateExisting" ? radioOptionEnabled : ""}
                          value={1}
                          onChange={radioCheck}
                          name='Cation_options'
                          id='volumeRadio'
                          label='Volume'
                        />
                        <div className='input-reference'>
                          <InputWithText
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            disabled={RadionCheckEnable != 1 ? radioOptionEnabled : ""}
                            isWarning={false}
                            isError={false}
                            type='number'
                            id='inputField'
                            inputText={unit.selectedUnits[13]}
                            className=''
                            name='volume'
                            onChange={txtChangeCation}
                            value={ixStoreCation.volume}
                          />
                        </div>
                      </div>

                      <div className='flow-rate'>
                        <CustomRadio
                          type='radio'
                          disabled={Modeling != "EvaluateExisting" ? radioOptionEnabled : ""}
                          value={2}
                          onChange={radioCheck}
                          name='Cation_options'
                          id='flowRateRadio'
                          label='Flow Rate'
                        />
                        <div className='input-reference'>
                          <CustomInput
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            disabled={RadionCheckEnable != 2 ? radioOptionEnabled : ""}
                            type='number'
                            id='flowRateInput'
                            className=''
                            name='flowRate'
                            onChange={txtChangeCation}
                            value={ixStoreCation.flowRate}
                          />
                          <InputReferenceText refText='>0' />
                        </div>
                      </div>

                      <CustomHeading
                        label='&'
                        fontFamily='DiodrumSemiBold'
                        fontSize='14px'
                        fontWeight='600'
                        className='and-label'
                        color={radioOptionEnabled ? colors.Grey96 : colors.Black}
                      />

                      <div className='time'>
                        <CustomRadio
                          type='radio'
                          disabled={Modeling != "EvaluateExisting" ? radioOptionEnabled : ""}
                          value={3}
                          onChange={radioCheck}
                          name='Cation_options'
                          id='timeRadio'
                          label='Time'
                        />
                        <div className='input-reference'>
                          <CustomInput
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            min='0.1'
                            max='2'
                            disabled={RadionCheckEnable != 3 ? radioOptionEnabled : ""}
                            isError={false}
                            isWarning={false}
                            type='number'
                            id='timeInput'
                            className=''
                            name='time'
                            onChange={txtChangeCation}
                            value={ixStoreCation.time}
                          />
                          <InputReferenceText refText='0.1–2 hr' />
                        </div>
                      </div>

                      <div className='regeneration-ratio'>
                        <CustomRadio
                          type='radio'
                          disabled={
                            Modeling === "NewPlant" && ixStore?.cationResin === 2 && ixStore?.anionResin === 1
                              ? true
                              : false
                          }
                          value={4}
                          checked={ixStoreCation.regenerantDoseLbl4ID == 4 ? true : false}
                          onChange={CationCheck}
                          name='regenerantDoseLbl4ID'
                          id='regenerationRadio'
                          label='Regeneration Ratio'
                        />
                        <div className='input-reference'>
                          <CustomInput
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            disabled={
                              Modeling === "NewPlant" && ixStore?.cationResin === 2 && ixStore?.anionResin === 1
                                ? true
                                : false || ixStoreCation.regenerantDoseLbl4ID != 4
                            }
                            isWarning={false}
                            type='number'
                            id='regenerationInput'
                            name='regenerationRatio'
                            onChange={txtChangeCation}
                            onBlur={e => handleBlurRegeneration(e, true)}
                            value={ixStoreCation.regenerantDoseLbl4ID === REGENERANT_RATIO ? ixStoreCation.regenerationRatio : 0}
                            isError={
                              ixStoreCation.regenerationRatio < REGENERATION_RATIO_RANGE.min ||
                              ixStoreCation.regenerationRatio > REGENERATION_RATIO_RANGE.max ||
                              isNaN(ixStoreCation.regenerationRatio)
                            }
                          />
                          <InputReferenceText refText='100-10000%' />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </StyledCard>
          <StyledCard className='anion-resin-regeneration-card'>
            <Card.Header>
              <CustomHeading
                label='Anion Resin Regeneration'
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
              />
              <Card.Title>
                <IconWithTooltip
                  label='For each regeneration, provide target leakages, resin safety factors, and chemical dose (100%).'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body className='anion-resin-regeneration-card-body'>
              <div className='effluent-quality'>
                <div className='vertical-line-heading'>
                  <CustomHeading
                    className='effluent-heading effluent-heading-anion'
                    label='Effluent Quality'
                    fontFamily='NotoSansSemiBold'
                    fontSize='14px'
                    fontWeight='500'
                    color={colors.Black}
                  />
                  <span className='vertical-line effluent'></span>
                </div>
                <div className='na'>
                  <div className='radio-box'>
                    <CustomLabel className='anion-label-na' label='SiO₂' />
                  </div>
                  <div className='top-wrapper'>
                    <div className='input-box-wrapper'>
                      <CustomHeading
                        label='Average'
                        fontFamily='NotoSansRegular'
                        fontSize='14px'
                        fontWeight='400'
                        color={colors.PrimaryDarkAquaMarine}
                      />
                      <CustomInput
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        isError={isAvgEndPointValuesOutOfRange(
                          ixStoreAnion.averageSpeciesVal,
                          EffluentQulatiyRanges.Average,
                          ixStoreAnion.speciesUnit,
                        )}
                        id='inputField'
                        className=''
                        type='number'
                        disabled={ixStoreAnion?.speciesLblNameID == 4}
                        name='averageSpeciesVal'
                        onChange={txtChangeAnion}
                        value={ixStoreAnion.averageSpeciesVal}
                        onBlur={e => handleBlurAnion(e)}
                        onFocus={() => handleFocus(1)}
                      />
                      <InputReferenceText
                        refText={`${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreAnion.speciesUnit).min} - 
                        ${getRangesInCurrentUnit(EffluentQulatiyRanges.Average, ixStoreAnion.speciesUnit).max}`}
                      />
                    </div>
                    <div className='end-point-wrapper'>
                      <div className='input-box-wrapper'>
                        <CustomHeading
                          label='End Point'
                          fontFamily='NotoSansRegular'
                          fontSize='14px'
                          fontWeight='400'
                          color={colors.PrimaryDarkAquaMarine}
                        />
                        <CustomInput
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          id='inputField'
                          isError={isAvgEndPointValuesOutOfRange(
                            ixStoreAnion.endpoingSpeciesVal,
                            EffluentQulatiyRanges.EndPoint,
                            ixStoreAnion.speciesUnit,
                          )}
                          type='number'
                          disabled={ixStoreAnion?.speciesLblNameID == 4}
                          name='endpoingSpeciesVal'
                          onChange={txtChangeAnion}
                          value={ixStoreAnion.endpoingSpeciesVal}
                          onBlur={e => handleBlurEndAnion(e)}
                          onFocus={() => handleFocus(1)}
                        />
                        <InputReferenceText
                          refText={`${getRangesInCurrentUnit(EffluentQulatiyRanges.EndPoint, ixStoreAnion.speciesUnit).min} - 
                        ${getRangesInCurrentUnit(EffluentQulatiyRanges.EndPoint, ixStoreAnion.speciesUnit).max}`}
                        />
                      </div>
                      <div className='select-options-div'>
                        <CustomHeading
                          className='hidden'
                          label='End Point'
                          fontFamily='NotoSansRegular'
                          fontSize='14px'
                          fontWeight='400'
                          color={colors.PrimaryDarkAquaMarine}
                        />
                        <CustomSelect
                          id='selectNA'
                          onChange={handlechangeAnionUnit}
                          value={ixStoreAnion.speciesUnit}
                          name='Anion_speciesUnit'
                          disabled={ixStoreAnion?.speciesLblNameID == 4}
                        >
                          {Res_AndRegenerantDose &&
                            Res_AndRegenerantDose?.iXunitname_column2?.map(Unit => (
                              <option key={Unit.ixregenDoseSpecies1Id} value={Unit.ixregenDoseSpecies1Id}>
                                {Unit.unitName}
                              </option>
                            ))}
                          ;
                        </CustomSelect>
                        <InputReferenceText refText='xx-yy' className='hidden' />
                      </div>
                    </div>
                  </div>
                </div>
                <CustomHeading
                  label='AND'
                  fontFamily='DiodrumSemiBold'
                  fontSize='14px'
                  fontWeight='600'
                  color={colors.Black}
                  className={"andLable"}
                />
                <div className='organic'>
                  <div className='radio-box'>
                    <CustomLabel className='anion-label-na' label='Organics Removal Efficiency' />
                  </div>
                  <div className='top-wrapper'>
                    <InputWithText
                      onKeyDown={evt =>
                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                      }
                      isError={false}
                      isWarning={false}
                      id='organicInputField'
                      inputText='%'
                      className=''
                      type='number'
                      disabled={ixStoreAnion?.speciesLblNameID == 3}
                      name='endpointConductivityVal'
                      onChange={txtChangeAnion}
                      value={ixStoreAnion.endpointConductivityVal}
                      onBlur={e => handleBlurOrganic(e)}
                      onFocus={() => handleFocus(1)}
                    />
                    <InputReferenceText refText='0-100' />
                  </div>
                </div>
              </div>
              <div className='regenerant-dose'>
                <div className='vertical-line-heading'>
                  <CustomHeading
                    className='regenerant-heading'
                    label='Regenerant Dose'
                    fontFamily='NotoSansSemiBold'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                  />
                  <span className='vertical-line'></span>
                </div>
                <div className='regenerant-dose-wrapper'>
                  <div className='sac-safety-factor'>
                    {TTRes_AndRegenerantDose &&
                      TTRes_AndRegenerantDose?.map((Dosevalue, i) => (
                        <>
                          <CustomLabel label={Dosevalue.langText} />
                          <div className='input-reference'>
                            <CustomInput
                              min='0.001'
                              max='2.00'
                              isError={isValueOutOfRange(Dosevalue.txtValue, Dosevalue.langText)}
                              isWarning={false}
                              type='number'
                              id='sacInput'
                              name={"ANRegenerantDose-" + i}
                              onChange={e => AnionRegenerantDoseCheck(e, Dosevalue.ixregenerantDoseId)}
                              className='form-control'
                              value={Dosevalue.txtValue}
                              onBlur={e => handleBlurDoseAnion(e, Dosevalue.langText, Dosevalue.ixregenerantDoseId)}
                              onFocus={() => handleFocus(1)}
                            />
                            {Dosevalue.langText === "WBA Overrun" ? (
                              ixStore.newPlant_ind ? (
                                <InputReferenceText refText='0%–100%' />
                              ) : (
                                <InputReferenceText refText='0%–200%' />
                              )
                            ) : Dosevalue.langText === REGENERATION_RATIO ? (
                              <InputReferenceText refText='100–10000%' />
                            ) : (
                              <InputReferenceText refText='0.001–2.00' />
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
              <div className={radioOptionEnabled ? "select-options disabled_div" : "select-options"}>
                {!isIXMB && (
                  <div className='vertical-line-heading'>
                    <CustomHeading
                      className='radio-option-heading'
                      label='Select one of the options'
                      fontFamily='NotoSansSemiBold'
                      fontSize='14px'
                      fontWeight='400'
                      color={radioOptionEnabled ? colors.Grey96 : colors.Black}
                    />
                    <span className='vertical-line option'></span>
                  </div>
                )}
                <div className='radio-options-wrapper'>
                  <div className='doseh2so4' disabled={ixStore?.newPlant_ind && ixStore?.anionResin === WBA_RESIN_ID}>
                    {!isIXMB ? (
                      <CustomRadio
                        type='radio'
                        name='Anion_regenerantDoseLbl4ID'
                        disabled={ixStore?.newPlant_ind && ixStore?.anionResin === WBA_RESIN_ID}
                        id='doseH2SO4Radio'
                        value={1}
                        checked={ixStoreAnion.regenerantDoseLbl4ID == 1 ? true : false}
                        onChange={AnionCheck}
                        label={regenerantListAnionValueNew}
                      />
                    ) : (
                      <CustomHeading label={regenerantListAnionValueNew} />
                    )}
                    <div className='input-reference'>
                      <InputWithText
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        isWarning={false}
                        isError={false}
                        disabled={
                          (ixStore?.newPlant_ind && ixStore?.anionResin === WBA_RESIN_ID) ||
                          ixStoreAnion.regenerantDoseLbl4ID !== REGENERANT_DOSE_ID
                        }
                        type='number'
                        id='inputField'
                        inputText={unit.selectedUnits[14]}
                        className=''
                        name='regenerantDoseVal4'
                        onChange={txtChangeAnion}
                        onBlur={e => handleBlurSelectDoseAnion(e)}
                        value={ixStoreAnion.regenerantDoseLbl4ID === REGENERANT_DOSE_ID ? ixStoreAnion.regenerantDoseVal4 : 0}
                      />
                    </div>
                  </div>
                  {!isIXMB && (
                    <div className='dose-wrapper'>
                      <div className='volume'>
                        <CustomRadio
                          type='radio'
                          disabled={Modeling != "EvaluateExisting" ? radioOptionEnabled : ""}
                          value={5}
                          onChange={radioCheck}
                          name='Anion_options'
                          id='volumeRadio'
                          label='Volume'
                        />
                        <div className='input-reference'>
                          <InputWithText
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            disabled={RadionCheckEnable != 5 ? radioOptionEnabled : ""}
                            isError={false}
                            isWarning={false}
                            inputText={unit.selectedUnits[13]}
                            type='text'
                            id='inputField'
                            className=''
                            name='volume'
                            onChange={txtChangeAnion}
                            value={ixStoreAnion.volume}
                          />
                        </div>
                      </div>
                      <div className='flow-rate'>
                        <CustomRadio
                          type='radio'
                          disabled={Modeling != "EvaluateExisting" ? radioOptionEnabled : ""}
                          value={6}
                          onChange={radioCheck}
                          name='Anion_options'
                          id='flowRateRadio'
                          label='Flow Rate'
                        />
                        <div className='input-reference'>
                          <CustomInput
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            min='0'
                            disabled={RadionCheckEnable != 6 ? radioOptionEnabled : ""}
                            type='number'
                            id='flowRateInput'
                            className=''
                            name='flowRate'
                            onChange={txtChangeAnion}
                            value={ixStoreAnion.flowRate}
                          />
                          <InputReferenceText refText='>0' />
                        </div>
                      </div>
                      <CustomHeading
                        label='&'
                        fontFamily='DiodrumSemiBold'
                        fontSize='14px'
                        fontWeight='600'
                        color={radioOptionEnabled ? colors.Grey96 : colors.Black}
                        className='and-label'
                      />
                      <div className='time'>
                        <CustomRadio
                          type='radio'
                          disabled={Modeling != "EvaluateExisting" ? radioOptionEnabled : ""}
                          value={7}
                          onChange={radioCheck}
                          name='Anion_options'
                          id='timeRadio'
                          label='Time'
                        />
                        <div className='input-reference'>
                          <CustomInput
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            disabled={RadionCheckEnable != 7 ? radioOptionEnabled : ""}
                            isError={false}
                            isWarning={false}
                            type='number'
                            id='timeInput'
                            className=''
                            name='time'
                            onChange={txtChangeAnion}
                            value={ixStoreAnion.time}
                          />
                          <InputReferenceText refText='0.1–2 hr' />
                        </div>
                      </div>
                      <div className='regeneration-ratio'>
                        <CustomRadio
                          type='radio'
                          disabled={
                            Modeling === "NewPlant" && ixStore?.cationResin === 2 && ixStore?.anionResin === 1
                              ? true
                              : false
                          }
                          value={4}
                          checked={ixStoreAnion.regenerantDoseLbl4ID == 4 ? true : false}
                          onChange={AnionCheck}
                          name='Anion_regenerantDoseLbl4ID'
                          id='regenerationRadio'
                          label='Regeneration Ratio'
                        />
                        <div className='input-reference'>
                          <CustomInput
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            disabled={
                              Modeling === "NewPlant" && ixStore?.cationResin === 2 && ixStore?.anionResin === 1
                                ? true
                                : false || ixStoreAnion.regenerantDoseLbl4ID != 4
                            }
                            isError={
                              ixStoreAnion.regenerationRatio < REGENERATION_RATIO_RANGE.min ||
                              ixStoreAnion.regenerationRatio > REGENERATION_RATIO_RANGE.max ||
                              isNaN(ixStoreAnion.regenerationRatio)
                            }
                            isWarning={false}
                            type='number'
                            id='regenerationInput'
                            className=''
                            name='regenerationRatio'
                            onChange={txtChangeAnion}
                            onBlur={e => handleBlurRegeneration(e, false)}
                            value={ixStoreAnion.regenerantDoseLbl4ID === REGENERANT_RATIO ? ixStoreAnion.regenerationRatio : 0}
                          />
                          <InputReferenceText refText='100-10000%' />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </StyledCard>
          <div className='neutrals-overrun-dose-card-wrapper'>
            <StyledCard className='neutrals-waste-effluent-card'>
              <Card.Header>
                <CustomHeading
                  label='Neutral Waste Effluent'
                  fontFamily='NotoSansRegular'
                  fontSize='14px'
                  fontWeight='400'
                  color={colors.PrimaryDarkAquaMarine}
                />
                <Card.Title>
                  <IconWithTooltip
                    label='If appropriate, increase acid or caustic dose so that waste stream pH = 7.'
                    icon={<InfoIcon />}
                  />
                </Card.Title>
              </Card.Header>
              <Card.Body className='neutrals-waste-effluent-card-body'>
                <div className='common-radio'>
                  <CustomRadio
                    type='radio'
                    id='notRequiredRadio'
                    name='naturalEffect'
                    checked={ixStoreCation.naturalEffect === 0 ? true : false}
                    onChange={CationCheck}
                    value={0}
                    label='Not Required'
                  />
                </div>
                <div className='common-radio'>
                  <CustomRadio
                    type='radio'
                    id='requiredRadio'
                    name='naturalEffect'
                    checked={ixStoreCation.naturalEffect == 1 ? true : false}
                    onChange={CationCheck}
                    value={1}
                    label='Required(will override dose inputs)'
                  />
                </div>
              </Card.Body>
            </StyledCard>
            <StyledCard className='overrun-card'>
              <Card.Header>
                <CustomHeading
                  label='Overrun Computation'
                  fontFamily='NotoSansRegular'
                  fontSize='14px'
                  fontWeight='400'
                  color={colors.PrimaryDarkAquaMarine}
                />
                <Card.Title>
                  <IconWithTooltip
                    label='If appropriate, overrun WAC or WBA rest past its normal endpoint, affects weak/strong resin ratio.'
                    icon={<InfoIcon />}
                  />
                </Card.Title>
              </Card.Header>
              <Card.Body className='overrun-card-body'>
                <div className='common-radio'>
                  <CustomRadio
                    type='radio'
                    id='manualRadio'
                    name='overAllComputation'
                    checked={!isIXMB && ixStoreCation.overAllComputation == 0 ? true : false}
                    value={0}
                    disabled={isIXMB}
                    onChange={CationCheck}
                    label='Manual'
                  />
                </div>
                <div className='common-radio'>
                  <CustomRadio
                    type='radio'
                    id='automaticRadio'
                    name='overAllComputation'
                    checked={ixStoreCation.overAllComputation == 1 ? true : false}
                    value={1}
                    onChange={CationCheck}
                    label='Automatic'
                    disabled={isIXMB}
                  />
                </div>
              </Card.Body>
            </StyledCard>
            <StyledCard className='dose-optimization-card'>
              <Card.Header>
                <CustomHeading
                  label='Dose Optimization'
                  fontFamily='NotoSansRegular'
                  fontSize='14px'
                  fontWeight='400'
                  color={colors.PrimaryDarkAquaMarine}
                />
                <Card.Title>
                  <IconWithTooltip
                    label='Adjust dose to minimum level required to meet all capacity and leakage targets.'
                    icon={<InfoIcon />}
                  />
                </Card.Title>
              </Card.Header>
              <Card.Body className='dose-optimization-card-body'>
                <div className='common-radio'>
                  <CustomRadio
                    type='radio'
                    id='noRadio'
                    name='doseOptimization'
                    checked={ixStoreCation.doseOptimization == 0 ? true : false}
                    value={0}
                    onChange={CationCheck}
                    label='No'
                  />
                </div>
                <div className='common-radio'>
                  <CustomRadio
                    type='radio'
                    id='yesRadio'
                    name='doseOptimization'
                    checked={ixStoreCation.doseOptimization == 1 ? true : false}
                    value={1}
                    onChange={CationCheck}
                    label='Yes(will override dose inputs)'
                    disabled={!isIXMB}
                  />
                </div>
              </Card.Body>
            </StyledCard>
          </div>
        </div>
        {isSumValid && (
          <ProjectErrorPopup
            show={isSumValid}
            close={() => {
              setIsSumValid(false);
              setIsFocused(1);
            }}
            message={message}
          />
        )}
      </ProductQualityRegenerantDoseStyled>
    </>
  );
};

export default ProductQualityRegenerantDose;
