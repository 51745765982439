const API_URLS = {
  projectState: "masterdata/api/v1/ProjectState",
  projectStatus: "masterdata/api/v1/EntityCheck",
  importProject: "userprofile/api/v1/Import",
  exportProject: "userprofile/api/v1/Export",
  ufDetails: "uf/api/v1/UFDetails",
  waterLibrary: "userprofile/api/v1/WaterLibrary",
  ufCalcReport: "uf/api/v1/UFCalcReport",
};

export default API_URLS;
