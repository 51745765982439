export const APP_TOAST_NOTIFICATION = {
  PROJECT_SAVED: "Changes saved",
  PROJECT_DELETED: "Project deleted successfully",
  PROJECT_RESTORED: "Project restored successfully",
  FOLDER_CREATED: "Folder created successfully",
  FOLDER_DELETED: "Folder deleted successfully",
  FOLDER_RESTORED: "Folder restored successfully",
  PROJECT_EXPORTED_SUCCESS: "Project exported successfully",
  WATER_LIB_SAVED: "Water library saved successfully",
  WATER_LIB_REPLACED: "Water library replaced successfully",
  WATER_LIB_SAVE_FAILED: "Unable to save library data",
  WATER_LIB_DELETE_FAILED: "Unable to delete library data",
  WATER_LIB_REPLACE_FAILED: "Unable to replace library data",
  WATER_LIB_GET_FAILED: "Unable to get library data. Please try again",
  FOLDER_DELETE_FAILED: "Unable to delete the folder. Please try again",
};
