import { convertUptoDigits } from "@utils/appUtils";

// Array defining the headers for the table, including the field name, display label, and column width
export const IONS_TABLE_HEADER = [
  { field: "name", label: "Symbol", colWidth: 119 },
  { field: "mgL", label: "mg/L", colWidth: 150 },
];

export const FEED_WATER_IONS_LABEL = {
  cations: "Cations",
  anions: "Anions",
  neutrals: "Neutrals",
};

export const CATIONS_IONS_ORDER = {
  "NH₄": 0,
  Na: 1,
  K: 2,
  Mg: 3,
  Ca: 4,
  Sr: 5,
  Ba: 6,
};
export const ANIONS_IONS_ORDER = {
  "CO₃": 0,
  "HCO₃": 1,
  "NO₃": 2,
  F: 3,
  Cl: 4,
  Br: 5,
  "SO₄": 6,
  "PO₄": 7,
};
export const NEUTRALS_IONS_ORDER = {
  "SiO₂": 0,
  B: 1,
  "CO₂": 2,
};

export const FEED_STRINGS = {
  OPEN_WATER_LIBRARY: "Open Water Library",
  SAVE_WATER_LIBRARY: "Save Water Library",
  WATER_TYPE: "Water Type",
  TURBIDITY: "Turbidity",
  TSS: "TSS",
  ORGANICS: "Organics (TOC)",
  SDI: "SDI₁₅",
  COPY_TO_FEED_WATER: "Copy to feed water",
  SAVE: "save",
  DELETE: "Delete from library",
  OPEN: "open",
  TOTAL: "Total",
  DROP_DOWN_LABEL: "Select from water library",
  NAME: "Name",
  waterLibraryNameExistDoYouWantToReplace: "Water Library name already exist, Do you want to replace?",
  replaceLibrary: "Replace Library",
  waterLibraryConfirmation: "Are you really sure you want to replace the existing library with this one?",
  saveToWaterLibrary: "Save to Water Library",
  fethingWaterLibraryData: "Fetching data.",
  deletingWaterLibMsg: "Deleting data.",
  waterLibraryData: "Water Library Data",
  additionalFeedWaterInfo: "Additional Feed Water Information",
  predefinedWaterLibrary: "The Predefined Library cannot be changed. Please select a different name.",
  saveButton: "Save",
  copyDataToFeedSetup: "Copying data to Feed Setup",
  feedSetup: "Feed Setup",
  replaceWaterLibrary: "Replacing library data",
  savingWaterLibrary: "Saving data",
  success: "SUCCESS",
  deleteLibraryHeader: "Delete Water Library?",
  replaceLibraryHeader: "Replace Water Library?",
  deleteButton: "Delete",
  replaceButton: "Replace",
  cancelButton: "Cancel",
  additionalInfoPlaceHolder: "Write some additional description here",
};

export const getWaterTypeInputFields = (waterLibraryData, data, isUF) => [
  {
    label: "Water Type",
    type: "text",
    id: "waterSource",
    name: "waterSource",
    value: isUF ? waterLibraryData.waterSource || data[0].waterSource : "N/A",
    disabled: true,
  },
  {
    label: "Turbidity",
    type: "number",
    id: "turbidity",
    name: "turbidity",
    inputText: "NTU",
    value: convertUptoDigits(waterLibraryData.turbidity, 2),
    disabled: true,
  },
  {
    label: "TSS",
    type: "number",
    id: "tss",
    name: "tss",
    inputText: "mg/L",
    value: convertUptoDigits(waterLibraryData.tss, 2),
    disabled: true,
  },
  {
    label: "Organics (TOC)",
    type: "number",
    id: "toc",
    name: "toc",
    inputText: "mg/L",
    value: convertUptoDigits(waterLibraryData.toc, 2),
    disabled: true,
  },
  {
    label: "SDI₁₅",
    type: "number",
    id: "sdi",
    name: "sdi",
    value: convertUptoDigits(waterLibraryData.sdi, 2),
    disabled: true,
  },
];

export const DEFAULT_WATER_LIBRARY_DATA = {
  waterLibraryId: "separator",
  waterLibraryName: "-----Default dropdownlist start from here-----",
  isSystem: false,
};

export const DEFAULT_IONS_PROPS = {
  1: { molCharge: 1, soluteID: 1, ppm: 0, meqL: 0 },
  2: { molCharge: 1, soluteID: 2, ppm: 0, meqL: 0 },
  3: { molCharge: 1, soluteID: 3, ppm: 0, meqL: 0 },
  4: { molCharge: 2, soluteID: 4, ppm: 0, meqL: 0 },
  5: { molCharge: 2, soluteID: 5, ppm: 0, meqL: 0 },
  6: { molCharge: 2, soluteID: 6, ppm: 0, meqL: 0 },
  7: { molCharge: 2, soluteID: 7, ppm: 0, meqL: 0 },
  8: { molCharge: -2, soluteID: 8, ppm: 0, meqL: 0 },
  9: { molCharge: -1, soluteID: 9, ppm: 0, meqL: 0 },
  10: { molCharge: -1, soluteID: 10, ppm: 0, meqL: 0 },
  11: { molCharge: -1, soluteID: 11, ppm: 0, meqL: 0 },
  12: { molCharge: -1, soluteID: 12, ppm: 0, meqL: 0 },
  13: { molCharge: -2, soluteID: 13, ppm: 0, meqL: 0 },
  14: { molCharge: 0, soluteID: 14, ppm: 0, meqL: 0 },
  23: { molCharge: -3, soluteID: 23, ppm: 0, meqL: 0 },
  28: { molCharge: -1, soluteID: 28, ppm: 0, meqL: 0 },
  29: { molCharge: 0, soluteID: 29, ppm: 0, meqL: 0 },
  30: { molCharge: 0, soluteID: 30, ppm: 0, meqL: 0 },
};

export const IONS_SOLUTE_IDS = {
  "NH₄": 1,
  Na: 3,
  K: 2,
  Mg: 4,
  Ca: 5,
  Sr: 6,
  Ba: 7,
  "CO₃": 8,
  "HCO₃": 9,
  "NO₃": 10,
  F: 12,
  Cl: 11,
  Br: 28,
  "SO₄": 13,
  "PO₄": 23,
  "SiO₂": 14,
  B: 29,
  "CO₂": 30,
};
